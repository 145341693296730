import React                   from "react";

import Modal                   from "react-modal";

import { connect }             from "react-redux";

import dispatcher              from "./redux/dispatcher.js";

import SignUpComponent         from "./components/session/SignUpComponent.js";
import GroupMemberDetails      from "./components/groups/GroupMemberDetails.js";
import GroupInvite             from "./components/groups/GroupInvite.js";
import ConfirmationComponent   from "./components/common/ConfirmationComponent.js";
import SectionOptionsComponent from "./components/section/SectionOptionsComponent.js";

function ModalSwitchComponent(props){

  const {
    modals,
    actions,
  } = props;

  let wait = false;

  const getModal = ()=>{//renders whatever is in Redux in order of importance

    let modal;
    if (modals.SERVER_ERROR){//server errors take priority
      const error = modals.SERVER_ERROR;

      console.log(error);

      // modal = {
      //   name: "SERVER_ERROR",
      //   component: (
      //     <ConfirmationComponent
      //       header={error.modalProps.title}
      //       message={error.modalProps.message}
      //       okButton={true}
      //       {...error.modalProps}
      //       onRequestClose={()=>{
      //         actions.modals.removeModal("SERVER_ERROR");
      //       }}
      //     />
      //   )
      // };

    } else {

      let current_modal;
      //To have absolute control over which modal shows first. Should combine confirmation modals
      if (modals.CONFIRMATION){
        current_modal = modals.CONFIRMATION;
      } else if (modals.SESSION){
        current_modal = modals.SESSION;
        current_modal.modalProps.onRequestClose = ()=>{
          dispatcher("REMOVE_MODAL", current_modal.name);
        };

        modal = {
          name: current_modal.name,
          component: (
            <SignUpComponent
              {...current_modal.modalProps}
            />
          )
        };
      } else if (modals.SECTION_NOT_FOUND){
        current_modal = modals.SECTION_NOT_FOUND;
      } else if (modals.SECTION_OPTIONS){
        current_modal = modals.SECTION_OPTIONS;
        current_modal.modalProps.onRequestClose = ()=>{
          dispatcher("REMOVE_MODAL", current_modal.name);
        };

        modal = {
          name: current_modal.name,
          component: (
            <SectionOptionsComponent
              {...current_modal.modalProps}
            />
          ),
          onRequestClose: current_modal.modalProps.onRequestClose
        };
      } else if (modals.GROUP_INVITE){
        current_modal = modals.GROUP_INVITE;
        current_modal.modalProps.onRequestClose = ()=>{
          dispatcher("REMOVE_MODAL", current_modal.name);
        };


        modal = {
          name: current_modal.name,
          component: (
            <GroupInvite
              {...current_modal.modalProps}
            />
          ),
        };
      } else if (modals.GROUP_MEMBER_DETAILS){
        current_modal = modals.GROUP_MEMBER_DETAILS;
        current_modal.modalProps.onRequestClose = ()=>{
          dispatcher("REMOVE_MODAL", current_modal.name);
        };


        modal = {
          name: current_modal.name,
          component: (
            <GroupMemberDetails
              {...current_modal.modalProps}
            />
          ),
        };
      } else {
        modal = {};
      }

      if (current_modal && !modal){//check to handle generic ConfirmationComponent modals
        current_modal.modalProps.onRequestClose = ()=>{
          dispatcher("REMOVE_MODAL", current_modal.name);
        };

        modal = {
          name: current_modal.name,
          component: (
            <ConfirmationComponent
              {...current_modal.modalProps}
            />
          ),
          className: current_modal.className,
        };
      }

    }

    if (modals.WAIT && !wait){
      wait = true;
      setTimeout(()=>{
        wait = false;
        actions.modals.removeModal("WAIT");
      }, 2000);
    }

    if (modals.WAIT){
      modal = {};
    }

    return modal;
  };

  const modal = getModal();

  return (
    <Modal
      isOpen={Boolean(modal.name)}
      className={modal.className || "react-modal-class-name"}
      shouldCloseOnOverlayClick={true}
      onRequestClose={modal.onRequestClose}
      style={{overlay: {zIndex: 9}}}
      closeTimeoutMS={150}
    >
      {modal.component}
    </Modal>
  );
}

export default connect((state)=>({
  modals: state.modalReducer,
  user: state.sessionReducer.user_info,
}))(ModalSwitchComponent);
