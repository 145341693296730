import React, { useState } from "react";

import { connect }         from "react-redux";
import { useNavigate }     from "react-router-dom";

import dispatcher          from "../../redux/dispatcher.js";

import styles              from "../../styles/styles.js";

import BookmarkMenuItem    from "./BookmarkMenuItem.js";
import GroupMenuItem       from "./GroupMenuItem.js";
import GroupInviteMenuItem from "./GroupInviteMenuItem.js";

import ProfileSettings     from "./ProfileSettings.js";

import Select              from "react-select";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleRight
} from "@fortawesome/free-solid-svg-icons";

import { upperCase }       from "../../functions/utility.js";

function SideMenu(props){
  const {
    groups,
    hideNav,
    sections,
    user_info,
    group_invites,
    user_sections,
  } = props;

  const [groupsMenuOpen, setGroupsMenuOpen]       = useState(true);
  const [bookmarksMenuOpen, setBookmarksMenuOpen] = useState(true);
  const [settingsMenuOpen, setSettingsMenuOpen]   = useState(false);
  const [ArrowDown] = useState((
    <span className="side-menu-arrow-down-container" style={{...styles.sideMenuArrowDownContainer}}>
      <FontAwesomeIcon
        className="side-menu-arrow-down"
        style={{...styles.sideMenuArrowDown}}
        icon={faAngleDown}
      />
    </span>
  ));
  const [ArrowRight] = useState((
    <span className="side-menu-arrow-right-container" style={{...styles.sideMenuArrowRightContainer}}>
      <FontAwesomeIcon
        className="side-menu-arrow-right"
        style={{...styles.sideMenuArrowRight}}
        icon={faAngleRight}
      />
    </span>
  ));

  const history = useNavigate();

  const toggleBookmarkMenu = ()=>{
    setBookmarksMenuOpen(!bookmarksMenuOpen);
  };

  const toggleGroupsMenu = ()=>{
    setGroupsMenuOpen(!groupsMenuOpen);
  };

  const toggleSettingsMenu = ()=>{
    setSettingsMenuOpen(!settingsMenuOpen);
  };

  const navigate = (url)=>{
    hideNav();
    history(url);
  };

  const getDropdownOptions = ()=>{

    return ([
      {
        label: "Global Sections",
        options: getSectionOptions("global"),
      },
      {
        label: "Personal Sections",
        options: getSectionOptions("personal"),
      },
      {
        label: "Group Sections",
        options: getSectionOptions("group"),
      },
    ]);

  };

  const getSectionOptions = (collection)=>{

    const section_collection = Object.keys(sections[`${collection}_sections`]);

    return section_collection.filter((section_id)=>{
      const section = sections[`${collection}_sections`][section_id];

      return (section.deleted !== "true" && section.deleted !== true);
    }).sort((a, b)=>{
      if (sections[`${collection}_sections`][a].name < sections[`${collection}_sections`][b].name){ return -1; }
      if (sections[`${collection}_sections`][a].name > sections[`${collection}_sections`][b].name){ return 1; }
      return 0;
    }).map((section_id)=>{

      const uppercase_section_title = upperCase(sections[`${collection}_sections`][section_id].name);

      return ({
        name: "section_parent",
        value: section_id,
        label: uppercase_section_title,
        collection
      });
    });
  };

  const toAboutPage = ()=>{
    hideNav();
    navigate("/about");
  };

  const toSupportPage = ()=>{
    hideNav();
    navigate("/support");
  };

  const toPPAndToSPage = ()=>{
    hideNav();
    navigate("/privacy-policy-and-terms-of-service");
  };

  const getBookmarks = ()=>{

    return (
      Object.keys(user_sections).filter((section_id)=>{
        const collection = user_sections[section_id].collection;
        const section = sections[collection + "_sections"][section_id];

        return section && section.deleted !== "true" && user_sections[section_id].bookmarked;
      }).map((section_id, key)=>{
        const collection = user_sections[section_id].collection;
        const section = sections[collection + "_sections"][section_id];

        return (
          <BookmarkMenuItem
            key={section_id}
            index={key}
            section={section}
            onClick={()=>{
              navigate(`/achievements/${collection}/${section_id}`);
            }}
          />
        );
      })
    );
  };

  const getGroups = ()=>{

    if (user_info && user_info.groups && Object.keys(user_info.groups).length && groupsMenuOpen){
      return (
        Object.keys(groups).map((group_id, key)=>{
          const group = groups[group_id];

          return (
            <GroupMenuItem
              key={group_id}
              index={key}
              group={group}
              onClick={()=>{
                navigate(`/group/${group_id}`);
              }}
            />
          );
        })
      );
    } else {
      return;
    }

  };

  const getGroupInvites = ()=>{

    if (group_invites && Object.keys(group_invites).length && groupsMenuOpen){
      return (
        Object.keys(group_invites).map((group_invite_id)=>{
          const group_invite = group_invites[group_invite_id];

          return (
            <GroupInviteMenuItem
              key={group_invite_id}
              group_invite={group_invite}
              onClick={()=>{
                dispatcher("ADD_MODAL", {
                  name: "GROUP_INVITE",
                  modalProps: {
                    group_invite_id,
                    user_info,
                    group_invite: group_invites[group_invite_id]
                  }
                });
              }}
            />
          );
        })
      );
    } else {
      return;
    }

  };

  const getSettings = ()=>{

    if (settingsMenuOpen){
      return (
        <ProfileSettings />
      );
    } else {
      return;
    }

  };

  const options = getDropdownOptions();

  const mobile = (window.screen.width <= 800);

  const Bookmarks    = getBookmarks();
  const Groups       = getGroups();
  const GroupInvites = getGroupInvites();
  const Settings     = getSettings();

  return (
    <div id="side-menu" style={{...styles.sideMenu}}>

      {(mobile) ? (
        <React.Fragment>
          <div id="side-menu-section-search-content" style={{...styles.sideMenuSectionSearchContent}}>
            <Select
              id="side-menu-section-search"
              name="section_parent"
              placeholder={"Search Sections"}
              options={options}
              styles={{
                container: (style)=>{
                  return {...style, ...styles.sideMenuSectionSearchContainer};
                },
                menu: (style)=>{
                  return {...style, ...styles.sideMenuSectionSearchMenu};
                },
                input: (style)=>{
                  return {...style, ...styles.sideMenuSectionSearchInput};
                },
                control: (style)=>{
                  return {...style, ...styles.sideMenuSectionSearchControl};
                },
                placeholder: (style)=>{
                  return {...style, ...styles.sideMenuSectionSearchPlaceholder};
                },
              }}
            />
          </div>

          <div id="side-menu-top-spacer" style={{...styles.sideMenuTopSpacer}}></div>
        </React.Fragment>
      ) : (null)}

      <div
        id="side-menu-bookmarked-sections-label"
        style={{...styles.sideMenuBookmarkedSectionsLabel}}
        onClick={toggleBookmarkMenu}
      >
        Bookmarked Sections
        {(bookmarksMenuOpen) ? (ArrowDown) : (ArrowRight)}
      </div>

      {(bookmarksMenuOpen) ? (
        <div id="side-menu-bookmarked-sections" style={{...styles.sideMenuBookmarkedSections}}>
          {Bookmarks}
        </div>
      ) : (null)}

      <div className="line" style={{...styles.line, borderWidth: 2}}></div>

      <div
        id="side-menu-groups-label"
        style={{...styles.sideMenuGroupsLabel}}
        onClick={toggleGroupsMenu}
      >
        Groups
        {(groupsMenuOpen) ? (ArrowDown) : (ArrowRight)}
      </div>

      {Groups}

      {GroupInvites}

      <div className="line" style={{...styles.line, borderWidth: 2}}></div>

      <div
        id="side-menu-settings-label"
        style={{...styles.sideMenuSettingsLabel}}
        onClick={toggleSettingsMenu}
      >
        Settings
        {(bookmarksMenuOpen) ? (ArrowDown) : (ArrowRight)}
      </div>

      {(settingsMenuOpen) ? (
        <div id="side-menu-settings" style={{...styles.sideMenuSettings}}>
          {Settings}
        </div>
      ) : (null)}

      <div className="line" style={{...styles.line, borderWidth: 2}}></div>

      <div
        id="side-menu-about"
        style={{...styles.sideMenuAbout}}
        onClick={toAboutPage}
      >
        About
      </div>

      <div className="line" style={{...styles.line, borderWidth: 2}}></div>

      <div
        id="side-menu-support"
        style={{...styles.sideMenuSupport}}
        onClick={toSupportPage}
      >
        Support
      </div>

      <div className="line" style={{...styles.line, borderWidth: 2}}></div>

      <div
        id="side-menu-pp-and-tos"
        style={{...styles.sideMenuPPAndPOS}}
        onClick={toPPAndToSPage}
      >
        Privacy Policy and Terms of Service
      </div>

    </div>
  );
}

const mapState = (state)=>{
  const hackToUpdate = Math.random();

  return {
    user_info: state.sessionReducer.user_info,
    sections: state.sectionReducer,
    user_sections: state.sectionReducer.user_sections,
    groups: state.sessionReducer.user_info.groups,
    group_invites: state.groupReducer.group_invites,
    hackToUpdate
  };
};

export default connect(mapState)(SideMenu);
