import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

import { useNavigate } from "react-router-dom";

import dispatcher  from "../../redux/dispatcher.js";

import styles      from "../../styles/styles.js";

function GroupAchievementsPage(props){

  const {
    groups,
    user_groups,
    user_info,
    group_redirect_id
  } = props;

  const [errors, setErrors] = useState({});
  const [group_name, setGroupName] = useState("");
  const [group_description, setGroupDescription] = useState("");
  const [showCreateGroupName, setShowCreateGroupName] = useState(false);

  const history = useNavigate();

  useEffect(()=>{
    dispatcher("GET_PUBLIC_GROUPS");//TODO limit to 10 results and add pagination
  }, []);

  useEffect(()=>{
    if (group_redirect_id){
      dispatcher("FLUSH_REDIRECT");
      history(`/group/${group_redirect_id}`);
    }
  });

  const onChangeGroupName = (e)=>{
    if (e !== undefined){
      setGroupName(e.target.value);
    }
  };

  const onChangeGroupDescription = (e)=>{
    if (e !== undefined){
      setGroupDescription(e.target.value);
    }
  };

  const validate = ()=>{

    let new_errors = {};

    if (group_name === ""){
      // if (Object.keys(errors).length === 0){
      //   this.name_ref.current.focus();
      // }
      new_errors.group_name = "Please fill out the name field";
    }

    if (group_description === ""){
      // if (Object.keys(errors).length === 0){
      //   this.name_ref.current.focus();
      // }
      new_errors.group_description = "Please fill out the description field";
    }

    return new_errors;
  };

  const onSubmit = ()=>{
    const new_errors = validate();
    if (Object.keys(new_errors).length !== 0){
      setErrors(new_errors);
      return;
    }

    const payload = {
      name: group_name,
      description: group_description,
      creator: user_info.uid,
      admins: [user_info.uid],
      settings: {
        visibility: "public",
        joining_type: "invite_only"//open, request, invite_only
      }
    };

    dispatcher("CREATE_GROUP", {
      payload,
      display_name: user_info.display_name,
    });

  };

  const showCreateGroupNameOnClick = ()=>{

    if (!user_info.uid){
      loginModal();
    } else {
      setShowCreateGroupName(true);
    }

  };

  const loginModal = ()=>{
    dispatcher("ADD_MODAL", {
      name: "SESSION",
      modalProps: {
        login: true,
      }
    });
  };

  return (
    <div style={{...styles.CenterContent, ...{flexDirection: "column", paddingTop: 60}}}>

      <div style={{...styles.H1, ...{marginBottom: 16, color: styles.GroupPrimaryColor}}}>Groups</div>

      <div style={{...{width: 406}}}>
        <div>Your groups</div>
        <div style={{...styles.line}}></div>
      </div>

      {(user_groups && Object.keys(user_groups).length) ? (
        <div>
          {Object.keys(user_groups).map((group_id, key)=>{
            return (
              <div
                key={key}
                style={{...styles.MainSubSectionButton, ...styles.mainSectionBrownButton, color: styles.White, backgroundColor: styles.GroupPrimaryColor}}
                onClick={()=>{
                  history(`/group/${group_id}`);
                }}
              >
                {user_groups[group_id].group_name}
              </div>
            );
          })}
        </div>
      ) : (
        <div style={{
          maxWidth: 336,
          padding: 16,
          margin: 16,
          backgroundColor: styles.GroupPrimaryColor,
          borderRadius: 8,
          color: "white",
          textAlign: "center",
        }}>
          It looks like you are not part of any groups yet.
          <br/>
          <br/>
          You can create your own group below, or if you are looking to join a specific group, ask the admin of that group to add you to their group.
        </div>
      )}

      {(showCreateGroupName) ? (
        <div style={{width: 336}}>

          <div style={{...styles.H3, ...{width: "100%", color: styles.GroupPrimaryColor}}}>Group Name</div>

          <input
            name="group_name"
            type="text"
            placeholder="Group name"
            value={group_name}
            required={true}
            onChange={onChangeGroupName}
            style={{...styles.Input}}
          />
          {(errors.group_name) && (
            <div style={{}}>{errors.group_name}</div>
          )}

          <div style={{...styles.H3, ...{width: "100%", color: styles.GroupPrimaryColor}}}>Group Description</div>

          <textarea
            name="group_description"
            type="text"
            placeholder="Group description"
            value={group_description}
            required={true}
            onChange={onChangeGroupDescription}
            style={{...styles.Input}}
          />
          {(errors.group_description) && (
            <div style={{}}>{errors.group_description}</div>
          )}

          <div style={{...styles.Button, background: styles.PrimaryColorMedium, backgroundColor: styles.GroupPrimaryColor}} onClick={onSubmit}>Create</div>
        </div>
      ) : (
        <div style={{...styles.MainSubSectionButton, ...styles.mainSectionBlueButton, color: styles.Black, backgroundColor: styles.GroupSecondaryColor}}>
          <div onClick={showCreateGroupNameOnClick}>Create New Group</div>
        </div>
      )}

      <div style={{...{width: 406, marginTop: 16}}}>
        <div>Public groups</div>
        <div style={{...styles.line}}></div>
      </div>

      {(groups && Object.keys(groups).length) ? (
        <div>
          {Object.keys(groups).filter((group_id)=>{
            return (groups[group_id].settings.visibility === "public");
          }).map((group_id, key)=>{
            return (
              <div
                key={key}
                style={{...styles.MainSubSectionButton, ...styles.mainSectionBrownButton, color: styles.White, backgroundColor: styles.GroupPrimaryColor}}
                onClick={()=>{
                  history(`/group/${group_id}`);
                }}
              >
                {groups[group_id].name}
              </div>
            );
          })}
        </div>
      ) : (null)}

    </div>
  );
}

const mapState = (state)=>{

  const hackToUpdate = Math.random();

  return {
    groups: state.groupReducer.groups,
    user_info: state.sessionReducer.user_info,
    user_groups: state.sessionReducer.user_info.groups,
    group_redirect_id: state.groupReducer.group_redirect_id,
    hackToUpdate
  };
};

export default connect(mapState)(GroupAchievementsPage);
