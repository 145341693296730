import React, { useState } from "react";

import { connect }         from "react-redux";

import { useParams }       from "react-router-dom";

import dispatcher          from "../../redux/dispatcher.js";

import groupUtility        from "../../functions/groups.js";

import GroupMemberCard     from "./GroupMemberCard.js";

import styles              from "../../styles/styles.js";

function GroupMembers(props){

  const {
    groups,
    group_id,
    user_info,
  } = props;

  const params = useParams();

  const [errors, setErrors] = useState({});
  const [invitee, setInvitee] = useState("");
  const [inviteSent, setInviteeSent] = useState(false);
  const [showInviteMemberInput, setShowInviteMemberInput] = useState(false);

  const showInviteMemberInputOnClick = ()=>{
    setShowInviteMemberInput(true);
  };

  const onChangeInvitee = (e)=>{
    if (e !== undefined){
      setInvitee(e.target.value);
    }
  };

  const sendInvite = ()=>{
    const new_errors = validateInvitee();

    if (Object.keys(new_errors).length !== 0){
      setErrors(new_errors);
      return;
    }

    dispatcher("CREATE_GROUP_MEMBER", {
      payload: {
        group_id,
        group_name: groups[group_id].name,
        group_description: groups[group_id].description,
        inviter_id: user_info.uid,
        inviter_display_name: user_info.display_name,
        invitation_type: "email",//email, id (add by url in future)
        invitation_sent_to: invitee,
        status: "invited",//invited, rejected, joined, request, left
        //user name will be fetched from main user data. If user has not joined and viewer is admin, show info user was invited by, if viewer is not admin, do not show invitee. If user joined, but privacy is on, show to admins, but not members.
      },
    });

    setInviteeSent(true);
    setInvitee("");

  };

  const validateInvitee = ()=>{
    let new_errors = {};

    if (invitee === ""){
      new_errors.invitee = "Please fill out the field with an email.";
    }

    return new_errors;
  };

  const getInvitedMembers = ()=>{

    const group = groups[group_id];
    const members = group.members;
    const admin = group.admins.includes(user_info.uid);

    return members && Object.keys(members).filter((member_id)=>{
      const member = members[member_id];

      return (admin && (member.status !== "joined") && (member.status !== "left") && (member.status !== "deleted") && (member.status !== "request"));
    }).map((member_id, key)=>{
      const member = members[member_id];

      return (
        <GroupMemberCard
          key={key}
          viewer={"admin"}
          member={member}
          admins={group.admins}
        />
      );

    });
  };

  const getJoinedMembers = ()=>{

    const group = groups[group_id];
    const members = group.members;

    const admin = group.admins.includes(user_info.uid);

    return members && Object.keys(members).filter((member_id)=>{
      const member = members[member_id];

      return (member.status === "joined");
    }).map((member_id, key)=>{

      const member = members[member_id];

      const viewer = groupUtility.groupMemberStatus(user_info, admin, params.group_id);

      return (
        <GroupMemberCard
          key={key}
          viewer={viewer}
          member={member}
          admins={group.admins}
        />
      );

    });
  };

  const getRequestMembers = ()=>{

    const group = groups[group_id];
    const members = group.members;
    const admin = group.admins.includes(user_info.uid);

    return members && Object.keys(members).filter((member_id)=>{
      const member = members[member_id];

      return (admin && member.status === "request");
    }).map((member_id, key)=>{

      const member = members[member_id];

      return (
        <GroupMemberCard
          request
          key={key}
          viewer={"admin"}
          member={member}
          admins={group.admins}
        />
      );

    });
  };

  const group = groups[group_id];
  const admin = group.admins.includes(user_info.uid);

  const InvitedMembers   = getInvitedMembers().filter((a)=>{return a;});
  const JoinedMembers    = getJoinedMembers().filter((a)=>{return a;});
  const RequestedMembers = getRequestMembers().filter((a)=>{return a;});

  return (
    <div className="group-members" style={{...styles.groupMembers}}>

      {(showInviteMemberInput) ? (
        <div className="group-members-invite-container" style={{...styles.groupMembersInviteContainer}}>

          <div className="group-members-invite-label" style={{...styles.groupMembersInviteLabel}}>Invite Member</div>

          <input
            className="group-members-invitee"
            style={{...styles.groupMembersInvitee}}
            name="invitee"
            type="text"
            placeholder="Invitee email"
            value={invitee}
            required={true}
            onChange={onChangeInvitee}
          />
          {(errors.invitee) && (
            <div className="group-members-invitee-error" style={{...styles.groupMembersInviteeError}}>{errors.invitee}</div>
          )}

          <div
            className="group-members-send-button"
            style={{...styles.groupMembersSendButton}}
            onClick={sendInvite}
          >
            Send Invite
          </div>

          {(inviteSent) && (
            <div className="group-members-send-success-message" style={{...styles.groupMembersSendSuccessMessage}}>
              The invite has been sent to this user. This is an in-app invitation, so no email is sent. The Invitee must login or create an account to join this group.
            </div>
          )}
        </div>
      ) : (
        (admin) ? (
          <div
            className="group-members-invite-button"
            style={{...styles.groupMembersInviteButton}}
            onClick={showInviteMemberInputOnClick}
          >
            Invite Members
          </div>
        ) : (null)
      )}

      {(group) ? (
        <div className="group-members-container" style={{...styles.groupMembersContainer}}>

          <div className="group-members-label" style={{...styles.groupMembersLabel}}>Joined Members</div>

          <div className="line" style={{...styles.line}}></div>

          {JoinedMembers}

          {(admin) && (
            <React.Fragment>
              <div className="group-members-invited-label" style={{...styles.groupMembersInvitedLabel}}>Invited Members</div>

              <div className="line" style={{...styles.line}}></div>

              {(InvitedMembers.length) ? (InvitedMembers) : (
                <div className="group-members-no-invited-message" style={{...styles.groupMembersNoInvitedMessage}}>There are no members invited to this group yet. Invite members using emails below.</div>
              )}
            </React.Fragment>
          )}

          {((admin && RequestedMembers.length) || (admin && (group.settings.joining_type === "request_to_join"))) && (
            <React.Fragment>
              <div className="group-members-request-label" style={{...styles.groupMembersRequestLabel}}>Requests To Join</div>

              <div className="line" style={{...styles.line}}></div>

              {(RequestedMembers.length) ? (RequestedMembers) : (
                <div className="group-members-no-requests-message" style={{...styles.groupMembersNoRequestsMessage}}>There are no requests to join right now.</div>
              )}
            </React.Fragment>
          )}

        </div>
      ) : (null)}

    </div>
  );
}

const mapState = (state)=>{

  const hackToUpdate = Math.random();

  return {
    groups: state.groupReducer.groups,
    user_info: state.sessionReducer.user_info,
    hackToUpdate
  };
};

export default connect(mapState)(GroupMembers);
