export default (onRequestClose)=>{
  if (!window.firebase_ui){
    window.firebase_ui = new window.firebaseui.auth.AuthUI(window.auth);
  }

  window.firebase_ui.start("#firebaseui-auth-container", {
    signInOptions: [
      // window.firebase.auth.EmailAuthProvider.PROVIDER_ID,
      window.firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      window.firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: ()=>{
        console.log("hi?")
        onRequestClose();
        return false;
      },
      signInFailure: function(error){
        console.log(error);

        return (error)=>{
          console.log(error);
        };
      }
    },
    signInSuccessUrl: "/success",
    tosUrl: "/thing",
    privacyPolicyUrl: function(){
      // window.location.assign("<your-privacy-policy-url>");
    },
    signInFlow: "popup",
  });
};
