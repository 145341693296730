import React     from "react";

import styles    from "../../styles/styles.js";

import { upperCase } from "../../functions/utility.js";

function BookmarkMenuItem(props){

  const {
    index,
    onClick,
    section
  } = props;

  return (
    <div
      className="bookmark-menu-item"
      style={{
        ...styles.bookmarkMenuItem,
        borderTop: (index !== 0) ? ("1px solid black") : ("")
      }}
      onClick={onClick}>
      - {upperCase(section.name)}
    </div>
  );
}

export default BookmarkMenuItem;
