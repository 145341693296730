import React           from "react";

import { useNavigate, useParams } from "react-router-dom";

import { connect }     from "react-redux";

import Achievement     from "../achievement/Achievement.js";
import SectionCard     from "../section/SectionCard.js";

import styles          from "../../styles/styles.js";

function GroupAchievements(props){

  const {
    groups,
    user_info,
    sectionsReducer,
    achievementReducer,
  } = props;

  const history = useNavigate();
  const params  = useParams();

  const toNewGroupSection = ()=>{

    window.localStorage.setItem("group_id", params.group_id);
    history("/achievement-workshop/create/section/group");
  };

  const toNewGroupAchievement = ()=>{

    window.localStorage.setItem("group_id", params.group_id);
    history("/achievement-workshop/create/achievement/group");
  };

  const renderSections = ()=>{

    const group = groups[params.group_id];
    const admin_is_creator = group.creator === user_info.uid;

    let sections = [];

    Object.keys(sectionsReducer.group_sections).filter((section_id)=>{
      const section = sectionsReducer.group_sections[section_id];

      return ((section.group_id === params.group_id) && (section.deleted !== "true"));
    }).sort((a, b)=>{
      const textA = sectionsReducer.group_sections[a].name.toUpperCase();
      const textB = sectionsReducer.group_sections[b].name.toUpperCase();

      return (textA < textB) ? (-1) : ((textA > textB) ? (1) : (0));
    }).forEach((section_id, key)=>{
      const section = sectionsReducer.group_sections[section_id];

      sections.push(
        <SectionCard key={`group_section_${key}`} section={section}/>
      );
    });

    return (
      <div id="group-achievements-section-container" style={{...styles.groupAchievementsSectionContainer}}>

        {(admin_is_creator) ? (
          <div id="group-achievements-create-section-button"
            style={{...styles.groupAchievementsCreateSectionButton}}
            onClick={toNewGroupSection}
          >
            Create new section
          </div>
        ) : (null)}

        <div id="group-achievements-section-content" style={{...styles.personalAchievementsSectionContent}}>
          <div id="group-achievements-section-label" style={{...styles.personalAchievementsSectionLabel}}>Sections</div>
          {(sections.length) ? (sections) : (
            <div id="group-achievements-page-no-ections-message" style={{...styles.groupAchievementsPageNoSectionsMessage}}>There are no sections for this group yet.</div>
          )}
        </div>

      </div>
    );
  };

  const renderAchievements = ()=>{

    const group = groups[params.group_id];
    const admin_is_creator = group.creator === user_info.uid;

    const user_achievements = achievementReducer.user_achievements;

    let achievements = [];

    Object.keys(achievementReducer.group_achievements).filter((achievement_id)=>{
      const achievement = achievementReducer.group_achievements[achievement_id];

      return ((achievement.group_id === params.group_id) && (!achievement.deleted));
    }).sort((a, b)=>{
      const textA = achievementReducer.group_achievements[a].name.toUpperCase();
      const textB = achievementReducer.group_achievements[b].name.toUpperCase();

      return (textA < textB) ? (-1) : ((textA > textB) ? (1) : (0));
    }).forEach((achievement_id, key)=>{
      const achievement = achievementReducer.group_achievements[achievement_id];

      const user_achievement = user_achievements[achievement_id];

      const completed = user_achievement?.completed;
      const favorited = user_achievement?.favorited;
      const tracking  = user_achievement?.tracking;
      const completed_date = user_achievement?.created;

      achievements.push(
        <Achievement
          key={`achievement-${key}`}
          achievement={achievement}
          completed={completed}
          favorited={favorited}
          tracking={tracking}
          completed_date={completed_date}
          user_id={user_info.uid}
        />
      );
    });

    return (
      <div id="group-achievements-achievement-container" style={{...styles.groupAchievementsAchievementContainer}}>

        {(admin_is_creator) ? (
          <div id="group-achievements-create-achievement-button"
            style={{...styles.groupAchievementsCreateAchievementButton}}
            onClick={toNewGroupAchievement}
          >
            Create new achievement
          </div>
        ) : (null)}

        <div id="group-achievements-achievement-content" style={{...styles.groupAchievementsAchievementContent}}>
          <div id="group-achievements-achievement-label" style={{...styles.groupAchievementsAchievementLabel}}>Achievements</div>
          {(achievements.length) ? (achievements) : (
            <div id="group-achievements-page-no-achievements-message" style={{...styles.groupAchievementsPageNoAchievementsMessage}}>There are no achievements for this group yet.</div>
          )}
        </div>

      </div>
    );
  };

  const Sections = renderSections();
  const Achievements = renderAchievements();

  return (
    <div id="group-achievements" style={{...styles.groupAchievements}}>

      <div id="group-sections-and-achievements-container" style={{...styles.groupSectionsAndAchievementsContainer}}>

        {Sections}
        {Achievements}

      </div>

    </div>
  );
}

const mapState = (state)=>{
  return {
    user_info: state.sessionReducer.user_info,
    groups: state.groupReducer.groups,
    sectionsReducer: state.sectionReducer,
    achievementReducer: state.achievementReducer,
  };
};

export default connect(mapState)(GroupAchievements);
