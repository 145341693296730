import React, { useState } from "react";

import { connect }   from "react-redux";

import dispatcher    from "../../redux/dispatcher.js";

import styles        from "../../styles/styles.js";

function NoteDetailsComponent(props){
  const {
    details,
    achievement,
    achievementReducer,
    user_info
  } = props;

  const user_achievement = props.achievementReducer.user_achievements[props.achievement.achievement_id];

  let initial_notes;
  if (user_achievement){
    initial_notes = user_achievement.notes;
  } else {
    initial_notes = "";
  }

  const [notes, setNotes] = useState(initial_notes);
  const [saved_notes, setSavedNotes] = useState(initial_notes);

  const saveNotes = ()=>{

    let collection = "global";
    if (achievement.user_id){
      collection = "personal";
    } else if (achievement.group_id){
      collection = "group";
    }

    dispatcher("SAVE_NOTES", {
      payload: {
        ...achievementReducer.user_achievements[achievement.achievement_id],
        achievement_id: achievement.achievement_id,
        user_id: user_info.uid,
        notes
      },
      collection
    });

    setSavedNotes(notes);
  };

  const onChangeSetNotes = (e)=>{
    if (e !== undefined){
      setNotes(e.target.value);
    }
  };

  const {
    subject,
  } = details;

  if (!subject){
    return null;
  }

  let textareaStyle = {};
  let saveButtonStyle = {
    opacity: (saved_notes === notes) ? (0.33) : (1)
  };

  if (achievement.group_id){
    textareaStyle = {backgroundColor: styles.GroupSecondaryColor};
    saveButtonStyle = {
      ...saveButtonStyle,
      backgroundColor: styles.GroupPrimaryColor
    };
  } else if (achievement.user_id){
    textareaStyle = {backgroundColor: styles.PersonalSecondaryColor};
    saveButtonStyle = {
      ...saveButtonStyle,
      backgroundColor: styles.PersonalPrimaryColor
    };
  }

  return (
    <div id="note-details" style={{...styles.detailsStyle}}>

      <textarea
        id="note-details-input"
        style={{...styles.noteDetailsInput, ...textareaStyle}}
        name="notes"
        value={notes}
        placeholder="Add some notes to this achievement for yourself..."
        onChange={onChangeSetNotes}
      />

      <div id="note-details-save" style={{...styles.noteDetailsSave}}>
        <div
          id="note-details-save-button"
          style={{...styles.noteDetailsSaveButton, ...saveButtonStyle}}
          onClick={saveNotes}
        >
          Save
        </div>
      </div>

    </div>
  );
}

const mapState = (state)=>{

  return {
    user_info: state.sessionReducer.user_info,
    achievementReducer: state.achievementReducer,
    details: state.sessionReducer.details || {},
  };
};

export default connect(mapState)(NoteDetailsComponent);
