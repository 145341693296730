import React from "react";

export default function SocialPage(){

  return (
    <div style={{paddingTop: 60}}>
      This is the personal achievements page
    </div>
  );
}
