import React               from "react";
import { useNavigate }     from "react-router-dom";

import { connect }         from "react-redux";
import dispatcher          from "../../redux/dispatcher.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookmark,
  faStar,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import {
  faSquare,
  faCheckSquare,
  faStar as faHollowStar,
  faBookmark as faSolidBookmark
} from "@fortawesome/free-regular-svg-icons";

import moment              from "moment";

import utility             from "../../functions/utility.js";

import styles              from "../../styles/styles.js";

function AchievementMidContainer(props){

  const {
    achievement,
    completed,
    user_info,
    tracking,
    favorited,
    achievementReducer,
    completed_date,
    optionStyle,
    achievementOptionTextStyle,
    achievementDescriptionStyle,
    sectionReducer,
  } = props;

  const history = useNavigate();

  const toggleComplete = ()=>{

    if (!user_info.uid){
      loginModal();
    } else {

      let collection = "global";
      if (achievement.user_id){
        collection = "personal";
      } else if (achievement.group_id){
        collection = "group";
      }

      dispatcher("TOGGLE_COMPLETE_ACHIEVEMENT", {
        collection,
        payload: {
          ...achievementReducer.user_achievements[achievement.achievement_id],
          achievement_id: achievement.achievement_id,
          completed: !completed,
          user_id: user_info.uid
        }
      });

    }

  };

  const toggleTracking = ()=>{

    if (!user_info.uid){
      loginModal();
    } else {

      let collection = "global";
      if (achievement.user_id){
        collection = "personal";
      } else if (achievement.group_id){
        collection = "group";
      }

      dispatcher("TOGGLE_TRACKING_ACHIEVEMENT", {
        collection,
        payload: {
          ...achievementReducer.user_achievements[achievement.achievement_id],
          achievement_id: achievement.achievement_id,
          tracking: !tracking,
          user_id: user_info.uid
        }
      });

    }

  };

  const toggleFavorite = ()=>{

    if (!user_info.uid){
      loginModal();
    } else {

      let collection = "global";
      if (achievement.user_id){
        collection = "personal";
      } else if (achievement.group_id){
        collection = "group";
      }

      dispatcher("TOGGLE_FAVORITE_ACHIEVEMENT", {
        collection,
        payload: {
          ...achievementReducer.user_achievements[achievement.achievement_id],
          achievement_id: achievement.achievement_id,
          favorited: !favorited,
          user_id: user_info.uid
        }
      });

    }

  };

  const loginModal = ()=>{
    dispatcher("ADD_MODAL", {
      name: "SESSION",
      modalProps: {
        login: true,
      }
    });
  };

  const completedTime = (completed_date && completed) ? (`Completed ${moment(completed_date).format("DD MMM, YYYY [at] h:mma")}`) : ("");

  const favoriteTrackingText = (completed) ? ((favorited) ? ("Unfavorite") : ("Favorite")) : ((tracking) ? ("Untrack") : ("Track"));

  return (
    <div className="achievement-bottom-container">

      <div className="achievement-completed-time" style={{...styles.achievementCompletedTime, color: "#f2ead3", marginTop: 16}}>
        {completedTime}
      </div>

      <div className="achievement-description" style={{...achievementDescriptionStyle}}>
        {achievement.description}
      </div>

      {(achievement.suggesting_user_id) ? (
        <div
          className="achievement-parent-section-label"
          style={{...styles.achievementParentSectionLabel}}
          onClick={()=>{
            history(`/achievements/global/${achievement.section}`);
          }}
        >
          Parent Section: {utility.upperCase((
            sectionReducer.global_sections[achievement.section]
          ) ? (
            sectionReducer.global_sections[achievement.section].name
          ) : (
            sectionReducer.suggested_sections[achievement.section].name
          ))}
          <FontAwesomeIcon style={{...styles.suggestedParentLink}} icon={faShare}/>
        </div>
      ) : (null)}

      <div className="achievement-button-container" style={{...styles.achievementButtonContainer}}>

        {(achievement.suggesting_user_id) ? (
          <div className="achievement-space-between-placeholder"></div>
        ) : (
          <React.Fragment>
            <div
              className="achievement-complete-container"
              style={{...styles.achievementCompleteContainer, ...optionStyle}}
              onClick={toggleComplete}
            >
              <FontAwesomeIcon
                className="achievement-complete-icon"
                size="2x"
                icon={(completed) ? (faCheckSquare) : (faSquare)}
              />
              <div className="achievement-complete-text" style={{...achievementOptionTextStyle, width: "100%"}}>
                {(completed) ? ("Uncomplete") : ("Complete")}
              </div>
            </div>

            <div
              className="achievement-favorite-tracking-container"
              style={{...styles.achievementFavoriteTrackingContainer, ...optionStyle}}
              onClick={(completed) ? (toggleFavorite) : (toggleTracking)}
            >
              <FontAwesomeIcon
                className="achievement-favorite-tracking-icon"
                size="2x"
                icon={(completed) ? ((favorited) ? (faStar) : (faHollowStar)) : ((tracking) ? (faBookmark) : (faSolidBookmark))}
              />
              <div className="achievement-favorite-tracking-text" style={{...achievementOptionTextStyle, width: "100%"}}>
                {favoriteTrackingText}
              </div>
            </div>
          </React.Fragment>
        )}

      </div>

    </div>
  );
}

const mapState = function(state){
  return {
    user_info: state.sessionReducer.user_info,
    sectionReducer: state.sectionReducer,
    achievementReducer: state.achievementReducer,
  };
};

export default connect(mapState)(AchievementMidContainer);
