import React               from "react";

import { useNavigate }     from "react-router-dom";

import dispatcher          from "../../redux/dispatcher.js";

import utility             from "../../functions/utility.js";

import styles              from "../../styles/styles.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWindowClose,
  faCheckCircle,
  faExternalLinkAlt
}  from "@fortawesome/free-solid-svg-icons";

function Report(props){

  const {
    report,
    subject,
    general_report,
    section_report,
    achievement_report,
  } = props;

  const history = useNavigate();

  const toReportedSubject = ()=>{

    let section_id;
    if (achievement_report){
      section_id = subject.section;
    } else {
      section_id = subject.section_id;
    }

    history(`/achievements/${report.collection}/${section_id}`);
  };

  const resolveReport = ()=>{
    dispatcher("ADD_MODAL", {
      name: "CONFIRMATION",
      modalProps: {
        header: "Resolve?",
        message: "Mark issue as resolved?",
        okButton: true,
        cancelButton: true,
        callback: ()=>{
          dispatcher("UPDATE_REPORT", {
            payload: {
              resolved: "true",
              deleted: "true"
            },
            report_id: report.report_id
          });
        }
      }
    });
  };

  const removeReport = ()=>{
    dispatcher("ADD_MODAL", {
      name: "CONFIRMATION",
      modalProps: {
        header: "Remove?",
        message: "Remove report?",
        okButton: true,
        cancelButton: true,
        callback: ()=>{
          dispatcher("UPDATE_REPORT", {
            payload: {
              deleted: "true"
            },
            report_id: report.report_id
          });
        }
      }
    });
  };

  let subject_type;
  if (general_report){
    subject_type = "General";
  } else if (section_report){
    subject_type = "Section";
  } else {
    subject_type = "Achievement";
  }

  return (
    <div className="report" style={{...styles.report}}>

      <div className="report-top-container" style={{...styles.reportTopContainer}}>
        <div className="report-subject-type">Report subject type: {subject_type}</div>
        {(!general_report) ? (
          <FontAwesomeIcon
            className="report-link"
            style={{...styles.reportLink}}
            icon={faExternalLinkAlt}
            onClick={toReportedSubject}
          />
        ) : (null)}
      </div>

      {(subject) ? (
        <div className="report-subject-name-container" style={{...styles.reportSubjectNameContainer}}>
          <div className="report-subject-name">
            {subject_type} name: {utility.upperCase(subject.name)}
          </div>
        </div>
      ) : (null)}

      <div className="report-notes-container" style={{...styles.reportNotesContainer}}>
        <div className="report-notes-label" style={{...styles.reportNotesLabel}}>Reporter's Notes:</div>
        <div className="report-notes">
          "{report.notes}"
        </div>
      </div>

      <div className="report-buttons-container" style={{...styles.reportButtonsContainer}}>
        <FontAwesomeIcon
          className="report-remove-button"
          style={{...styles.reportRemoveButton}}
          icon={faWindowClose}
          onClick={removeReport}
        />
        <FontAwesomeIcon
          className="report-resolve-button"
          style={{...styles.reportResolveButton}}
          icon={faCheckCircle}
          onClick={resolveReport}
        />
      </div>

    </div>
  );
}

export default Report;
