import React, { FC }       from "react";

import dispatcher          from "../../redux/dispatcher.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleUp,
  faAngleDown,
}  from "@fortawesome/free-solid-svg-icons";

import styles    from "../../styles/styles.js";

interface Props {
  achievement: {
    down_votes: string[],
    up_votes: string[],
    achievement_id: string,
  },
  achievement_id: number,
  user_info: {
    uid: string
  },
  vote_number: number,
}

const suggestedAchievementVotingContainer: React.CSSProperties = {
  ...styles.suggestedAchievementVotingContainer,
  flexDirection: "column",
}

const AchievementVotingComponent: FC<Props> = (props)=>{

  const {
    achievement,
    achievement_id,
    user_info,
    vote_number,
  } = props;

  const loginModal = ()=>{
    dispatcher("ADD_MODAL", {
      name: "SESSION",
      modalProps: {
        login: true,
      }
    });
  };

  const achievementVote = (achievement_id: string, vote_type: string)=>{

    if (!user_info.uid){
      loginModal();
    } else {
      dispatcher("EDIT_ACHIEVEMENT", {
        collection: "suggested",
        payload: {},
        vote_type,
        achievement_id,
        user_id: user_info.uid,
      });
    }
  };

  return (
    <div id={`suggested-achievement-voting-container-${achievement_id}`} style={{...suggestedAchievementVotingContainer}}>
      <div
        id={`suggested-achievement-vote-up-${achievement_id}`}
        style={{}}
        onClick={()=>{
          if (achievement.down_votes && achievement.down_votes.includes(user_info.uid)){
            achievementVote(achievement.achievement_id, "down_votes");
          }

          achievementVote(achievement.achievement_id, "up_votes");
        }}
      >
        <FontAwesomeIcon
          id={`suggested-achievement-vote-up-arrow-${achievement_id}`}
          style={{
            ...styles.Pointer,
            color: (achievement.up_votes && achievement.up_votes.includes(user_info.uid)) ? ("#7dbd81") : ("black"),
            opacity: (achievement.up_votes && achievement.up_votes.includes(user_info.uid)) ? (0.66) : (1)
          }}
          size="2x"
          icon={faAngleUp}
        />
      </div>

      {vote_number}

      <div
        id={`suggested-achievement-vote-down-${achievement_id}`}
        style={{}}
        onClick={()=>{
          if (achievement.up_votes && achievement.up_votes.includes(user_info.uid)){
            achievementVote(achievement.achievement_id, "up_votes");
          }

          achievementVote(achievement.achievement_id, "down_votes");
        }}
      >
        <FontAwesomeIcon
          id={`suggested-achievement-vote-down-arrow-${achievement_id}`}
          style={{
            ...styles.Pointer,
            color: (achievement.down_votes && achievement.down_votes.includes(user_info.uid)) ? (styles.Red) : ("black"),
            opacity: (achievement.down_votes && achievement.down_votes.includes(user_info.uid)) ? (0.66) : (1)
          }}
          size="2x"
          icon={faAngleDown}
        />
      </div>
    </div>
  );
}

export default AchievementVotingComponent;
