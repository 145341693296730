import React, { useState, useEffect } from "react";

import styles from "../../styles/styles.js";

export default function ConfirmationComponent(props){

  const {
    header,
    message,
    messageTwo,
    containerStyle,
    messageStyle,
    timeout,
    callback,
    okButton,
    okButtonText: okButtonTextProp,
    okButtonStyle,
    callback2,
    cancelButton,
    cnacelButtonText: cancelButtonTextProp,
    cancelButtonStyle,
    radioSelection,
    timedDisappear,
    onRequestClose,
  } = props;

  const [focus, setFocus] = useState((radioSelection) ? (radioSelection[0].name) : (null));

  useEffect(()=>{

    if (timedDisappear){
      setTimeout(()=>{
        if (callback){
          callback();
        }
        onRequestClose();
      }, timeout || 3000);
    }

  }, []);

  // onRequestClose = ()=>{
  //
  //   onRequestClose();
  // };

  const getOkButton = ()=>{

    const okButtonText = (okButtonTextProp || "OKAY");

    return (okButton) ? (
      <div
        id="confirmation-ok-button"
        style={{...styles.confirmationOkButton, ...okButtonStyle}}
        onClick={()=>{
          if (callback){
            callback(focus);
          }
          onRequestClose();
        }}>
        {okButtonText}
      </div>
    ) : (<div id="confirmation-ok-button-spacer" style={{...styles.confirmationOkButtonSpacer}}></div>);
  };

  const getCancelButton = ()=>{

    const cancelButtonText = (cancelButtonTextProp || "CANCEL");

    return (cancelButton) ? (
      <div
        id="confirmation-cancel-button"
        style={{...styles.confirmationCancelButton, ...cancelButtonStyle}}
        onClick={()=>{
          if (callback2){
            callback2();
          }
          onRequestClose();
        }}
      >
        {cancelButtonText}
      </div>
    ) : (<div id="confirmation-cancel-button-spacer" style={{...styles.confirmationCancelButtonSpacer}}></div>);
  };

  const getRadioSelection = ()=>{

    return (radioSelection) ? (
      <div id="confirmation-select" style={{...styles.confirmationSelect}}>
        {radioSelection.map((choice, key)=>{
          return (
            <div
              key={key}
              className="confirmation-select-choice"
              style={{...styles.confirmationSelectChoice}}
              onClick={()=>{
                setFocus(choice.name);
              }}
            >
              <div className="confirmation-select-text" style={{...styles.confirmationSelectText}}>
                {choice.text}
              </div>
              {(focus === choice.name) ? (
                <div className="confirmation-select-name" style={{...styles.confirmationSelectName}}></div>
              ): (null)}
            </div>
          );
        })}
      </div>
    ) : (null);
  };

  const OkButton       = getOkButton();
  const CancelButton   = getCancelButton();
  const RadioSelection = getRadioSelection();

  return (
    <div id="confirmation" style={{...styles.confirmation, ...containerStyle}}>
      <div id="confirmation-content" style={{...styles.confirmationContent}}>

        <div id="confirmation-header" style={{...styles.confirmationHeader}}>
          {header}
        </div>

        <div id="confirmation-message" style={{...styles.confirmationMessage, ...messageStyle}}>
          {message}
        </div>

        <div id="confirmation-message-two" style={{...styles.confirmationMessageTwo}}>
          {messageTwo}
        </div>

        {RadioSelection}

        <div id="confirmation-button-container" style={{...styles.confirmationButtonContainer}}>

          {CancelButton}

          {OkButton}

        </div>
      </div>
    </div>
  );
}
