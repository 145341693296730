import React               from "react";

import { connect }         from "react-redux";

import { useLocation, useNavigate } from "react-router-dom";

import dispatcher          from "../../redux/dispatcher.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrash,
  faToolbox,
  faComments,
  faUsers,
  faGraduationCap,
} from "@fortawesome/free-solid-svg-icons";

import styles              from "../../styles/styles.js";

function AchievementBottomContainer(props){

  const {
    user_info,
    achievement,
    groups,
    achievementStyles
  } = props;

  const history = useNavigate();
  const location = useLocation();

  const openToolsDetails = ()=>{

    let collection = "global";
    if (achievement.user_id){
      collection = "personal";
    } else if (achievement.group_id){
      collection = "group";
    } else if (achievement.suggesting_user_id){
      collection = "suggested";
    }

    dispatcher("OPEN_DETAILS", {
      subject: "tools",
      collection,
      achievement_id: achievement.achievement_id,
      group_id: achievement.group_id
    });
  };

  const openReport = ()=>{

    if (!user_info.uid){
      loginModal();
    } else {
      dispatcher("OPEN_DETAILS", {
        subject: "report",
        collection: (achievement.group_id) ? ("group") : ("global"),
        achievement_id: achievement.achievement_id,
        group_id: achievement.group_id
      });
    }
  };

  const approveSuggestedAchievement = ()=>{

    dispatcher("GET_LINKS", {
      related_id: achievement.achievement_id,
      collection: "suggested",
    });

    dispatcher("ADD_MODAL", {
      name: "CONFIRMATION",
      modalProps: {
        header: "Are you sure?",
        message: "Are you certain you want to approve this achievement to become an official global achievement for all users?",
        okButton: true,
        okButtonText: "APPROVE",
        cancelButton: true,
        callback: ()=>{

          let payload = {
            ...achievement,
            suggested_user_id: achievement.suggesting_user_id,
            approved_admin: user_info.uid,
            suggested_achievement_id: achievement.achievement_id
          };
          delete payload.suggesting_user_id;
          delete payload.up_votes;
          delete payload.down_votes;
          delete payload.achievement_id;
          delete payload.info;
          delete payload.tools;
          delete payload.created;

          dispatcher("CREATE_ACHIEVEMENT", {
            collection: "global",
            payload: {
              ...payload
            },
            tools: achievement.tools,
            info: achievement.info,
            callback: ()=>{
              //TODO empty callback to prevent faulty setup from redirecting user
            }
          });
        }
      }
    });
  };

  const upcomingFeature = ()=>{
    dispatcher("ADD_MODAL", {
      name: "CONFIRMATION",
      modalProps: {
        header: "Help our project!",
        message: "This feature has not been added yet, but if you would like to help us get this working, please support me over on Patreon!",
        okButton: true,
        okButtonText: "To Patreon",
        okButtonStyle: {backgroundColor: styles.GroupPrimaryColor},
        cancelButton: true,
        cancelButtonText: "Close",
        callback: ()=>{
          window.location.href = "https://www.patreon.com/calebsundance";
        }
      }
    });
  };

  const adminEdit = ()=>{

    let collection = "global";
    if (achievement.user_id){
      collection = "personal";
    } else if (achievement.group_id){
      collection = "group";
    } else if (achievement.suggesting_user_id){
      collection = "suggested";
    }

    history(`/achievement-workshop/edit/achievement/${collection}/${achievement.achievement_id}`);
  };

  const adminDelete = ()=>{

    let collection = "global";
    if (achievement.user_id){
      collection = "personal";
    } else if (achievement.group_id){
      collection = "group";
    } else if (achievement.suggesting_user_id){
      collection = "suggested";
    }

    dispatcher("ADD_MODAL", {
      name: "CONFIRMATION",
      modalProps: {
        header: "Are you sure?",
        message: "Are you certain you want to delete this achievement?",
        okButton: true,
        okButtonText: "DELETE",
        okButtonStyle: {backgroundColor: styles.Red},
        cancelButton: true,
        callback: ()=>{

          let admin_edit;
          if (achievement.suggesting_user_id){
            admin_edit = (user_info.uid !== achievement.suggesting_user_id);
          }

          dispatcher("DELETE_ACHIEVEMENT", {
            collection,
            admin_edit,
            name: achievement.name,
            section_id: achievement.section,
            achievement_id: achievement.achievement_id,
            suggesting_user_id: achievement.suggesting_user_id
          });
        }
      }
    });
  };

  const loginModal = ()=>{
    dispatcher("ADD_MODAL", {
      name: "SESSION",
      modalProps: {
        login: true,
      }
    });
  };

  const getGroupAdmin = ()=>{

    let group_admin;
    if (achievement.group_id){
      if (groups[achievement.group_id].admins.includes(user_info.uid)){
        group_admin = true;
      }
    }
    return group_admin;
  };

  const group_admin = getGroupAdmin();

  const {
    optionStyle,
    achievementTextStyle
  } = achievementStyles;

  return (
    <div
      id={achievement.achievement_id + "-bottom-container"}
      className="achievement-more-info-container"
      style={{...styles.achievementMoreInfoContainer}}
    >

      <div
        className="achievement-tools-container"
        style={{...optionStyle, marginTop: 0}}
        onClick={openToolsDetails}
      >
        <FontAwesomeIcon
          className="achievement-tools-icon"
          style={{marginRight: 8}}
          size="2x"
          icon={faToolbox}
        />
        <div className="achievement-tools-text" style={{...styles.achievementToolsText}}>Tools and More</div>
      </div>

      {(achievement.suggesting_user_id && location.pathname.includes("workshop") && user_info.admin) ? (
        <div
          className="achievement-suggestion-approve-container"
          style={{...optionStyle, marginTop: 0, backgroundColor: styles.PersonalPrimaryColor, color: styles.White, justifyContent: "center"}}
          onClick={approveSuggestedAchievement}
        >
          <div className="achievement-suggestion-approve-text" style={{...styles.achievementToolsText}}>Approve</div>
        </div>
      ) : (null)}

      {(!achievement.user_id && !achievement.suggesting_user_id) ? (
        <React.Fragment>
          <div
            className="achievement-discuss-container"
            style={{...optionStyle, ...styles.notReleasedYet}}
            onClick={upcomingFeature}
          >
            <FontAwesomeIcon
              className="achievement-discuss-icon"
              style={{...styles.achievementAdviceIcon}}
              size="2x"
              icon={faComments}
            />
            <div className="achievement-discuss-text" style={{...styles.achievementDiscussText}}>Discuss</div>
          </div>

          <div
            className="achievement-collaboration-container"
            style={{...optionStyle, ...styles.notReleasedYet}}
            onClick={upcomingFeature}
          >
            <FontAwesomeIcon
              className="achievement-collaboration-icon"
              style={{...styles.achievementAdviceIcon}}
              size="2x"
              icon={faUsers}
            />
            <div className="achievement-collaboration-text" style={{...styles.achievementCollaborationText}}>Collaborate</div>
          </div>

          <div
            className="achievement-advice-container"
            style={{...optionStyle, ...styles.notReleasedYet}}
            onClick={upcomingFeature}
          >
            <FontAwesomeIcon
              className="achievement-advice-icon"
              style={{...styles.achievementAdviceIcon}}
              size="2x"
              icon={faGraduationCap}
            />
            <div className="achievement-advice-text" style={{...styles.achievementAdviceText}}>Advice</div>
          </div>
        </React.Fragment>
      ) : (null)}

      {(user_info.admin || achievement.user_id || group_admin) ? (
        <div className="achievement-admin-buttons-container" style={{...styles.achievementAdminButtonsContainer}}>

          <div
            className="achievement-admin-edit-button"
            style={{...styles.achievementAdminEditButton}}
            onClick={adminEdit}
          >
            <FontAwesomeIcon
              className="achievement-admin-edit-icon"
              style={{...styles.achievementAdminEditIcon}}
              size="2x"
              icon={faPen}
            />
          </div>

          <div
            className="achievement-admin-delete-button"
            style={{...styles.achievementAdminDeleteButton}}
            onClick={adminDelete}
          >
            <FontAwesomeIcon
              className="achievement-admin-delete-icon"
              style={{...styles.achievementAdminDeleteIcon}}
              size="2x"
              icon={faTrash}
            />
          </div>

        </div>
      ) : (
        (achievement.suggesting_user_id) ? (null) : (
          <div className="achievement-report-container" style={{...styles.achievementReportContainer}}>

            <img
              className="achievement-report-button"
              style={{...styles.achievementReportButton}}
              src="../../../images/flag.png"
              alt="Report icon"
              onClick={openReport}
            />

            <div className="achievement-report-text" style={{...achievementTextStyle}}>
              Report
            </div>

          </div>
        )
      )}

    </div>
  );
}

const mapState = function(state){
  return {
    user_info: state.sessionReducer.user_info,
    groups: state.groupReducer.groups,
  };
};

export default connect(mapState)(AchievementBottomContainer);
