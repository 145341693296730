import React, { useState, useEffect } from "react";

import { connect }       from "react-redux";

import { useParams }    from "react-router-dom";

import dispatcher        from "../../redux/dispatcher.js";

import groupUtility      from "../../functions/groups.js";

import GroupMembers      from "../groups/GroupMembers.js";
import GroupAchievements from "../groups/GroupAchievements.js";
import GroupSettings     from "../groups/GroupSettings.js";

import styles            from "../../styles/styles.js";

import {
  FontAwesomeIcon
} from "@fortawesome/react-fontawesome";
import {
  faCogs,
  faInfoCircle
}  from "@fortawesome/free-solid-svg-icons";

function GroupPage(props){

  const {
    groups,
    user_info,
  } = props;

  //TODO add check to see if user created first group, if so, switch to "members"
  const [selected_tab, setSelectedTab] = useState("achievements");

  const params = useParams();

  useEffect(()=>{
    dispatcher("GET_SINGLE_GROUP", {
      group_id: params.group_id,
      user_groups: user_info.groups,
      group_page: true,
    });
  }, []);

  const switchTab = (new_selected_tab)=>{
    setSelectedTab(new_selected_tab);
  };

  const getActiveTabContent = ()=>{

    let TabContent;
    if (selected_tab === "achievements"){
      TabContent = (
        <GroupAchievements />
      );
    } else if (selected_tab === "members"){
      TabContent = (
        <GroupMembers
          group_id={params.group_id}
        />
      );
    } else if (selected_tab === "settings"){
      TabContent = (
        <GroupSettings
          group_id={params.group_id}
        />
      );
    }

    return TabContent;
  };

  const requestToJoinGroup = ()=>{

    if (!user_info.uid){
      loginModal();
    } else {

      const group = groups[params.group_id];

      dispatcher("CREATE_GROUP_MEMBER", {
        admin_ids: group.admins,
        payload: {
          display_name: user_info.display_name,
          user_id: user_info.uid,
          group_id: params.group_id,
          group_name: group.name,
          group_description: group.description,
          invitation_type: "id",//email, id (add by url in future)
          invitation_sent_to: user_info.uid,
          status: "request",
        }
      });

      dispatcher("ADD_MODAL", {//TODO Move to actual success
        name: "CONFIRMATION",
        modalProps: {
          header: "Success",
          message: "Request to join this group has been sent.",
          okButton: true
        }
      });
    }
  };

  const joinGroup = ()=>{

    if (!user_info.uid){
      loginModal();
    } else {

      const group = groups[params.group_id];

      dispatcher("JOIN_GROUP", {
        invitation_type: "open_join",
        display_name: user_info.display_name,
        user_id: user_info.uid,
        group_id: params.group_id,
        group_name: group.name,
        joining_type: "open",
        admin_ids: group.admins
      });
    }

  };

  const loginModal = ()=>{
    dispatcher("ADD_MODAL", {
      name: "SESSION",
      modalProps: {
        login: true,
      }
    });
  };

  const membersTabStyle      = (selected_tab === "members") ? (styles.groupPageActiveTab) : (styles.groupPageInactiveTab);
  const settingsTabStyle     = (selected_tab === "settings") ? (styles.groupPageActiveTab) : (styles.groupPageInactiveTab);
  const achievementsTabStyle = (selected_tab === "achievements") ? (styles.groupPageActiveTab) : (styles.groupPageInactiveTab);

  const activeTabContent = getActiveTabContent();

  const group = groups[params.group_id];

  const admin = group.admins.includes(user_info.uid);
  const viewer = groupUtility.groupMemberStatus(user_info, admin, params.group_id);

  return (
    (group) ? (
      <div id="group-page" style={{...styles.groupPage}}>

        <div id="group-page-header" style={{...styles.groupPageHeader}}>{group.name}</div>

        {(user_info.groups && !user_info.groups[params.group_id]) ? (

          <div id="group-page-join-buttons-container">

            {(group.settings.joining_type === "request_to_join") ? (

              <div
                id="group-page-request-to-join-button"
                style={{...styles.groupPageRequestToJoinButton}}
                onClick={requestToJoinGroup}
              >
                Request to Join
              </div>

            ) : (

              (group.settings.joining_type === "open_join") ? (
                <div
                  id="group-page-join-group-button"
                  style={{...styles.groupPageJoinGroupButton}}
                  onClick={joinGroup}
                >
                  Join Group
                </div>
              ) : (
                <div id="group-page-invite-only-message" style={{margin: 16}}>This group is invite only.</div>
              )

            )}

          </div>

        ) : (null)}

        <div id="group-page-tab-container" style={{...styles.groupPageTabContainer}}>

          <div id="group-page-tab-options" style={{...styles.groupPageTabOptions}}>

            <div
              id="group-page-members-tab"
              style={membersTabStyle}
              onClick={()=>{
                switchTab("members");
              }}
            >
              Members
            </div>

            <div
              id="group-page-achievements-tab"
              style={achievementsTabStyle}
              onClick={()=>{
                switchTab("achievements");
              }}
            >
              Achievements
            </div>

            <div
              id="group-page-options-tab"
              style={settingsTabStyle}
              onClick={()=>{
                switchTab("settings");
              }}
            >
              <FontAwesomeIcon style={{}} icon={(viewer === "non-member") ? (faInfoCircle) : (faCogs)}/>
            </div>

          </div>

          <div id="group-page-tab-content" style={{...styles.groupPageTabContent}}>
            {activeTabContent}
          </div>

        </div>

      </div>
    ) : (
      <div id="group-page-loading-message-container" style={{...styles.groupPageLoadingMessageContainer}}>
        <div id="group-page-loading-message" style={{...styles.groupPageLoadingMessage}}>Loading...</div>
      </div>
    )
  );
}

const mapState = (state)=>{

  const hackToUpdate = Math.random();

  return {
    user_info: state.sessionReducer.user_info,
    groups: state.groupReducer.groups,
    hackToUpdate
  };
};

export default connect(mapState)(GroupPage);
