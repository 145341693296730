import React, { useState } from "react";

import { connect } from "react-redux";

import dispatcher  from "../../redux/dispatcher.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTimes,
  faTrash,
  faUndoAlt,
  faExternalLinkAlt
} from "@fortawesome/free-solid-svg-icons";

import styles      from "../../styles/styles.js";

function LinkComponent(props){

  const {
    subject,
    user_info,
    link_data,
    link_collection,
    toggleAddAdminLink,
    toggleAddPersonalLink,
  } = props;

  const {
    collection,
    achievement_id,
    group_id,
  } = props.details;

  const [status, setStatus] = useState((props.link_data) ? ("DISPLAY") : ("CREATING"));
  const [link_title, setLinkTitle] = useState((props.link_data) ? (props.link_data.title) : (""));
  const [link_description, setLinkDescription] = useState((props.link_data) ? (props.link_data.description) : (""));
  const [link_url, setLinkUrl] = useState((props.link_data) ? (props.link_data.url) : (""));
  const [errors, setErrors] = useState({});

  let altered_link_url;

  const onChangeLinkTitle = (e)=>{
    if (e !== undefined){
      setLinkTitle(e.target.value);
    }
  };

  const onChangeLinkDescription = (e)=>{
    if (e !== undefined){
      setLinkDescription(e.target.value);
    }
  };

  const onChangeLinkUrl = (e)=>{
    altered_link_url = undefined;

    if (e !== undefined){
      setLinkUrl(e.target.value);
    }
  };

  const isValidUrl = (string)=>{
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  };

  const validateLink = ()=>{

    let new_errors = {};

    if (link_title === ""){
      new_errors.link_title = "Please fill out the title field";
    }

    if (link_url === ""){
      new_errors.link_url = "Please fill out the url field";
    }

    if (!link_url.includes("http")){
      altered_link_url = "https://" + link_url;
    } else if (!isValidUrl(link_url)){
      new_errors.link_url = "Please enter a valid url.";
    }

    return new_errors;
  };

  const createLink = ()=>{
    const new_errors = validateLink();
    if (Object.keys(new_errors).length !== 0){
      setErrors(new_errors);
      return;
    }

    dispatcher("CREATE_LINK", {
      payload: {
        title: link_title,
        description: link_description,
        url: altered_link_url || link_url,
        link_for: "achievement",
        subject,
        related_id: achievement_id,
        collection,
        link_collection,
        user_id: (link_collection === "personal") ? (user_info.uid) : (null),//
        group_id: (link_collection === "group") ? (group_id) : (null),
      },
    });

    if (link_collection === "personal"){
      toggleAddPersonalLink();
    } else {
      toggleAddAdminLink();
    }

  };

  const editLink = ()=>{
    const new_errors = validateLink();
    if (Object.keys(new_errors).length !== 0){
      setErrors(new_errors);
      return;
    }

    dispatcher("UPDATE_LINK", {
      payload: {
        ...link_data,
        title: link_title,
        description: link_description,
        url: altered_link_url || link_url,
      },
    });

    setStatus("DISPLAY");

  };

  const toggleCreatingEditing = ()=>{
    setStatus((status === "DISPLAY") ? ("EDITING") : ("DISPLAY"));
  };

  const deleteLink = ()=>{

    dispatcher("UPDATE_LINK", {
      payload: {
        ...link_data,
        deleted: "true"
      }
    });
  };

  const getDisplayedLink = ()=>{

    let primaryColor;
    let secondaryColor;
    if (link_collection === "personal"){
      primaryColor = styles.PersonalPrimaryColor;
      secondaryColor = styles.PersonalSecondaryColor;
    } else if (link_collection === "group"){
      primaryColor = styles.GroupPrimaryColor;
      secondaryColor = styles.GroupSecondaryColor;
    } else if (link_collection === "suggested"){
      primaryColor = styles.Grey;
      secondaryColor = styles.Black;
    } else {
      primaryColor = styles.SecondaryColorMedium;
      secondaryColor = styles.SecondaryColorLight;
    }

    return (
      <div className="link-displayed" style={{...styles.linkDisplayed}}>

        <div className="link-displayed-container" style={{...styles.linkDisplayedContainer}}>

          <div className="link-displayed-content" style={{...styles.linkDisplayedContent, backgroundColor: primaryColor}}>

            <div
              className="link-displayed-edit"
              style={{...styles.linkDisplayedEdit}}
              onClick={toggleCreatingEditing}
            >
              <FontAwesomeIcon
                className="link-displayed-edit-icon"
                style={{...styles.linkDisplayedEditIcon, color: secondaryColor}}
                icon={faPen}
              />
            </div>

            <div className="link-displayed-title" style={{...styles.linkDisplayedTitle}}>
              {link_data.title}
            </div>

            <FontAwesomeIcon
              className="link-displayed-icon"
              style={{...styles.linkDisplayedIcon}}
              onClick={()=>{
                dispatcher("CLOSE_DETAILS");
                window.location.href = link_data.url;
              }}
              icon={faExternalLinkAlt}
            />

          </div>

          {(link_data.description) ? (
            <div className="link-displayed-description" style={{
              ...styles.linkDisplayedDescription,
              backgroundColor: secondaryColor,
              color: (link_collection === "suggested") ? (styles.White) : (styles.Black)
            }}>
              {link_data.description}
            </div>
          ) : (null)}

        </div>
      </div>
    );
  };

  const getCreatingEditingLink = ()=>{

    let primaryColor;
    let secondaryColor;
    if (link_collection === "personal"){
      primaryColor = styles.PersonalPrimaryColor;
      secondaryColor = styles.PersonalSecondaryColor;
    } else if (link_collection === "group"){
      primaryColor = styles.GroupPrimaryColor;
      secondaryColor = styles.GroupSecondaryColor;
    } else if (link_collection === "suggested"){
      primaryColor = styles.Grey;
      secondaryColor = styles.Black;
    } else {
      primaryColor = styles.SecondaryColorMedium;
      secondaryColor = styles.SecondaryColorLight;
    }

    const onClickCancel = (status === "CREATING") ? ((link_collection === "personal") ? (toggleAddPersonalLink) : (toggleAddAdminLink)) : (toggleCreatingEditing);
    const cancelIcon = (status === "CREATING") ? (faTimes) : (faUndoAlt);

    return (
      <div className="link-create-edit" style={{...styles.linkCreateEdit}}>

        <div className="link-create-edit-container" style={{...styles.linkCreateEditContainer}}>

          <div className="link-create-edit-title-container" style={{...styles.linkCreateEditTitleContainer, backgroundColor: primaryColor}}>

            <div
              className="link-create-edit-cancel-container"
              style={{...styles.linkCreateEditCancelContainer}}
              onClick={onClickCancel}
            >
              <FontAwesomeIcon
                className="link-create-edit-cancel-icon"
                style={{...styles.linkCreateEditCancelIcon, color: secondaryColor}}
                icon={cancelIcon}
              />
            </div>

            <div className="link-create-edit-title" style={{...styles.linkCreateEditTitle}}>
              <input
                className="link-create-edit-title-input"
                style={{...styles.linkCreateEditTitleInput}}
                name="link_title"
                type="text"
                placeholder="Title"
                value={link_title}
                required={true}
                onChange={onChangeLinkTitle}
              />
            </div>

            {(status === "EDITING") ? (
              <FontAwesomeIcon
                className="link-create-edit-delete-icon"
                style={{...styles.linkCreateEditDeleteIcon}}
                onClick={deleteLink}
                icon={faTrash}
              />
            ) : (
              <div className="link-create-edit-delete-error" style={{...styles.linkCreateEditDeleteError}}></div>
            )}

          </div>

          <div className="link-create-edit-description" style={{...styles.linkCreateEditDescription, backgroundColor: secondaryColor}}>

            <input
              className="link-create-edit-description-input"
              style={{...styles.linkCreateEditDescriptionInput}}
              name="link_description"
              type="text"
              placeholder="Description (Optional)"
              value={link_description}
              onChange={onChangeLinkDescription}
            />

          </div>

          <div className="link-create-edit-url" style={{...styles.linkCreateEditUrl}}>

            <input
              className="link-create-edit-url-input"
              style={{...styles.linkCreateEditUrlInput}}
              name="link_url"
              type="text"
              placeholder="Link Address"
              value={link_url}
              required={true}
              onChange={onChangeLinkUrl}
            />

          </div>

        </div>

        {(errors.personal_link_title) && (
          <div className="link-create-edit-title-error" style={{...styles.linkCreateEditTitleError}}>{errors.personal_link_title}</div>
        )}
        {(errors.personal_link_description) && (
          <div className="link-create-edit-description-error" style={{...styles.linkCreateEditDescriptionError}}>{errors.personal_link_description}</div>
        )}
        {(errors.personal_link_url) && (
          <div className="link-create-edit-url-error" style={{...styles.linkCreateEditUrlError}}>{errors.personal_link_url}</div>
        )}

        <div
          className="link-create-edit-save-button"
          style={{...styles.linkCreateEditSaveButton, backgroundColor: primaryColor}}
          onClick={(link_data) ? (editLink) : (createLink)}
        >
          {(link_data) ? ("Save") : ("Add")}
        </div>
      </div>
    );
  };

  let ComponentContent;
  if (status !== "DISPLAY"){
    ComponentContent = getCreatingEditingLink();
  } else {
    ComponentContent = getDisplayedLink();
  }

  return (
    ComponentContent
  );
}

const mapState = (state)=>{

  return {
    user_info: state.sessionReducer.user_info,
  };
};

export default connect(mapState)(LinkComponent);
