import React     from "react";
import { useParams } from "react-router-dom";

import { connect }   from "react-redux";

import dispatcher    from "../../redux/dispatcher.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from "@fortawesome/free-regular-svg-icons";
import { faPen, faBookmark as faSolidBookmark } from "@fortawesome/free-solid-svg-icons";

import styles    from "../../styles/styles.js";

function SectionHeaderOptions(props){

  const {
    user_info,
    user_sections,
    collection,
  } = props;
  const params = useParams();

  const openSectionOptionsModal = ()=>{

    dispatcher("ADD_MODAL", {
      name: "SECTION_OPTIONS",
      modalProps: {
        section_id: params.section,
        collection,
      },
    });
  };

  const toggleBookmark = ()=>{

    if (!user_info.uid){
      loginModal();
    } else {
      const user_section = user_sections[params.section];

      dispatcher("TOGGLE_SECTION_BOOKMARK", {
        payload: {
          ...user_section,
          collection,
          section_id: params.section,
          bookmarked: (user_section) ? (!user_section.bookmarked) : (true),
          user_id: user_info.uid
        }
      });
    }
  };

  const loginModal = ()=>{
    dispatcher("ADD_MODAL", {
      name: "SESSION",
      modalProps: {
        login: true,
      }
    });
  };

  const user_section = user_sections[params.section] || {};

  let buttonStyle;
  if (collection === "group"){
    buttonStyle = {
      color: styles.GroupSecondaryColor,
      backgroundColor: styles.GroupPrimaryColor
    };
  } else if (collection === "personal"){
    buttonStyle = {
      color: styles.PersonalSecondaryColor,
      backgroundColor: styles.PersonalPrimaryColor
    };
  } else if (collection === "suggested"){
    buttonStyle = {
      color: styles.White,
      backgroundColor: styles.Grey
    };
  } else {
    buttonStyle = {
      color: styles.SecondaryColorLight,
      backgroundColor: styles.SecondaryColorMedium
    };
  }

  return (
    <div id="section-header-options" style={{...styles.sectionHeaderOptions}}>
      <FontAwesomeIcon
        id="section-header-options-edit"
        style={{
          ...styles.sectionHeaderOptionsEdit,
          ...buttonStyle
        }}
        size="2x"
        icon={faPen}
        onClick={openSectionOptionsModal}
      />

      {(collection === "suggested") ? (
        <div id="section-header-options-positioning-placeholder"></div>
      ) : (
        <FontAwesomeIcon
          id="section-header-options-bookmark"
          style={{
            ...styles.sectionHeaderOptionsBookmark,
            ...buttonStyle
          }}
          size="2x"
          icon={(user_section.bookmarked) ? (faSolidBookmark) : (faBookmark)}
          onClick={toggleBookmark}
        />
      )}
    </div>
  );
}


const mapState = function(state){

  const hackToUpdate = Math.random();

  return {
    user_info: state.sessionReducer.user_info,
    user_sections: state.sectionReducer.user_sections,
    hackToUpdate
  };
};

export default connect(mapState)(SectionHeaderOptions);
