import React     from "react";

import dispatcher  from "../../redux/dispatcher.js";

import styles    from "../../styles/styles.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes }    from "@fortawesome/free-solid-svg-icons";

function GroupInvite(props){

  const {
    user_info,
    group_invite,
    group_invite_id,
  } = props;

  const close = ()=>{
    dispatcher("REMOVE_MODAL", "GROUP_INVITE");
  };

  const decline = ()=>{
    dispatcher("DECLINE_GROUP", {
      group_invite_id
    });

    close();
  };

  const join = ()=>{

    dispatcher("JOIN_GROUP", {
      group_invite_id,
      display_name: user_info.display_name,
      user_id: user_info.uid,
      group_id: group_invite.group_id,
      group_name: group_invite.group_name,
      inviter_id: group_invite.inviter_id,
      joining_type: "invite"
    });

    close();
  };

  return (
    <div className="group-invite" style={{...styles.groupInvite}}>

      <div className="group-invite-header-container" style={{...styles.groupInviteHeaderContainer}}>
        <div className="group-invite-header-spacer" style={{...styles.groupInviteHeaderSpacer}}></div>
        <div className="group-invite-header" style={{...styles.groupInviteHeader}}>Group Invite</div>
        <FontAwesomeIcon
          className="group-invite-header-close-icon"
          style={{...styles.groupInviteHeaderCloseIcon}}
          icon={faTimes}
          size="2x"
          onClick={close}
        />
      </div>

      <div className="group-invite-content" style={{...styles.groupInviteContent}}>
        <div className="group-invite-message" style={{...styles.groupInviteMessage}}>
          {group_invite.inviter_display_name} has invited you to join the group "{group_invite.group_name}".
        </div>

        <div className="group-invite-description-label" style={{...styles.groupInviteDescriptionLabel}}>
          Description for {group_invite.group_name}:
        </div>
        <div className="group-invite-description" style={{...styles.groupInviteDescription}}>
          {group_invite.group_description}
        </div>
      </div>

      <div className="group-invite-buttons-container" style={{...styles.groupInviteButtonsContainer}}>
        <div
          className="group-invite-decline-button"
          style={{...styles.groupInviteDeclineButton}}
          onClick={decline}
        >
          Decline
        </div>
        <div
          className="group-invite-join-button"
          style={{...styles.groupInviteJoinButton}}
          onClick={join}
        >
          Join
        </div>
      </div>

    </div>
  );
}

export default GroupInvite;
