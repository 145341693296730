export default (query, queryTwo)=>{
  const collection = window.db.collection(query.collection);

  if (queryTwo?.operand_two){
    return collection.where(
      query.operand_one,
      query.operator,
      query.operand_two
    ).where(
      queryTwo.operand_one,
      queryTwo.operator,
      queryTwo.operand_two
    ).get();
  } else if (query.operand_one){
    return collection.where(query.operand_one, query.operator, query.operand_two).get();
  } else if (query.id){
    return collection.doc(query.id).get();
  } else {
    return collection.get();
  }

};
