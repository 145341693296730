import React, { useState, useEffect } from "react";

import { connect }    from "react-redux";

import { useParams, useNavigate } from "react-router-dom";

import Select         from "react-select";

import styles         from "../../styles/styles.js";

import dispatcher     from "../../redux/dispatcher.js";

import utility        from "../../functions/utility.js";
import groupsUtility  from "../../functions/groups.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash }    from "@fortawesome/free-solid-svg-icons";
//Will have to add extra logic for group sections / achievements to determine which group it's for

//global sections can have children, but group and personal sections that have the parents of global sections cannot be stored as children on global achievements.
//The issue is that I would have to query for the parents of each of the personal sections / achievements that re children of global sections and display them. But I currently have no way of know what collection they're from.
function CreateEditAchievementPage(props){

  const {
    name,
    stars,
    description,
    errors: errorsProp,
    groups,
    sections,
    achievements: achievementsProp,
    user_info,
    section_page,
    onChange: onChangeProp
  } = props;

  const params = useParams();
  const history = useNavigate();

  const [achievements, setAchievements] = useState({
    ["new_achievement_"]: {
      name: "",
      section: "",
      stars: "1",
      description: "",
    }
  });
  const [group_id, setGroupId] = useState();
  const [admined_groups, setAdminedGroups] = useState([]);
  const [page_title, setPageTitle] = useState();
  const [submit_text, setSubmitText] = useState();
  const [default_section_name, setDefaultSectionName] = useState();
  const [editing, setEditing] = useState();
  const [styleColor, setStyleColor] = useState({});
  const [errors, setErrors] = useState({});
  const [suggesting_user_id] = useState();

  useEffect(()=>{

    const editing_param  = window.location.href.includes("edit");
    const collection     = params.collection + "_achievements";
    const achievement_id = params.achievement_id;

    const collection_subject = params.collection;

    const achievement_collection = utility.upperCase(collection_subject);

    const admined_groups_param = (params.collection === "group") ? (groupsUtility.getAdminedGroups(groups, user_info.uid)) : ([]);

    let initial_page_title;
    let initial_submit_text;
    let initial_default_section_id;
    if (editing_param){
      initial_page_title = `Edit ${achievement_collection} Achievement`;
      initial_submit_text = "Update";

      initial_default_section_id = (params.section_id) ? (params.section_id) : (achievementsProp[collection_subject + "_achievements"][params.achievement_id].section);
    } else {
      initial_page_title = `Create ${achievement_collection} Achievement/s`;
      initial_submit_text = "Submit All";

      initial_default_section_id = params.section_id;
    }

    //Because what collection the parent is coming from is not specified.
    const global_section   = sections.global_sections[initial_default_section_id];
    const group_section    = sections.group_sections[initial_default_section_id];
    const personal_section = sections.personal_sections[initial_default_section_id];

    let initial_default_section_name;
    if (global_section){
      initial_default_section_name = global_section.name;
    } else if (group_section){
      initial_default_section_name = group_section.name;
    } else if (personal_section){
      initial_default_section_name = personal_section.name;
    }

    let initial_styleColor;
    if (collection_subject === "group"){
      initial_styleColor = styles.GroupPrimaryColor;
    } else if (collection_subject === "personal"){
      initial_styleColor = styles.PersonalPrimaryColor;
    } else if (collection_subject === "suggested"){
      initial_styleColor = styles.Grey;
    } else {
      initial_styleColor = styles.SecondaryColorMedium;
    }

    if (!props.section_page && achievement_id && achievementsProp[collection][achievement_id]){//editing single achievement

      setAchievements({
        [`new_achievement_${new Date().getTime()}`]: {
          ...props.achievements[collection][achievement_id],
          name: props.achievements[collection][achievement_id].name,
        }
      });

    } else {

      let group_id_param = window.localStorage.getItem("group_id");
      window.localStorage.removeItem("group_id");

      if (!group_id_param){
        group_id_param = admined_groups_param[0] && admined_groups_param[0].group_id;
      }

      setAchievements({
        [`new_achievement_${new Date().getTime()}`]: {
          name: "",
          section: (props.section_page) ? ("") : (params.section_id || ""),
          stars: "1",
          description: "",
        }
      });
      setGroupId(group_id_param);

    }

    setAdminedGroups(admined_groups);
    setPageTitle(initial_page_title);
    setSubmitText(initial_submit_text);
    setDefaultSectionName(initial_default_section_name);
    setEditing(editing_param);
    setStyleColor(initial_styleColor);

  }, []);

  const determineSectionCollection = (section_id)=>{

    const global_section   = sections.global_sections[section_id];
    const group_section    = sections.group_sections[section_id];
    const personal_section = sections.personal_sections[section_id];

    let section_collection;

    if (global_section){
      section_collection = "global_sections";
    } else if (group_section){
      section_collection = "group_sections";
    } else if (personal_section){
      section_collection = "personal_sections";
    }

    return section_collection;
  };

  const onChange = (e, achievement_key, section_creation_page)=>{

    if (e !== undefined){

      let state;
      if (e.target){
        state = {
          ...achievements,
          [achievement_key]: {
            ...achievements[achievement_key],
            [e.target.name]: e.target.value
          },
        };

        setGroupId((e.target.name === "group_id") ? (e.target.value) : (group_id));
      } else {
        state = {
          ...achievements,
          [achievement_key]: {
            ...achievements[achievement_key],
            section: e.value
          }
        };
      }


      if (section_creation_page){
        onChangeProp(state);
      }

      setAchievements(state);
    }
  };

  const validate = ()=>{

    let new_errors = {};

    Object.keys(achievements).forEach((key)=>{

      const achievement = achievements[key];

      if (achievement.name === ""){

        if (new_errors[key]){
          new_errors[key] = {
            ...new_errors[key],
            name: "Please fill out the name field"
          };
        } else {
          new_errors[key] = {
            name: "Please fill out the name field"
          };
        }
      }

      if (achievement.section === ""){
        if (new_errors[key]){
          new_errors[key] = {
            ...new_errors[key],
            section: "Please pick which section this achievement belongs to"
          };
        } else {
          new_errors[key] = {
            section: "Please pick which section this achievement belongs to"
          };
        }
      }

      if (achievement.description === ""){
        if (new_errors[key]){
          new_errors[key] = {
            ...new_errors[key],
            description: "Please fill out the description of the achievement"
          };
        } else {
          new_errors[key] = {
            description: "Please fill out the description of the achievement"
          };
        }
      }
    });

    return new_errors;
  };

  const onSubmit = ()=>{
    const new_errors = validate();
    if (Object.keys(new_errors).length !== 0){
      setErrors(new_errors);
      return;
    }

    const collection = params.collection;
    const user_id = (collection === "personal") ? (user_info.uid) : (null);

    let payload = {
      user_id,
    };

    if (params.achievement_id){

      let admin_edit;
      if (suggesting_user_id){
        admin_edit = (user_info.uid !== suggesting_user_id);
      }

      Object.keys(achievements).map((key)=>{
        const achievement = achievements[key];

        if (achievement){
          payload = {
            ...payload,
            ...achievement,
          };
        }

      });

      dispatcher("EDIT_ACHIEVEMENT", {
        collection,
        payload,
        achievement_id: params.achievement_id,
        admin_edit,
        callback: ()=>{
          history(-1, {replace: true});
        }
      });

    } else {

      Object.keys(achievements).map((key)=>{
        const achievement = achievements[key];

        if (achievement){
          payload = {
            user_id,
            ...achievement,
          };

          if (params.collection === "suggested"){
            payload.suggesting_user_id = user_info.uid;
            payload.up_votes = [user_info.uid];
            payload.down_votes = [];
          }

          dispatcher("CREATE_ACHIEVEMENT", {
            collection,
            payload: {
              ...payload,
              group_id: (group_id) ? (group_id) : (null)
            },
            callback: ()=>{
              history(-1, {replace: true});
            }
          });

        }

      });

    }

  };

  const getSectionOptions = (collection)=>{

    const section_collection = Object.keys(sections[`${collection}_sections`]);

    return section_collection.filter((section_id)=>{
      const section = sections[`${collection}_sections`][section_id];

      return (section.deleted !== "true" && section.deleted !== true);
    }).sort((a, b)=>{
      if (sections[`${collection}_sections`][a].name < sections[`${collection}_sections`][b].name){ return -1; }
      if (sections[`${collection}_sections`][a].name > sections[`${collection}_sections`][b].name){ return 1; }
      return 0;
    }).map((section_id)=>{

      const uppercase_section_title = utility.upperCase(sections[`${collection}_sections`][section_id].name);

      return ({
        name: "section",
        value: section_id,
        label: uppercase_section_title
      });
    });
  };

  const getDropdownOptions = ()=>{

    let options;

    let first_collection;
    if (params.collection === "suggested"){
      first_collection = "global";
    } else {
      first_collection = (params.collection.split("section")[0]) || (params.collection && params.collection.split("section")[0]);
    }

    let first_options = getSectionOptions(first_collection);
    let first_label;
    let second_options;
    let second_label;
    let third_options;
    let third_label;

    if (params.collection.includes("group")){
      first_label = "Group Sections";
      second_options = getSectionOptions("personal");
      second_label = "Personal Sections";
      third_options = getSectionOptions("global");
      third_label = "Global Sections";

      options = [
        {
          label: first_label,
          options: first_options,
        },
        {
          label: second_label,
          options: second_options,
        },
        {
          label: third_label,
          options: third_options,
        },
      ];
    } else if (params.collection.includes("personal")){
      first_label = "Personal Sections";
      second_options = getSectionOptions("group");
      second_label = "Group Sections";
      third_options = getSectionOptions("global");
      third_label = "Global Sections";

      options = [
        {
          label: first_label,
          options: first_options,
        },
        {
          label: second_label,
          options: second_options,
        },
        {
          label: third_label,
          options: third_options,
        },
      ];
    } else {
      first_label = "Global Sections";

      options = [{
        label: first_label,
        options: first_options,
      }];
    }

    return options;
  };

  const formatGroupLabel = (data)=>{

    let stylez = (data.label === "Group Sections") ? ({
      color: styles.GroupPrimaryColor
    }) : (data.label === "Personal Sections") ? ({
      color: styles.PersonalPrimaryColor
    }) : (data.label === "Global Sections") ? ({
      color: styles.SecondaryColorMedium
    }) : ({});

    return (
      <div className="create-edit-achievement-page-parent-options-label-container" style={{...styles.createEditAchievementPageParentOptionsLabelContainer, ...stylez}}>
        <span className="create-edit-achievement-page-parent-options-label-name">{data.label}</span>
        <span className="create-edit-achievement-page-parent-options-count" style={{...styles.createEditAchievementPageParentOptionsCount}}>{data.options.length}</span>
      </div>
    );
  };

  const getGroupSelection = (achievement_key)=>{

    if ((!params.collection === "group") || admined_groups.length <= 1){
      return;
    }

    return (
      <div id={`create-edit-section-page-group-container-${achievement_key}`} style={{...styles.createEditSectionPageGroupContainer}}>

        <div id={`create-edit-section-page-group-label-${achievement_key}`} style={{...styles.createEditSectionPageGroupLabel, color: styleColor}}>Choose Group: </div>

        <select
          id={`create-edit-section-page-group-select-${achievement_key}`}
          style={{...styles.createEditSectionPageGroupSelect, color: styleColor}}
          name="group_id"
          value={group_id}
          onChange={(e)=>{onChange(e, achievement_key);}}
        >
          {admined_groups.map((group, key)=>{
            return (
              <option
                key={key}
                id={`create-edit-section-page-group-${key}`}
                value={group.group_id}
              >
                {group.name}
              </option>
            );
          })}
        </select>

      </div>
    );
  };

  const removeAchievement = (key)=>{

    let new_achievements = Object.assign({}, achievements);
    delete new_achievements[key];

    setAchievements(new_achievements);
  };

  const addAchiementTemplate = ()=>{

    let new_achievements = Object.assign({}, achievements);

    const newAchievementKey = `new_achievement_${new Date().getTime()}`;

    new_achievements[newAchievementKey] = {
      name: "",
      section: (section_page) ? ("") : (params.section_id || ""),
      stars: "1",
      description: "",
      group_id
    };

    setAchievements(new_achievements);
  };

  const options = getDropdownOptions();

  const pageStyle = (section_page) ? (
    {...styles.createEditAchievementPage_SectionPage, borderTop: `1px solid ${styleColor}`}
  ) : (
    {...styles.createEditAchievementPage}
  );

  let addAchievementButtonStyle;

  if (params.collection === "group"){
    addAchievementButtonStyle = {backgroundColor: styles.GroupSecondaryColor};
  } else if (params.collection === "personal"){
    addAchievementButtonStyle = {backgroundColor: styles.PersonalSecondaryColor};
  } else {
    addAchievementButtonStyle = {backgroundColor: styles.SecondaryColorLight};
  }

  return (
    <div id="create-edit-achievement-page" style={pageStyle}>

      {(section_page) ? (null) : (
        <div id="create-edit-achievement-page-header" style={{...styles.createEditAchievementPageHeader, color: styleColor}}>
          {page_title}
        </div>
      )}

      {Object.keys(achievements).map((key, index)=>{
        const achievement = achievements[key];

        const GroupSelection = getGroupSelection(key);
        const wrappedOnChange = (e)=>{
          const section_creation_page = (onChangeProp) ? (true) : (null);//should change from reading onChange to something more direct

          onChange(e, key, section_creation_page);
        };

        let name_error;
        if (errors[key]?.name){
          name_error = errors[key]?.name;
        } else if (errorsProp){
          name_error = errorsProp[key]?.name;
        }

        let description_error;
        if (errors[key]?.name){
          description_error = errors[key]?.name;
        } else if (errorsProp){
          description_error = errorsProp[key]?.name;
        }

        if (achievement.submitted){
          return (
            <div>
              <div>Achievement Created!</div>
              <div style={{...styles.line}}></div>
            </div>
          );
        } else {

          return (
            <div
              key={key}
              id={`create-edit-achievement-page-name-container-${key}`}
              style={{...styles.createEditAchievementPageContainer}}
            >

              {(section_page || index !== 0) ? (null) : (
                <React.Fragment>

                  {GroupSelection}

                  <div id={`create-edit-achievement-page-section-container-${key}`} style={{...styles.createEditAchievementPageSectionContainer}}>

                    <div id={`create-edit-achievement-page-section-label-${key}`} style={{...styles.createEditAchievementPageSectionLabel, color: styleColor}}>
                      Achievement Section
                    </div>

                    <Select
                      id={`create-edit-achievement-page-section-select-${key}`}
                      name="section"
                      onChange={wrappedOnChange}
                      value={(achievement.section !== "") ? ({
                        label: utility.upperCase(sections[determineSectionCollection(achievement.section)][achievement.section].name),
                        name: "section",
                        value: achievement.section
                      }) : (undefined)}
                      placeholder={default_section_name || "Select..."}
                      options={options}
                      formatGroupLabel={formatGroupLabel}
                      styles={{
                        menu: (style)=>{
                          return {...style, ...styles.createEditAchievementPageSectionSelectMenu};
                        },
                        input: (style)=>{
                          return {...style, ...styles.createEditAchievementPageSectionSelectInput};
                        },
                        control: (style)=>{
                          return {...style, ...styles.createEditAchievementPageSectionSelectControl};
                        },
                        placeholder: (style)=>{
                          return {...style, ...styles.createEditAchievementPageSectionSelectPlaceholder};
                        },
                      }}
                    />
                  </div>

                  <div style={{...styles.line}}></div>

                </React.Fragment>
              )}

              <div id="create-edit-achievement-page-name-container" style={{...styles.createEditAchievementPageNameContainer}}>
                <div id={`create-edit-achievement-page-name-label-${key}`} style={{...styles.createEditAchievementPageNameLabel, color: styleColor}}>
                  Achievement Name
                </div>

                <input
                  id={`create-edit-achievement-page-name-input-${key}`}
                  name="name"
                  type="text"
                  placeholder="Achievement name"
                  value={name || achievement.name}
                  required={true}
                  onChange={wrappedOnChange}
                  style={{...styles.Input}}
                />
                {(name_error) && (
                  <div id={`create-edit-achievement-page-name-error-${key}`} style={{...styles.error}}>{name_error}</div>
                )}
              </div>

              <div id={`create-edit-achievement-page-description-container-${key}`} style={{...styles.createEditAchievementPageDescriptionContainer}}>

                <div id={`create-edit-achievement-page-description-label-${key}`} style={{...styles.createEditAchievementPageDescriptionLabel, color: styleColor}}>
                  Achievement Description
                </div>

                <textarea
                  id={`create-edit-achievement-page-description-input-${key}`}
                  name="description"
                  type="text"
                  placeholder="Achievement description"
                  value={description || achievement.description}
                  required={true}
                  onChange={wrappedOnChange}
                  style={{...styles.createEditAchievementPageDescriptionInput}}
                />
                {(description_error) && (
                  <div id={`create-edit-achievement-page-description-error-${key}`} style={{...styles.error}}>{description_error}</div>
                )}
              </div>

              <div id={`create-edit-achievement-page-stars-container-${key}`} style={{...styles.createEditAchievementPageStarsContainer}}>

                <div id={`create-edit-achievement-page-stars-label-${key}`} style={{...styles.createEditAchievementPageStarsLabel, color: styleColor}}>Points: </div>

                <select
                  id={`create-edit-achievement-page-stars-select-${key}`}
                  style={{...styles.createEditAchievementPageStarsSelect, color: styleColor}}
                  name="stars"
                  value={stars || achievement.stars}
                  onChange={wrappedOnChange}
                >
                  <option id={`create-edit-achievement-page-stars-1-${key}`} value="1">1</option>
                  <option id={`create-edit-achievement-page-stars-2-${key}`} value="2">2</option>
                  <option id={`create-edit-achievement-page-stars-3-${key}`} value="3">3</option>
                  <option id={`create-edit-achievement-page-stars-4-${key}`} value="4">4</option>
                  <option id={`create-edit-achievement-page-stars-5-${key}`} value="5">5</option>
                </select>
                {(errors.stars) && (
                  <div id={`create-edit-achievement-page-stars-error-${key}`} style={{}}>{errors.stars}</div>
                )}
              </div>

              {(section_page) ? (
                (index === 0) ? (null) : (
                  <div
                    className="achievement-admin-delete-button"
                    style={{...styles.achievementAdminDeleteButton}}
                    onClick={()=>{removeAchievement(key);}}
                  >
                    <FontAwesomeIcon
                      className="achievement-admin-delete-icon"
                      style={{...styles.achievementAdminDeleteIcon, ...{color: styles.White, margin: 0}}}
                      size="2x"
                      icon={faTrash}
                    />
                  </div>
                )
              ) : (
                <div className="create-edit-achievement-page-buttons-container" style={{...styles.createEditAchievementPageButtonsContainer}}>
                  {(index === 0) ? (null) : (
                    <div
                      className="achievement-admin-delete-button"
                      style={{...styles.achievementAdminDeleteButton}}
                      onClick={()=>{removeAchievement(key);}}
                    >
                      <FontAwesomeIcon
                        className="achievement-admin-delete-icon"
                        style={{...styles.achievementAdminDeleteIcon, ...{color: styles.White, margin: 0}}}
                        size="2x"
                        icon={faTrash}
                      />
                    </div>
                  )}
                  <div
                    id={`create-edit-achievement-page-submit-button-${key}`}
                    style={{...styles.createEditAchievementPageSubmitButton, backgroundColor: styleColor}}
                    onClick={onSubmit}
                  >
                    {submit_text}
                  </div>
                </div>
              )}

              <div style={{...styles.line}}></div>
            </div>
          );
        }
      })}

      {(editing) ? (null) : (
        <div>
          <div>Add another achievement</div>
          <div
            id="create-edit-section-page-add-achievement"
            style={{...styles.createEditSectionPageAddAchievement}}
            onClick={addAchiementTemplate}
          >
            <FontAwesomeIcon
              id="create-edit-section-page-add-achievement-icon"
              style={{...styles.createEditSectionPageAddAchievementIcon, ...addAchievementButtonStyle}}
              icon={faPlus}
            />
          </div>
        </div>
      )}

    </div>
  );
}

const mapState = (state)=>{
  return {
    user_info: state.sessionReducer.user_info,
    achievements: state.achievementReducer,
    sections: state.sectionReducer,
    groups: state.groupReducer.groups
  };
};

export default connect(mapState)(CreateEditAchievementPage);
