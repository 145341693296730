import React, { useState } from "react";
import { connect }    from "react-redux";

import { useNavigate } from "react-router-dom";

import dispatcher     from "../../redux/dispatcher.js";

import styles         from "../../styles/styles.js";

function SupportPage(props){

  const {
    user_info,
  } = props;

  const [type, setType] = useState("");
  const [notes, setNotes] = useState("");
  const [errors, setErrors] = useState({});

  const history = useNavigate();

  const validateReport = ()=>{

    let new_errors = {};

    if (notes === ""){
      new_errors.notes = "Please describe the issue.";
    }

    if (type === ""){
      new_errors.type = "Please select the type of issue.";
    }

    return new_errors;
  };

  const sendReport = ()=>{
    const new_errors = validateReport();

    if (Object.keys(new_errors).length !== 0){
      setErrors(new_errors);
      return;
    }

    let payload = {
      user_id: (user_info) ? (user_info.uid) : (null),
      notes,
      type
    };

    dispatcher("SUBMIT_REPORT", {
      payload
    });

    history("/");
  };

  const onChangeType = (e)=>{
    if (e !== undefined){
      setType(e.target.value);
    }
  };

  const onChangeNotes = (e)=>{
    if (e !== undefined){
      setNotes(e.target.value);
    }
  };

  return (
    <div id="support-page" style={{...styles.CenterContent, ...styles.supportPage}}>
      <div id="support-page-container" style={{...styles.CenterContent, ...styles.supportPageContainer}}>

        <div id="support-page-header" style={{...styles.H2, ...styles.Bold, ...styles.supportPageHeader}}>Support Page</div>

        <div id="support-page-content" style={{...styles.detailsStyle, ...styles.supportPageContent}}>

          <div id="support-page-issue-type-container" style={{...styles.supportPageIssueTypeContainer}}>

            <div id="support-page-issue-type-label" style={{...styles.H3, ...styles.supportPageIssueTypeLabel}}>Issue type</div>

            <select id="support-page-issue-type-select" style={{...styles.BoxShadow, ...styles.supportPageIssueTypeSelect}} name="type" value={type} onChange={onChangeType}>
              <option id="support-page-issue-type-option-placeholder" value="" disabled hidden>Select</option>
              <option id="support-page-issue-type-option-bug" value="bug">Bug</option>
              <option id="support-page-issue-type-option-incorrect-data" value="incorrect_data">Incorrect Data</option>
              <option id="support-page-issue-type-option-inappropriate-content" value="inappropriate_content">Inappropriate Content</option>
              <option id="support-page-issue-type-option-suggestion" value="suggestion">Suggestion</option>
              <option id="support-page-issue-type-option-other" value="other">Other</option>
            </select>
          </div>
          {(errors.type) && (
            <div id="support-page-issue-type-error" style={{}}>{errors.type}</div>
          )}

          <textarea
            id="support-page-notes"
            name="notes"
            value={notes}
            style={{...styles.BoxShadow, ...styles.supportPageNotes}}
            placeholder="Describe the issue."
            onChange={onChangeNotes}
          />
          {(errors.notes) && (
            <div id="support-page-notes-error" style={{}}>{errors.notes}</div>
          )}

          <div id="support-page-send-button-container" style={{...styles.supportPageSendButtonContainer}}>
            <div id="support-page-send-button" style={{...styles.Button, ...styles.supportPageSendButton}} onClick={sendReport}>Send</div>
          </div>

        </div>

      </div>

    </div>
  );
}


const mapState = (state)=>{

  return {
    user_info: state.sessionReducer.user_info
  };
};

export default connect(mapState)(SupportPage);
