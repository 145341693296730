import React, { useState } from "react";

import AchievementTopContainer    from "./AchievementTopContainer.tsx";
import AchievementMidContainer    from "./AchievementMidContainer.js";
import AchievementBottomContainer from "./AchievementBottomContainer.js";

import styles                     from "../../styles/styles.js";

import { TweenMax }               from "gsap/all";

import { FontAwesomeIcon }        from "@fortawesome/react-fontawesome";
import { faChevronDown }          from "@fortawesome/free-solid-svg-icons";

function Achievement(props){

  const {
    achievement,
    completed,
    favorited,
    tracking,
    completed_date,
  } = props;

  const [more_info_open, setMoreInfoOpen] = useState(false);

  const toggleMoreInfo = ()=>{

    if (more_info_open){
      TweenMax.to(document.getElementById(achievement.achievement_id + "-bottom-container"), 1, {maxHeight: 0});
      TweenMax.to(document.getElementById(achievement.achievement_id + "-arrow"), 0.25, {rotation: 0});
    } else {
      TweenMax.to(document.getElementById(achievement.achievement_id + "-bottom-container"), 3, {maxHeight: 1000});
      TweenMax.to(document.getElementById(achievement.achievement_id + "-arrow"), 0.25, {rotation: 180});
    }

    setMoreInfoOpen(!more_info_open);
  };

  const getStyles = ()=>{

    let achievementTemplateStyle = (completed) ? ({...styles.achievementTemplateCompleted}) : ({...styles.achievementTemplate});
    let achievementButtonStyle;
    let achievementTextStyle;
    let achievementNameStyle;
    let achievementDescriptionStyle;
    let achievementFontColor;
    let achievementOptionTextStyle;

    let primaryColor;
    let secondaryColor;

    if (achievement.user_id){
      primaryColor = styles.PersonalPrimaryColor;
      secondaryColor = styles.PersonalSecondaryColor;

      achievementTemplateStyle = {
        ...achievementTemplateStyle,
        ...styles.personalAchievementTemplate,
      };
      achievementButtonStyle = {
        ...styles.achievementButtonIcons,
        backgroundColor: secondaryColor,
      };
      achievementTextStyle = {
        ...styles.achievementButtonText,
      };
      achievementNameStyle = {
        ...styles.achievementName,
      };
      achievementDescriptionStyle = {
        ...styles.achievementDescription,
      };
      achievementFontColor = {
        color: styles.White
      };
      achievementOptionTextStyle = {
        backgroundColor: secondaryColor,
        color: styles.Black
      };

    } else if (achievement.group_id){

      primaryColor = styles.GroupPrimaryColor;
      secondaryColor = styles.GroupSecondaryColor;

      achievementTemplateStyle = {
        ...achievementTemplateStyle,
        ...styles.groupAchievementTemplate,
      };
      achievementButtonStyle = {
        ...styles.achievementButtonIcons,
        backgroundColor: secondaryColor,
        color: "black"
      };
      achievementTextStyle = {
        ...styles.achievementButtonText,
      };
      achievementNameStyle = {
        ...styles.achievementName,
      };
      achievementDescriptionStyle = {
        ...styles.achievementDescription,
      };
      achievementFontColor = {
        color: styles.White
      };
      achievementOptionTextStyle = {
        backgroundColor: secondaryColor,
        color: styles.Black
      };
    } else if (achievement.suggesting_user_id){

      primaryColor = styles.Grey;
      secondaryColor = styles.White;

      achievementTemplateStyle = {
        ...achievementTemplateStyle,
        ...styles.suggestedAchievementTemplate,
        boxSizing: "border-box",
        marginRight: 0,
        marginLeft: 0,
        maxWidth: (window.screen.width) ? (8 * 37) : (8 * 42)
      };
      achievementButtonStyle = {
        ...styles.achievementButtonIcons,
        color: "black",
        backgroundColor: styles.White
      };
      achievementTextStyle = {
        ...styles.achievementButtonText,
      };
      achievementNameStyle = {
        ...styles.achievementName,
      };
      achievementDescriptionStyle = {
        ...styles.achievementDescription,
      };
      achievementFontColor = {color: styles.White};
      achievementOptionTextStyle = {
        backgroundColor: secondaryColor,
        color: styles.Black
      };

    } else {

      primaryColor = styles.SecondaryColorMedium;
      secondaryColor = styles.SecondaryColorLight;

      achievementTemplateStyle = {
        ...achievementTemplateStyle,
      };
      achievementButtonStyle = {
        ...styles.achievementButtonIcons,
        color: "black"
      };
      achievementTextStyle = {
        ...styles.achievementButtonText,
      };
      achievementNameStyle = {
        ...styles.achievementName,
      };
      achievementDescriptionStyle = {
        ...styles.achievementDescription,
      };
      achievementFontColor = {color: styles.White};
      achievementOptionTextStyle = {
        backgroundColor: secondaryColor,
        color: styles.Black
      };

    }

    const optionStyle = {
      ...styles.wideButton,
      ...achievementOptionTextStyle,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      position: "relative"
    };

    return {
      optionStyle,
      achievementTemplateStyle,
      achievementButtonStyle,
      achievementTextStyle,
      achievementNameStyle,
      achievementDescriptionStyle,
      achievementFontColor,
      achievementOptionTextStyle,

      primaryColor,
      secondaryColor,
    };
  };

  const achievementStyles = getStyles();

  const {
    optionStyle,
    achievementTemplateStyle,
    achievementButtonStyle,
    achievementNameStyle,
    achievementDescriptionStyle,
    achievementFontColor,
    achievementOptionTextStyle,
  } = achievementStyles;

  return (
    <div
      className="achievement-container"
      id={achievement.achievement_id}
      style={{...achievementTemplateStyle, ...achievementFontColor}}
    >

      <div className="achievement-main-content" style={{...styles.achievementMainContent}}>

        <AchievementTopContainer
          achievement={achievement}
          achievementNameStyle={achievementNameStyle}
          achievementButtonStyle={achievementButtonStyle}
          completed={completed}
        />

        <AchievementMidContainer
          achievement={achievement}
          completed={completed}
          favorited={favorited}
          tracking={tracking}
          completed_date={completed_date}
          optionStyle={optionStyle}
          achievementOptionTextStyle={achievementOptionTextStyle}
          achievementDescriptionStyle={achievementDescriptionStyle}
        />

      </div>

      <AchievementBottomContainer
        achievement={achievement}
        achievementStyles={achievementStyles}
      />

      <div
        id={`${achievement.achievement_id}-arrow`}
        className="achievement-more-container"
        style={{cursor: "pointer"}}
        onClick={toggleMoreInfo}
      >
        <FontAwesomeIcon
          className="achievement-more-icon"
          size="2x"
          icon={faChevronDown}
        />
      </div>

    </div>
  );
}

export default Achievement;
