import React, { useEffect }       from "react";
import { connect }                from "react-redux";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import SectionHeaderOptions       from "../section/SectionHeaderOptions.js";
import SectionCard                from "../section/SectionCard.js";
import Achievement                from "../achievement/Achievement.js";
import AchievementVotingComponent from "../achievement/AchievementVotingComponent.tsx";

import dispatcher                 from "../../redux/dispatcher.js";

import utility                    from "../../functions/utility.js";

import styles                     from "../../styles/styles.js";

import { FontAwesomeIcon }        from "@fortawesome/react-fontawesome";
import { faShare }                from "@fortawesome/free-solid-svg-icons";

//audit SECTION_CHECK and GET_SECTION_AND_CHILDREN

function SectionPage(props){

  const {
    user_info,
    sectionsReducer,
    achievementReducer,
  } = props;

  const history = useNavigate();
  const location = useLocation();
  const params = useParams();
  const {
    section: section_id,
    collection
  } = params;

  let achievement_count = 0;
  let completed_achievement_count = 0;

  useEffect(()=>{
    if (props.sectionsReducer[collection + "_sections"][section_id]){//checks if any updates have been made to section + achievements, if so, updates them locally
      dispatcher("SECTION_CHECK", {
        collection,
        section_id,
        section_modified: props.sectionsReducer[collection + "_sections"][section_id].modified,
      });
    } else {//attempts to get
      dispatcher("GET_SECTION_AND_CHILDREN", {
        collection,
        section_id
      });
    }

    if (location.hash !== ""){
      setTimeout(()=>{
        document.getElementById(location.hash.split("#")[1]).scrollIntoView({behavior: "smooth", block: "center"});
      }, 1000);
    }

  }, []);

  useEffect(()=>{
    if (sectionsReducer[collection + "_sections"][section_id]){//checks if any updates have been made to section + achievements, if so, updates them locally
      dispatcher("SECTION_CHECK", {
        collection,
        section_id,
        section_modified: sectionsReducer[collection + "_sections"][section_id].modified,
      });
    } else {//attempts to get
      dispatcher("GET_SECTION_AND_CHILDREN", {
        collection,
        section_id
      });
    }
  }, [params.section]);

  const renderSections = ()=>{

    const collection = params.collection;

    const children = sectionsReducer[collection + "_sections"][params.section] && sectionsReducer[collection + "_sections"][params.section].children;

    let sections = [];

    const personal_sections = Object.keys(sectionsReducer.personal_sections).filter((section_id)=>{
      const section = sectionsReducer.personal_sections[section_id];

      return ((section.parent === params.section) && (section.deleted !== "true"));
    }).sort((a, b)=>{
      const textA = sectionsReducer.personal_sections[a].name.toUpperCase();
      const textB = sectionsReducer.personal_sections[b].name.toUpperCase();

      return (textA < textB) ? (-1) : ((textA > textB) ? (1) : (0));
    }).map((section_id, key)=>{
      const section = sectionsReducer.personal_sections[section_id];

      return (
        <SectionCard key={`personal_section_${key}`} section={section}/>
      );
    });

    const group_sections = Object.keys(sectionsReducer.group_sections).filter((section_id)=>{
      const section = sectionsReducer.group_sections[section_id];

      return ((section.parent === params.section) && (section.deleted !== "true"));
    }).sort((a, b)=>{
      const textA = sectionsReducer.group_sections[a].name.toUpperCase();
      const textB = sectionsReducer.group_sections[b].name.toUpperCase();

      return (textA < textB) ? (-1) : ((textA > textB) ? (1) : (0));
    }).map((section_id, key)=>{
      const section = sectionsReducer.group_sections[section_id];
      if (section.parent === params.section){
        return (
          <SectionCard key={`group_section_${key}`} section={section}/>
        );
      }
    });

    if (children){
      children.filter((section_id)=>{
        const section = sectionsReducer.global_sections[section_id];

        return (section && ((section.parent === params.section) && (section.deleted !== "true")));
      }).sort((a, b)=>{
        const textA = sectionsReducer.global_sections[a].name.toUpperCase();
        const textB = sectionsReducer.global_sections[b].name.toUpperCase();

        return (textA < textB) ? (-1) : ((textA > textB) ? (1) : (0));
      }).forEach((section_id, key)=>{

        const section = sectionsReducer.global_sections[section_id];

        sections.push(
          <SectionCard key={`global_section_${key}`} section={section}/>
        );
      });
    }

    const mobile = (window.screen.width <= 800);

    const style = (mobile) ? ({
      padding: 16,
      width: 336
    }) : ({
      padding: 16,
      margin: 16,
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderRadius: 8,
      width: 336
    });

    return (
      <div>

        {(personal_sections?.length) ? (
          <div style={style}>
            <div style={{textAlign: "center"}}>Personal Sections</div>
            {personal_sections}
          </div>
        ) : (null)}

        {(group_sections?.length) ? (
          <div style={style}>
            <div style={{textAlign: "center"}}>Group Sections</div>
            {group_sections}
          </div>
        ) : (null)}

        {(collection === "global") ? (
          <div style={style}>
            <div style={{textAlign: "center"}}>Global Sections</div>
            {(sections.length) ? (sections) : (
              <div id="sections-achievements-page-no-sections-message" style={{...styles.sectionsAchievementsPageNoSectionsMessage}}>
                There are no sub-sections for this. If you think there should be some, you can suggest sub-sections for this section in our workshop
                <span
                  id="sections-achievements-page-workshop-link-section"
                  style={{...styles.sectionsAchievementsPageWorkshopLinkSection}}
                  onClick={()=>{history(`/achievement-workshop/create/section/suggested/${params.section}`);}}
                >
                  here
                </span>
              </div>
            )}
          </div>
        ) : (null)}

      </div>
    );
  };

  const renderAchievements = ()=>{

    const collection = params.collection;

    const user_achievements = achievementReducer.user_achievements;

    let completed_achievement_count = 0;

    const section_achievements = sectionsReducer[collection + "_sections"][params.section] && sectionsReducer[collection + "_sections"][params.section].achievements;

    const personal_achievements = Object.keys(achievementReducer.personal_achievements).filter((achievement_id)=>{
      const achievement = achievementReducer.personal_achievements[achievement_id];

      return ((achievement.section === params.section) && (achievement.deleted !== "true"));
    }).sort((a, b)=>{
      const textA = achievementReducer.personal_achievements[a].name.toUpperCase();
      const textB = achievementReducer.personal_achievements[b].name.toUpperCase();

      return (textA < textB) ? (-1) : ((textA > textB) ? (1) : (0));
    }).map((achievement_id, key)=>{
      const achievement = achievementReducer.personal_achievements[achievement_id];

      const user_achievement = user_achievements[achievement_id];

      const completed      = user_achievement?.completed;
      const favorited      = user_achievement?.favorited;
      const tracking       = user_achievement?.tracking;
      const completed_date = user_achievement?.created;

      completed_achievement_count = (completed) ? (completed_achievement_count + 1) : (completed_achievement_count);

      return (
        <Achievement
          key={`personal_achievement-${key}`}
          achievement={achievement}
          completed={completed}
          favorited={favorited}
          tracking={tracking}
          completed_date={completed_date}
          user_id={user_info.uid}
        />
      );

    });

    const group_achievements = Object.keys(achievementReducer.group_achievements).filter((achievement_id)=>{
      const achievement = achievementReducer.group_achievements[achievement_id];

      return ((achievement.section === params.section) && (achievement.deleted !== "true"));
    }).sort((a, b)=>{
      const textA = achievementReducer.group_achievements[a].name.toUpperCase();
      const textB = achievementReducer.group_achievements[b].name.toUpperCase();

      return (textA < textB) ? (-1) : ((textA > textB) ? (1) : (0));
    }).map((achievement_id, key)=>{
      const achievement = achievementReducer.group_achievements[achievement_id];

      const user_achievement = user_achievements[achievement_id];

      const completed      = user_achievement?.completed;
      const favorited      = user_achievement?.favorited;
      const tracking       = user_achievement?.tracking;
      const completed_date = user_achievement?.created;

      completed_achievement_count = (completed) ? (completed_achievement_count + 1) : (completed_achievement_count);

      return (
        <Achievement
          key={`group_achievement-${key}`}
          achievement={achievement}
          completed={completed}
          favorited={favorited}
          tracking={tracking}
          completed_date={completed_date}
          user_id={user_info.uid}
        />
      );

    });

    let suggested_achievements;

    const filtered_suggested_achievements = Object.keys(achievementReducer.suggested_achievements).filter((achievement_id)=>{
      const achievement = achievementReducer.suggested_achievements[achievement_id];

      return ((achievement.section === params.section) && (achievement.deleted !== "true"));
    });

    if (collection === "suggested"){
      suggested_achievements = (
        <div id="suggested-achievements-container" style={{...styles.suggestedAchievementsContainer}}>
          {filtered_suggested_achievements.map((achievement_id, key)=>{
            const achievement = achievementReducer.suggested_achievements[achievement_id];

            let vote_number = 0;
            if (achievement.down_votes){
              vote_number = vote_number - achievement.down_votes.length;
            }
            if (achievement.up_votes){
              vote_number = vote_number + achievement.up_votes.length;
            }

            return (
              <div
                key={`suggested_achievement-${key}`}
                id={`suggested-achievement-container-${achievement_id}`}
                style={{...styles.suggestedAchievementContainer}}
              >
                <Achievement
                  achievement={achievement}
                  user_id={user_info.uid}
                />

                <AchievementVotingComponent
                  achievement={achievement}
                  achievement_id={achievement_id}
                  user_info={user_info}
                  vote_number={vote_number}
                />
              </div>
            );

          })}
          {(filtered_suggested_achievements.length) ? (null) : (
            <div>There are currently no achievements for this section.</div>
          )}
        </div>
      );
    }

    let achievements = [];
    if (section_achievements){
      section_achievements.filter((achievement_id)=>{
        const achievement = achievementReducer.global_achievements[achievement_id];

        return (achievement && ((achievement.section === params.section) && (achievement.deleted !== "true")));
      }).sort((a, b)=>{
        const textA = achievementReducer.global_achievements[a].name.toUpperCase();
        const textB = achievementReducer.global_achievements[b].name.toUpperCase();

        return (textA < textB) ? (-1) : ((textA > textB) ? (1) : (0));
      }).forEach((achievement_id, key)=>{
        const achievement = achievementReducer.global_achievements[achievement_id];

        const user_achievement = user_achievements[achievement_id];

        const completed      = user_achievement?.completed;
        const favorited      = user_achievement?.favorited;
        const tracking       = user_achievement?.tracking;
        const completed_date = user_achievement?.created;

        completed_achievement_count = (completed) ? (completed_achievement_count + 1) : (completed_achievement_count);

        achievements.push(
          <Achievement
            key={`global_achievement-${key}`}
            achievement={achievement}
            completed={completed}
            favorited={favorited}
            tracking={tracking}
            completed_date={completed_date}
            user_id={user_info.uid}
          />
        );

      });
    }

    achievement_count = (personal_achievements.length + group_achievements.length + achievements.length);

    return (
      <div style={{...{width: "100%"}}}>

        {(personal_achievements?.length) ? (
          <div id="personal-achievements-container" style={{...styles.personalAchievementsContainer}}>
            <div id="personal-achievements-label" style={{...styles.personalAchievementsLabel}}>Personal Achievements</div>
            {personal_achievements}
          </div>
        ) : (null)}

        {(group_achievements?.length) ? (
          <div id="group-achievements-container" style={{...styles.groupAchievementsContainer}}>
            <div id="group-achievements-label" style={{...styles.groupAchievementsLabel}}>Group Achievements</div>
            {group_achievements}
          </div>
        ) : (null)}

        {(collection === "global") ? (
          <div id="global-achievements-container" style={{...styles.globalAchievementsContainer}}>
            <div id="global-achievements-label" style={{...styles.globalAchievementsLabel}}>Global Achievements</div>
            {(achievements.length) ? (achievements) : (
              <div id="sections-achievements-page-no-sections-message" style={{...styles.sectionsAchievementsPageNoSectionsMessage}}>
                There are no achievements for this section. If you think there should be some, you can suggest achievements for this section in our workshop
                <span
                  id="sections-achievements-page-workshop-link-achievement"
                  style={{...styles.sectionsAchievementsPageWorkshopLinkAchievement}}
                  onClick={()=>{history(`/achievement-workshop/create/achievement/suggested/${params.section}`);}}
                >
                  here
                </span>
              </div>
            )}
          </div>
        ) : (null)}

        {suggested_achievements}

      </div>
    );
  };

  const Sections = renderSections();
  const Achievements = renderAchievements();

  let section = {};
  let section_title;
  if (sectionsReducer[collection + "_sections"][params.section]){
    section = sectionsReducer[collection + "_sections"][params.section];
    section_title = utility.upperCase(section.name);
  } else {
    section_title = "Loading...";
  }

  return (
    <div id="section-page" style={{...styles.sectionPage}}>
      <div id="section-page-header-container" style={{...styles.sectionPageHeaderContainer}}>
        <div id="section-page-header" style={{...styles.sectionPageHeader}}>{section_title}</div>
      </div>

      {(collection === "suggested") ? (
        <div
          className="section-parent-section-label"
          style={{...styles.sectionParentSectionLabel}}
          onClick={()=>{
            history(`/achievements/global/${section.parent}`);
          }}
        >
          Parent Section: {utility.upperCase(sectionsReducer.global_sections[section.parent].name)}
          <FontAwesomeIcon style={{...styles.suggestedParentLink}} icon={faShare}/>
        </div>
      ) : (
        <div id="section-page-progress-bar-container" style={{...styles.sectionPageProgressBarContainer}}>
          <div id="section-page-progress-bar-content" style={{...styles.sectionPageProgressBarContent}}>
            <div id="section-page-progress-bar-earned" className="styled" style={{...styles.sectionPageProgressBarEarned}}>
              <span id="section-page-progress-bar-earned-text" style={{...styles.sectionPageProgressBarEarnedText}}>Earned:</span>
              <progress
                id="section-page-progress-bar"
                value={completed_achievement_count}
                max={achievement_count}>
              </progress>
              <div id="section-page-progress-bar-earned-fraction" style={{...styles.sectionPageProgressBarEarnedFraction}}>
                {completed_achievement_count} / {achievement_count}
              </div>
            </div>
          </div>
        </div>
      )}

      <div id="section-page-content" style={{...styles.sectionPageContent}}>

        <SectionHeaderOptions
          collection={collection}
        />

        <div id="section-page-sections-and-achievements-container" style={{...styles.sectionPageSectionsAndAchievementsContainer}}>
          {Sections}
          {Achievements}
        </div>

      </div>

    </div>
  );
}

const mapState = function(state){
  return {
    sectionsReducer: state.sectionReducer,
    achievementReducer: state.achievementReducer,
    user_info: state.sessionReducer.user_info,
  };
};

export default connect(mapState)(SectionPage);
