import React, { useState } from "react";

import { useNavigate }     from "react-router-dom";

import { connect }         from "react-redux";

import dispatcher          from "../../redux/dispatcher.js";

import groupUtility        from "../../functions/groups.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen }           from "@fortawesome/free-solid-svg-icons";

import styles              from "../../styles/styles.js";

function GroupSettings(props){

  const {
    groups,
    group_id,
    user_info,
  } = props;

  const [errors, setErrors] = useState({});
  const [group_name, setGroupName] = useState(groups[props.group_id].name);
  const [group_description, setGroupDescription] = useState(groups[props.group_id].description);
  const [showGroupNameInput, setShowGroupNameInput] = useState(false);
  const [showGroupDescriptionInput, setShowGroupDescriptionInput] = useState(false);

  const history = useNavigate();

  const leaveGroup = (admin)=>{

    const members = groups[group_id].members;

    let member_id;
    Object.keys(members).some((id)=>{
      if (members[id].user_id === user_info.uid){
        member_id = id;
        return true;
      } else {
        return false;
      }
    });

    dispatcher("ADD_MODAL", {
      name: "CONFIRMATION",
      modalProps: {
        header: "Are you sure?",
        message: `Any progress that you've made on the achievements will be kept in-tact, but you will have to rejoin the group to have access to their achievements again. ${(admin) ? ("You will also lose your admin status and will need to be re-appointed by an existing admin.") : ("")}`,
        okButton: true,
        cancelButton: true,
        callback: ()=>{

          dispatcher("LEAVE_GROUP", {
            user_id: user_info.uid,
            member_id,
            group_id,
            admin,
            onSuccess: ()=>{

              dispatcher("ADD_MODAL", {
                name: "CONFIRMATION",
                modalProps: {
                  header: "Success",
                  message: "You have left the group.",
                  okButton: true,
                  callback: ()=>{
                    history("/");
                  }
                }
              });

            }
          });

        }
      }
    });

  };

  const onChangeGroupDescription = (e)=>{
    if (e !== undefined){
      setGroupDescription(e.target.value);
    }
  };

  const onChangeGroupName = (e)=>{
    if (e !== undefined){
      setGroupName(e.target.value);
    }
  };

  const validate = ()=>{

    let new_errors = {};

    if (group_name === ""){
      // if (Object.keys(errors).length === 0){
      //   this.name_ref.current.focus();
      // }
      new_errors.group_name = "Field cannot be blank";
    }

    if (group_description === ""){
      // if (Object.keys(errors).length === 0){
      //   this.name_ref.current.focus();
      // }
      new_errors.group_description = "Field cannot be blank";
    }

    return new_errors;
  };

  const onSubmit = ()=>{
    const new_errors = validate();
    if (Object.keys(new_errors).length !== 0){
      setErrors(new_errors);
      return;
    }

    dispatcher("UPDATE_GROUP", {
      group_id,
      payload: {
        name: group_name,
        description: group_description,
      }
    });

    setShowGroupNameInput(false);
    setShowGroupDescriptionInput(false);

  };

  const showGroupNameInputOnClick = ()=>{
    setShowGroupNameInput(true);
  };

  const showGroupDescriptionInputOnClick = ()=>{
    setShowGroupDescriptionInput(true);
  };

  const leaveGroupAsAdmin = ()=>{
    leaveGroup("ADMIN");
  };

  const deleteGroup = ()=>{

    dispatcher("ADD_MODAL", {
      name: "CONFIRMATION",
      modalProps: {
        header: "Are you sure?",
        message: "This is a serious action and will permanently delete this entire group for all users along with any achievements created. This is an irreversible action.",
        okButton: true,
        cancelButton: true,
        callback: ()=>{
          history("/");
          dispatcher("DELETE_GROUP", {
            user_id: user_info.uid,
            group_id,
          });
        }
      }
    });
  };

  const getAdminBlock = ()=>{

    const group = groups[group_id];

    const admin_is_creator = group.creator === user_info.uid;

    return (
      <div id="group-settings-content" style={{...styles.groupSettingsContent}}>

        <div id="group-settings-info-label" style={{...styles.groupSettingsInfoLabel}}>Info</div>

        <div id="group-settings-info-content" style={{...styles.groupSettingsInfoContent}}>

          <div
            id="group-settings-name-label-container"
            style={{...styles.groupSettingsNameLabelcontainer}}
            onClick={showGroupNameInputOnClick}
          >
            <div id="group-settings-name-label-content" style={{...styles.groupSettingsNameLabelContent}}>

              <div id="group-settings-name-label" style={{...styles.groupSettingsNameLabel}}>
                Group Name
              </div>

              <FontAwesomeIcon
                id="group-settings-name-edit-icon"
                style={{...styles.groupSettingsNameEditIcon}}
                icon={faPen}
              />

            </div>
          </div>

          {(showGroupNameInput) ? (
            <React.Fragment>
              <input
                id="group-settings-name-input"
                style={{...styles.groupSettingsNameInput}}
                name="group_name"
                type="text"
                placeholder="Group name"
                value={group_name}
                required={true}
                onChange={onChangeGroupName}
              />
              {(errors.group_name) && (
                <div id="group-settings-name-error" style={{...styles.groupsettingsNameError}}>{errors.group_name}</div>
              )}
            </React.Fragment>
          ) : (
            <div
              id="group-settings-name-container"
              style={{...styles.groupSettingsNameContainer}}
              onClick={showGroupNameInputOnClick}
            >
              <div className="line" style={{...styles.line, borderBottom: 0}}></div>
              <div id="group-settings-name" style={{...styles.groupSettingsName}}>{group.name}</div>
              <div className="line" style={{...styles.line}}></div>
            </div>
          )}

          <div
            id="group-settings-description-label-container"
            style={{...styles.groupSettingsDescriptionLabelContainer}}
            onClick={showGroupDescriptionInputOnClick}
          >
            <div id="group-settings-description-label-content" style={{...styles.groupSettingsDescriptionLabelContent}}>

              <div id="group-settings-description-label" style={{...styles.groupSettingsDescriptionLabel}}>
                Group Description
              </div>

              <FontAwesomeIcon
                id="group-settings-description-edit-icon"
                style={{...styles.groupSettingsDescriptionEditIcon}}
                icon={faPen}
              />

            </div>
          </div>

          {(showGroupDescriptionInput) ? (
            <React.Fragment>
              <textarea
                id="group-settings-description-input"
                style={{...styles.groupSettingsDescriptionInput}}
                name="group_description"
                type="text"
                placeholder="Group description"
                value={group_description}
                required={true}
                onChange={onChangeGroupDescription}
              />
              {(errors.group_description) && (
                <div id="group-settings-description-error" style={{...styles.groupSettingsDescriptionError}}>{errors.group_description}</div>
              )}
            </React.Fragment>
          ) : (
            <div
              id="group-settings-description-container"
              style={{...styles.groupSettingsDescriptionContainer}}
              onClick={showGroupDescriptionInputOnClick}
            >
              <div className="line" style={{...styles.line, borderBottom: 0}}></div>
              <div id="group-settings-description" style={{...styles.groupSettingsDescription}}>{group.description}</div>
              <div className="line" style={{...styles.line}}></div>
            </div>
          )}

          {(group_name !== groups[group_id].name || group_description !== groups[group_id].description) ? (
            <div id="group-settings-save-button-container" style={{...styles.groupSettingsSaveButtonContainer}}>
              <div
                id="group-settings-save-button"
                style={{...styles.groupSettingsSaveButton}}
                onClick={onSubmit}
              >
                Save
              </div>
            </div>
          ) : (null)}

        </div>

        <div id="group-settings-label" style={{...styles.groupSettingsLabel}}>Settings</div>

        <div id="group-settings-privacy-container">

          <div id="group-settings-privacy-label" style={{...styles.groupSettingsPrivacyLabel}}>Privacy Settings</div>

          <div id="group-settings-privacy-content" style={{...styles.groupSettingsPrivacyContent}}>

            <select
              id="group-settings-privacy-select"
              style={{...styles.groupSettingsPrivacySelect}}
              value={group.settings.visibility}
              onChange={(e)=>{
                updateGroup({
                  visibility: e.target.value
                });
              }}
            >
              <option id="group-settings-privacy-public" value="public">
                Public
              </option>
              <option id="group-settings-privacy-private" value="private">
                Private
              </option>
            </select>
          </div>

        </div>

        <div id="group-settings-invite-type-container" style={{...styles.groupSettingsInviteTypeContainer}}>

          <div id="group-settings-invite-type-label" style={{...styles.groupSettingsInviteTypeLabel}}>Invite Type</div>

          <div id="group-settings-invite-type-content" style={{...styles.groupSettingsInviteTypeContent}}>

            <select
              id="group-settings-invite-type-select"
              style={{...styles.groupSettingsInviteTypeSelect}}
              value={group.settings.joining_type}
              onChange={(e)=>{
                updateGroup({
                  joining_type: e.target.value
                });
              }}
            >
              <option id="group-settings-invite-type-open" value="open_join">
                Open Join
              </option>
              <option id="group-settings-invite-type-request" value="request_to_join">
                Request to Join
              </option>
              <option id="group-settings-invite-type-invite" value="invite_only">
                Invite to Join
              </option>
            </select>

          </div>
        </div>

        <div className="line" style={{...styles.line}}></div>

        {(admin_is_creator) ? (
          <div
            id="group-settings-delete-button"
            style={{...styles.groupSettingsDeleteButton}}
            onClick={deleteGroup}
          >
            Delete Group
          </div>
        ) : (
          <div
            id="group-settings-leave-button"
            style={{...styles.groupSettingsLeaveButton}}
            onClick={leaveGroupAsAdmin}
          >
            Leave Group
          </div>
        )}

      </div>
    );

  };

  const getMemberBlock = ()=>{

    const group = groups[group_id];

    const admin = group.admins.includes(user_info.uid);
    const group_member_status = groupUtility.groupMemberStatus(user_info, admin, group_id);

    return (
      <div id="group-settings-member-view-container">

        <div id="group-settings-member-view-header" style={{...styles.groupSettingsMemberViewHeader}}>Info</div>

        <div id="group-settings-member-view-content" style={{...styles.groupSettingsMemberViewContent}}>

          <div id="group-settings-member-view-group-name-label" style={{...styles.groupSettingsMemberViewGroupNameLabel}}>Group Name</div>

          <div className="line" style={{...styles.line}}></div>
          <div id="group-settings-member-view-group-name" style={{...styles.groupSettingsMemberViewGroupName}}>
            {group.name}
          </div>
          <div className="line" style={{...styles.line}}></div>

          <div id="group-settings-member-view-group-description-label" style={{...styles.groupSettingsMemberViewGroupDescriptionLabel}}>
            Group Description
          </div>

          <div className="line" style={{...styles.line}}></div>
          <div id="group-settings-member-view-group-description">
            {group.description}
          </div>
          <div className="line" style={{...styles.line}}></div>

        </div>

        {(group_member_status !== "non-member") ? (
          <div id="group-settings-member-view-leave" style={{...styles.groupSettingsMemberViewLeave}} onClick={leaveGroup}>Leave Group</div>
        ) : (null)}
      </div>
    );
  };

  const updateGroup = (data_to_update)=>{

    const group = groups[group_id];

    const payload = {
      settings: {
        ...group.settings,
        ...data_to_update
      }
    };
    //Check and warn that Private Group cannot be request to join / open join
    dispatcher("UPDATE_GROUP", {
      group_id,
      payload
    });

  };

  const admin = groups[group_id].admins.includes(user_info.uid);

  return (
    <div id="group-settings" style={{...styles.groupSettings}}>
      {(admin) ? (
        getAdminBlock()
      ) : (
        getMemberBlock()
      )}
    </div>
  );
}

const mapState = (state)=>{

  const hackToUpdate = Math.random();

  return {
    user_info: state.sessionReducer.user_info,
    groups: state.groupReducer.groups,
    hackToUpdate
  };
};

export default connect(mapState)(GroupSettings);
