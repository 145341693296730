import moment     from "moment";

import dispatcher from "../dispatcher.js";

const initialState = {
  groups: {},//holds basic groups info
  group_invites: {},//holds invites to groups for current user
  group_redirect_id: null,
  loading: {
    groups: true,
  },
};

//remember to pay attention to the achievements_loaded flag that only exists on the frontend.

//Possible concern: joining and leaving a group has some cross permission issues. Joining a group currently needs the user that is joining to be able to edit the group, but they won't be an admin, while deleteing a group will only be able to be done by a group admin, but will then require touching every user object, but this requires life nav admin permissions. Should be able to be solved by cloud functions.

export default (state = initialState, action)=>{
  let newState = Object.assign({}, state);

  switch (action.type){

    case "CHECK_GROUP_INVITES": {
      const {
        email
      } = action.data;

      window.db.collection("group_members").where("invitation_sent_to", "==", email).where("status", "==", "invited").get().then((snapshot)=>{//TODO update to handle id vs just email
        snapshot.docs.forEach((doc)=>{

          dispatcher("RECEIVE_SINGLE_GROUP_INVITE", {
            invite: {
              invite_id: doc.id,
              ...doc.data()
            }
          });

        });
      }).catch((err)=>{
        console.log(err);
      });

      return newState;
    }
    case "RECEIVE_SINGLE_GROUP_INVITE": {
      const {
        invite
      } = action.data;

      newState.group_invites[invite.invite_id] = invite;

      return newState;
    }
    case "GET_PUBLIC_GROUPS": {

      window.db.collection("groups").where("settings.visibility", "==", "public").where("deleted", "==", "false").get().then((snapshot)=>{

        snapshot.docs.forEach((doc)=>{

          dispatcher("RECEIVE_SINGLE_GROUP", {
            group: {
              group_id: doc.id,
              ...doc.data()
            }
          });

        });

      });

      return state;
    }
    case "GET_SINGLE_GROUP": {
      const {
        user_groups,
        group_id,
        group_page,
        group_redirect_id
      } = action.data;

      window.db.collection("groups").doc(group_id).get().then((doc)=>{

        let data = doc.data();

        let user_not_member_of_private_group;
        if (data && ((data.settings.visibility !== "public") || data.deleted === "true")){
          if (user_groups){
            if (!user_groups[group_id]){
              user_not_member_of_private_group = true;
            }
          }
        }

        if (data && !user_not_member_of_private_group){

          data.group_id = doc.id;

          dispatcher("RECEIVE_SINGLE_GROUP", {
            group_redirect_id,
            group: data,
          });
          dispatcher("GET_GROUP_MEMBERS", {
            group_id: data.group_id,
          });
          dispatcher("GET_ALL_SECTIONS", {
            collection: "group",
            operand_one: "group_id",
            operator: "==",
            operand_two: data.group_id,
          });
          dispatcher("GET_ALL_ACHIEVEMENTS", {
            collection: "group",
            operand_one: "group_id",
            operator: "==",
            operand_two: data.group_id,
          });
        } else {

          if (group_page){
            dispatcher("ADD_MODAL", {
              name: "CONFIRMATION",
              modalProps: {
                header: "Uh oh",
                message: "You may be seeing this message for one of three reasons. You do not have permissions to veiw this group, this group id is not correct, or this group has been deleted.",
                okButton: true,
                callback: ()=>{
                  window.location.href = window.location.origin + "/";
                }
              }
            });
          }

          dispatcher("REMOVE_SINGLE_GROUP_FROM_REDUX", {
            group_id
          });
        }

      }).catch((err)=>{
        console.log(err);
      });

      return newState;
    }
    case "RECEIVE_SINGLE_GROUP": {
      const {
        group,
        group_redirect_id
      } = action.data;

      newState.groups[group.group_id] = {
        ...newState.groups[group.group_id],
        ...group
      };

      if (group_redirect_id){
        newState.group_redirect_id = group_redirect_id;
      }

      return newState;
    }
    case "GET_GROUP_MEMBERS": {
      const {
        group_id
      } = action.data;

      window.db.collection("group_members").where("group_id", "==", group_id).get().then((snapshot)=>{//might want to filter out group members with the status of "deleted" or "left"

        snapshot.docs.forEach((doc)=>{

          dispatcher("RECEIVE_SINGLE_GROUP_MEMBER", {
            member: {
              member_id: doc.id,
              ...doc.data()
            }
          });

        });

      });

      return newState;
    }
    case "CREATE_GROUP": {
      const {
        display_name
      } = action.data;

      const modified = moment().toISOString();

      const payload = {
        created: modified,
        ...action.data.payload,
        deleted: "false",
        modified,
      };

      window.db.collection("groups").add(payload).then((docRef)=>{

        payload.group_id = docRef.id;

        dispatcher("RECEIVE_SINGLE_GROUP", {
          group: payload
        });
        dispatcher("ADD_GROUP_TO_USER", {
          uid: payload.creator,
          group: {
            group_id: payload.group_id,
            group_name: payload.name,
          }
        });
        dispatcher("CREATE_GROUP_MEMBER", {
          created_group_id: docRef.id,
          payload: {
            user_id: payload.creator,
            group_id: docRef.id,
            group_name: payload.name,
            invitation_type: "id",//email, id (add by url in future)
            invitation_sent_to: payload.creator,
            display_name,
            status: "joined",//invited, rejected, joined,
            //user name will be fetched from main user data. If user has not joined and viewer is admin, show info user was invited by, if viewer is not admin, do not show invitee. If user joined, but privacy is on, show to admins, but not members.
          }
        });

      });

      return newState;
    }
    case "CREATE_GROUP_MEMBER": {
      const {
        admin_ids,
        created_group_id,
      } = action.data;

      const modified = moment().toISOString();

      const payload = {
        created: modified,
        ...action.data.payload,
        deleted: "false",
        modified,
      };

      const id = `${payload.group_id}-${payload.user_id}`;//groupID-userID

      window.db.collection("group_members").doc(id).set(payload).then(()=>{

        dispatcher("RECEIVE_SINGLE_GROUP_MEMBER", {
          created_group_id,
          member: {
            ...payload,
            member_id: id,
          }
        });

        if (admin_ids){
          admin_ids.forEach((admin_id)=>{
            dispatcher("CREATE_NOTIFICATION", {
              payload: {
                user_id: admin_id,
                message: `${payload.display_name} has requested to join ${payload.group_name}.`,
                subject: "group_request_to_join",
                image_url: "",//TODO will need updating once unique images are sorted for notifications / achievements
                url: `/group/${payload.group_id}`,
              }
            });
          });
        }
      });

      return newState;
    }
    case "RECEIVE_SINGLE_GROUP_MEMBER": {
      const {
        member,
        created_group_id
      } = action.data;

      newState.groups[member.group_id].members = {
        ...newState.groups[member.group_id].members,
        [member.member_id]: member
      };

      if (created_group_id){
        newState.group_redirect_id = created_group_id;
      }

      return newState;
    }
    case "REINVITE_GROUP_MEMBER": {
      const {
        onSuccess,
        member_id
      } = action.data;

      const modified = moment().toISOString();

      const payload = {
        status: "invited",
        modified,
      };

      window.db.collection("group_members").doc(member_id).update(payload).then(()=>{
        if (onSuccess){
          onSuccess();
        }
      });

      return newState;
    }
    case "UPDATE_GROUP": {
      const {
        group_id,
        onSuccess,
        user_id,
        admin_edit,
      } = action.data;

      const modified = moment().toISOString();

      if (admin_edit === "promote"){
        action.data.payload = {
          admins: [
            ...newState.groups[group_id].admins
          ]
        };

        action.data.payload.admins.push(user_id);
      } else if (admin_edit === "demote"){
        action.data.payload = {
          admins: [
            ...newState.groups[group_id].admins
          ]
        };

        const index = newState.groups[group_id].admins.indexOf(user_id);
        if (index > -1){
          action.data.payload.admins.splice(index, 1);
        }
      }

      const payload = {
        ...action.data.payload,
        modified,
      };

      window.db.collection("groups").doc(group_id).update(payload).then(()=>{

        if (onSuccess){
          onSuccess();
        } else {
          // dispatcher("ADD_MODAL", {
          //   name: "CONFIRMATION",
          //   modalProps: {
          //     header: "Success",
          //     message: "Update to group saved.",
          //     okButton: true
          //   }
          // });
        }

      });

      newState.groups[group_id] = {//Should be moved to success dispatch
        ...newState.groups[group_id],
        ...payload
      };

      return newState;
    }
    case "DELETE_GROUP": {
      //Add flag on group that it is deleted, do not touch group_members, but delete group ids off users. (leave group achievements and sections untouched)
      const {
        user_id,
        group_id,
      } = action.data;

      const modified = moment().toISOString();

      const payload = {
        modified,
        deleted: "true"
      };

      window.db.collection("groups").doc(group_id).update(payload).then(()=>{

        dispatcher("REMOVE_GROUP_FROM_CURRENT_USER", {
          user_id,
          group: {
            group_id,
          }
        });

        dispatcher("REMOVE_SINGLE_GROUP_FROM_REDUX", {
          group_id,
        });

        dispatcher("ADD_MODAL", {
          name: "CONFIRMATION",
          modalProps: {
            header: "Success",
            message: "The group has been deleted.",
            okButton: true
          }
        });

      });

      return newState;
    }
    case "DELETE_GROUP_MEMBER": {
      const {
        onSuccess,
        member_id,
        group_id,
        group_name,
        user_id,
      } = action.data;

      const modified = moment().toISOString();

      const payload = {
        status: "deleted",
        modified,
      };

      window.db.collection("group_members").doc(member_id).update(payload).then(()=>{
        if (onSuccess){
          onSuccess();
        }

        dispatcher("CREATE_NOTIFICATION", {
          payload: {
            user_id: user_id,
            message: `You've been removed from the group ${group_name}.`,
            subject: "group_removal",
            image_url: "",//TODO will need updating once unique images are sorted for notifications / achievements
            url: "",
          }
        });
      });

      delete newState.groups[group_id].members[member_id];

      return newState;
    }
    case "DECLINE_GROUP": {
      const {
        group_invite_id
      } = action.data;

      const modified = moment().toISOString();

      const payload = {
        status: "declined",
        modified,
      };

      window.db.collection("group_members").doc(group_invite_id).update(payload);

      delete newState.group_invites[group_invite_id];

      return newState;
    }
    case "JOIN_GROUP": {
      const {
        user_id,
        display_name,
        group_id,
        group_name,
        group_invite_id,
        by_admin,
        inviter_id,
        admin_ids,
        onSuccess
      } = action.data;

      const modified = moment().toISOString();

      const payload = {
        status: "joined",
        modified,
        display_name,
        user_id,
        group_id
      };

      if (group_invite_id){//update or create
        window.db.collection("group_members").doc(group_invite_id).update(payload).then(()=>{

          if (!by_admin){
            dispatcher("ADD_GROUP_TO_USER", {
              uid: payload.user_id,
              group: {
                group_id,
                group_name,
              }
            });
          }

          dispatcher("CREATE_NOTIFICATION", {
            payload: {
              user_id: inviter_id,
              message: `${display_name} has joined ${group_name}.`,
              subject: "group_invite_join",
              image_url: "",//TODO will need updating once unique images are sorted for notifications / achievements
              url: `/group/${group_id}`,
            }
          });

          dispatcher("GET_SINGLE_GROUP", {
            group_redirect_id: group_id,
            group_id,
          });

          if (onSuccess){
            onSuccess();
          }
        });

        delete newState.group_invites[group_invite_id];
      } else {//Be wary of not having all the same pieces of data

        window.db.collection("group_members").add(payload).then(()=>{

          if (!by_admin){
            dispatcher("ADD_GROUP_TO_USER", {
              uid: payload.user_id,
              group: {
                group_id,
                group_name,
              }
            });

            admin_ids.forEach((admin_id)=>{
              dispatcher("CREATE_NOTIFICATION", {
                payload: {
                  user_id: admin_id,
                  message: `${display_name} has joined ${group_name}.`,
                  subject: "group_open_join",
                  image_url: "",//TODO will need updating once unique images are sorted for notifications / achievements
                  url: `/group/${group_id}`,
                }
              });
            });
          }

          dispatcher("ADD_MODAL", {
            name: "CONFIRMATION",
            modalProps: {
              heading: "Success",
              message: (by_admin) ? ("You have added the new member.") : ("You have joined this group."),
              okButton: true
            }
          });

          dispatcher("GET_GROUP_MEMBERS", {
            group_id
          });

          if (onSuccess){
            onSuccess();
          }

        });

      }

      return newState;
    }
    case "LEAVE_GROUP": {
      const {
        group_id,
        user_id,
        member_id,
        onSuccess,
        admin,
      } = action.data;

      const modified = moment().toISOString();

      const payload = {
        status: "left",
        modified,
      };

      window.db.collection("group_members").doc(member_id).update(payload).then(()=>{

        if (onSuccess){
          onSuccess();
        }
        dispatcher("REMOVE_GROUP_FROM_CURRENT_USER", {
          user_id,
          group: {
            group_id,
          }
        });
        dispatcher("REMOVE_SINGLE_GROUP_FROM_REDUX", {
          group_id,
        });
      });

      if (admin){

        let admins = [];
        newState.groups[group_id].admins.forEach((single_admin)=>{
          if (single_admin !== user_id){
            admins.push(single_admin);
          }
        });

        const group_payload = {
          admins,
          modified,
        };

        window.db.collection("groups").doc(group_id).update(group_payload);
      }

      return newState;
    }
    case "REMOVE_SINGLE_GROUP_FROM_REDUX": {
      const {
        group_id
      } = action.data;

      delete newState.groups[group_id];

      return newState;
    }
    case "FLUSH_REDIRECT": {

      newState.group_redirect_id = null;

      return newState;
    }
    case "LOGOUT": {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
