import React     from "react";

import firebaseAuthLogic from "./firebaseAuthLogic.js";

import styles    from "../../styles/styles.js";

export default class SignUpComponent extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      email: "",
      password: "",
      errors: {},
    };

    this.email_ref    = React.createRef();
    this.password_ref = React.createRef();
  }

  componentDidMount = ()=>{
    firebaseAuthLogic(this.onRequestClose);
  };

  onChange = (e)=>{
    let state = {};
    if (e !== undefined){
      state[e.target.name] =  e.target.value;//TODO make sure to trim empty space from value
      this.setState(state);
    }
  };

  onEnter = (e)=>{
    if (e.key === "Enter"){
      this.onSubmit();
    }
  };

  onSubmit = (e)=>{
    const { onRequestClose } = this.props;
    const { password, email } = this.state;

    if (e){
      e.preventDefault();
    }

    let errors = this.validate();
    if (Object.keys(errors).length !== 0){
      this.setState({
        errors
      });
      return;
    }

    window.firebase.auth().createUserWithEmailAndPassword(email, password).then(()=>{
      onRequestClose();//TODO add welcome modal here
    }).catch(function(error){

      var errorCode = error.code;
      var errorMessage = error.message;

      if (error.code === "auth/email-already-in-use"){//attempt sign in
        window.firebase.auth().signInWithEmailAndPassword(email, password).then(()=>{
          onRequestClose();
        }).catch(function(error){

          var errorCode2 = error.code;
          var errorMessage2 = error.message;
          console.log("sign in error");
          console.log(errorCode2);
          console.log(errorMessage2);

        });
      }

      console.log("sign up error");
      console.log(errorCode);
      console.log(errorMessage);

      //show error message about wrong account info. In future, include option where, after sign in, can connect normal email / password to google / facebook

    });

  };

  validate = ()=>{
    const { email, password } = this.state;

    let errors = {};

    if (!email.match(/^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i)){
      if (Object.keys(errors).length === 0){
        this.email_ref.current.focus();
      }
      errors.email = "Please enter a valid email";
    }

    if (email === ""){
      if (Object.keys(errors).length === 0){
        this.email_ref.current.focus();
      }
      errors.email = "Email is required";
    }

    if (password === ""){
      if (Object.keys(errors).length === 0){
        this.password_ref.current.focus();
      }
      errors.password = "Password is required";
    }

    return errors;
  };

  render(){
    const { onRequestClose } = this.props;

    return (
      <div id="sign-up-component" style={{...styles.signUpComponent}}>

        <div id="sign-up-component-top-container" style={{...styles.signUpComponentTopContainer}}>
          <div id="sign-up-component-header" style={{...styles.signUpComponentHeader}}>Login to Life Nav</div>
          <div
            id="sign-up-component-close"
            onClick={onRequestClose}
            style={{...styles.signUpComponentClose}}
          >
            X
          </div>
        </div>

        <div style={{textAlign: "center"}}>*Note that the Facebook login / sign up currently only works for select designated testers while we are in Alpha.</div>

        <div id="firebaseui-auth-container"></div>

      </div>

    );
  }
}
