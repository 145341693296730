import React, { useEffect }  from "react";

import { connect } from "react-redux";

import dispatcher    from "../../redux/dispatcher.js";

import Report        from "../report/Report.js";

import styles from "../../styles/styles.js";

function ReportsPage(props){

  const {
    reports,
    sections,
    achievements,
  } = props;
  
  useEffect(()=>{
    dispatcher("GET_REPORTS");
  }, []);

  const getGeneralReports = ()=>{

    return Object.keys(reports).filter((report_id)=>{
      const report = reports[report_id];

      return (!report.achievement_id && !report.section_id);
    }).map((report_id)=>{
      const report = reports[report_id];

      return (
        <Report key={report_id} report={report} general_report />
      );
    });
  };

  const getAchievementReports = ()=>{

    return Object.keys(reports).filter((report_id)=>{
      const report = reports[report_id];

      return report.achievement_id;
    }).map((report_id)=>{
      const report = reports[report_id];

      const subject = achievements[`${report.collection}_achievements`][report.achievement_id];

      return (
        <Report key={report_id} report={report} subject={subject} achievement_report />
      );
    });
  };

  const getSectionReports = ()=>{

    return Object.keys(reports).filter((report_id)=>{
      const report = reports[report_id];

      return report.section_id;
    }).map((report_id)=>{
      const report = reports[report_id];

      const subject = sections[`${report.collection}_sections`][report.section_id];

      return (
        <Report key={report_id} report={report} subject={subject} section_report />
      );
    });
  };

  //Add tabs for each type
  const GeneralReports     = getGeneralReports();
  const SectionReports     = getSectionReports();
  const AchievementReports = getAchievementReports();

  return (
    <div id="reports-page" style={{...styles.reportsPage}}>

      <div id="reports-page-container" style={{...styles.reportsPageContainer}}>

        <div id="reports-page-header" style={{...styles.reportsPageHeader}}>Reports</div>

        {GeneralReports}
        {SectionReports}
        {AchievementReports}

      </div>

    </div>
  );
}

const mapState = (state)=>{

  const hackToUpdate = Math.random();

  return {
    user_info: state.sessionReducer.user_info,
    reports: state.sessionReducer.reports || {},
    achievements: state.achievementReducer,
    sections: state.sectionReducer,
    hackToUpdate,
  };
};

export default connect(mapState)(ReportsPage);
