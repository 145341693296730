export default (state = {}, action)=>{
  let newState = Object.assign({}, state);

  switch (action.type){

    case "API_ERROR":{
      newState.error = action.data;
      return newState;
    }
    case "FLUSH_ERROR":{
      newState.error = null;
      return newState;
    }
    case "FLUSH_STATE":{
      return {};
    }
    case "LOGOUT": {
      return {};
    }
    default:{
      return state;
    }
  }
};
