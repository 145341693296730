import React, { useState, useRef } from "react";

import { connect }         from "react-redux";

import { useNavigate }     from "react-router-dom";

import { TweenMax }        from "gsap/all";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown }   from "@fortawesome/free-solid-svg-icons";

import utility             from "../../functions/utility.js";

import styles              from "../../styles/styles.js";

function HomePage(props){

  const { sections } = props;

  const [core_open, set_coreOpen]           = useState(false);
  const [skill_open, set_skillOpen]         = useState(false);
  const [adventure_open, set_adventureOpen] = useState(false);

  const coreRef           = useRef();
  const skillRef          = useRef();
  const adventureRef      = useRef();
  const coreArrowRef      = useRef();
  const skillArrowRef     = useRef();
  const adventureArrowRef = useRef();

  const state = {
    core_open,
    skill_open,
    adventure_open,
    set_coreOpen,
    set_skillOpen,
    set_adventureOpen,
    coreRef,
    skillRef,
    adventureRef,
    coreArrowRef,
    skillArrowRef,
    adventureArrowRef,
  };

  const app_version = "v0.0.8";
  const main_sections = [
    "FpOjDOj4Ed7ZPnPgAHJ5",
    "V9QROje0Sg88LR5QOyUu",
    "zucv8OCTvAyKnVvQq2qx",
  ];//these sections should come from api/database

  const history = useNavigate();

  const navigate = (url)=>{
    history(url);
  };

  const getMainSections = ()=>{
    return main_sections.filter((section)=>{
      return sections[section];
    }).map((section, key)=>{

      const section_name = sections[section].name;

      const theCallback = ()=>{

        if (state[section_name + "_open"]){
          TweenMax.to(state[section_name + "Ref"].current, 1, {maxHeight: 0});
          TweenMax.to(state[section_name + "ArrowRef"].current, 0.25, {rotation: 0});
        } else {
          TweenMax.to(state[section_name + "Ref"].current, 3, {maxHeight: 1000});
          TweenMax.to(state[section_name + "ArrowRef"].current, 0.25, {rotation: 180});
        }

        state["set_" + section_name + "Open"](!state[section_name + "_open"]);
      };

      return (
        <div
          id="home-page-main-section-group-container"
          style={{...styles.homePageMainSectionGroupContainer}}
          key={key}
        >

          <div id="home-page-main-section-group-name" style={{...styles.homePageMainSectionGroupName}}>
            {utility.upperCase(section_name)} Achievements
          </div>

          <div id={section} ref={state[section_name + "Ref"]} style={{...styles.homePageMainSectionGroupContent}}>
            {getSubSections(section)}
          </div>

          <div
            id="home-page-main-section-group-arrow-button"
            style={{...styles.homePageMainSectionGroupArrowButton}}
            onClick={theCallback}
          >
            <FontAwesomeIcon
              id={section + "-arrow"}
              forwardedRef={state[section_name + "ArrowRef"]}
              style={{...{color: styles.White}}}
              size="2x"
              icon={faChevronDown}
            />
          </div>

        </div>
      );
    });
  };

  const getSubSections = (parent_id)=>{

    return Object.keys(sections).filter((section_id)=>{
      const section = sections[section_id];

      return (section.parent === parent_id) && (section.deleted !== "true");
    }).map((sub_section_id, key)=>{
      const sub_section = sections[sub_section_id];

      return (
        <div
          id="home-page-main-sub-section-button-container"
          style={{...styles.homePageMainSubSectionButtonContainer, padding: 0}}
          key={key}
        >

          <div
            id="home-page-main-sub-section-button"
            style={{margin: 16}}
            onClick={()=>{navigate(`/achievements/global/${sub_section_id}`);}}
          >
            {utility.upperCase(sub_section.name)}
          </div>

        </div>
      );
    });
  };

  // const makeAdmin = ()=>{
  //   const email = "calebrechten@gmail.com";
  //
  //   const addAdminRole = window.fb_functions.httpsCallable("addAdminRole");
  //
  //   addAdminRole({email}).then((response)=>{
  //     console.log(response);
  //   });
  // };

  const mainSections = getMainSections();

  // <div onClick={this.makeAdmin}>Make Admin</div>
  return (
    <div id="home-page" style={{...styles.homePage}}>

      <div
        id="home-page-patch-notes-container"
        style={{...styles.homePagePatchNotesContainer}}
        onClick={()=>{history("/patch-notes");}}
      >
        {app_version}
        <div id="home-page-patch-notes-label" style={{...styles.homePagePatchNotesLabel}}>Patch Notes</div>
      </div>

      <a
        id="home-page-patreon-link"
        style={{...styles.homePagePatreonLink}}
        href="https://www.patreon.com/calebsundance"
      >
        Support me on Patreon!
      </a>

      {mainSections}

      <div
        id="home-page-achievement-workshop-button"
        style={{...styles.homePageAchievementWorkshopButton}}
        onClick={()=>{navigate("/achievement-workshop");}}
      >
        Achievement Workshop
      </div>

      <div
        id="home-page-group-achievements-button"
        style={{...styles.homePageGroupAchievementButton}}
        onClick={()=>{navigate("/group");}}
      >
        Group Achievements
      </div>

      <div
        id="home-page-personal-achievements-button"
        style={{...styles.homePagePersonalAchievementButton}}
        onClick={()=>{navigate("/personal-achievements");}}
      >
        Personal Achievements
      </div>

    </div>
  );
}
const mapState = (state)=>{
  const hackToUpdate = Math.random();

  return {
    sections: state.sectionReducer.global_sections,
    hackToUpdate
  };
};

export default connect(mapState)(HomePage);
