import React  from "react";

import styles from "../../styles/styles.js";

// {
//   version_number: "0.0.",
//   release_date: "//2020",
//   features: [
//     "",
//   ],
//   bugs: [
//     "",
//   ]
// },

// SEARCH: app_version to fix the areas around the app where the version is displaye

const patch_notes = {
  patch_notes: [
    {
      version_number: "0.0.8",
      release_date: "05/02/2022",
      features: [
        "Added basic user achievement stats to profile page.",
        "Moved user settings from profile page to side menu."
      ],
      bugs: [
        "None of note.",
      ]
    },
    {
      version_number: "0.0.7",
      release_date: "11/14/2020",
      features: [
        "Aligned organization of achievements and sections in the group and personal areas of the app with the main app.",
        "Enabled the ability to create multiple achievements at once.",
        "Added version numbers for achievements and sections for technical use.",
        "Added a section progress bar.",
        "Added in-app notifications for group activity regarding joining status changes, admin status changes.",
        "Added in-app notifications for suggested achievements and sections approved, updated, or removed.",
      ],
      bugs: [
        "Fixed issue where clicking / tapping on the 'Request to Join' group button would create multiple requests.",
      ]
    },
    {
      version_number: "0.0.6",
      release_date: "06 / 27 / 2020",
      features: [
        "Added area to suggest new global achievements and sub-sections. Also added voting on the suggestions by other people using the app to show support.",
        "Added two separate columns for desktop view separating sub-sections and achievements.",
        "Added small headers separating personal and group achievements and sub-sections from global achievements and sub-sections.",
        "Updated design of achievements (how they look when completed, how they look when opening the additional options, and most of the icons associated with each option).",
        "Added a place for achievement 'points' (previously referred to as 'stars') in the layout of the achievement.",
        "Combined 'Tools', 'Info', and 'Notes' achievement buttons into one button.",
        "Achievements and sections are now both sorted alphabetically by default."
      ],
      bugs: [
        "Fixed issue with some group sections and achievements showing up under the wrong group.",
        "Various fixes.",
      ]
    },
    {
      version_number: "0.0.5",
      features: [
        "Added ability for users to report general issues from the support page.",
        "Added ability for users to report issues with specific sections or achievements.",
        "Added area for admins to view and handle reported issues by users.",
        "More incremental color alignment on achievements. Some discrepancies still exist.",
        "Updated the 'about' page slightly.",
      ],
      bugs: [
        "Aligned achievement creation / edit section selection dropdown to current updated version of the dropdown.",
        "Fixed buttons for admins directing them to correct location now when trying to create an achievement or section from the achievement workshop area.",
        "Fixed issue with ghost sections appearing at the bottom of the dropdown when creating a global section or achievement.",
        "Fixed grammar error in the 'about' page. (Thank you Nina!) and updated the version number displayed there.",
        "Fixed a small mispelling on a sewing achievement.",
      ]
    },
    {
      version_number: "0.0.4",
      features: [
        "Added ability to add personal notes on achievements.",
        "More incremental color alignment on achievements. Some discrepancies still exist."
      ],
      bugs: [
        "Fixed handling of trying to add personal links when not logged in.",
      ]
    },
    {
      version_number: "0.0.3",
      features: [
        "Added the ability for users, groups, and Life Nav admins to add links to achievements under the 'info' and 'tools' sub-sections. The links consist of a title, an optional description, and of course the url itself. Editing and deleting these links is possible as well.",
      ],
      bugs: [
        "Fixed a rather serious bug related to group sections / achievements breaking the app.",
        "Aligned colors more for sub-options on achievements.",
      ]
    },
    {
      version_number: "0.0.2",
      features: [
        "Added search bar for looking up and navigating to sections.",
        "Revamped how deleting achievements and sections work under the hood.",
        "Updated colors on achievement and section creation pages to follow established color differences between global, group, and personal content."
      ],
      bugs: [
        "Removed some test sections and achievements.",
        "Fixed a few sections appearing in wrong locations.",
        "Making sure sections are loading properly in some cases.",
      ]
    },
    {
      version_number: "0.0.1",
      features: [
        "Added this patch notes page.",
        "Added 1000+ achievements to the global app.",
        "Finished allowing group achievement edits.",
        "Added an 'about' page.",
        "Added link to Patreon on homescreen.",
        "Added a skeleton page for the privacy policy and terms of service and added a link to it the side nav.",
        "Added a skeleton support page and added a link to it in the side nav.",
        "Aligned colors more across the app.",
        "Fixed some of the side nav styling.",
        "Added placeholder trophy icon on achievements and sections until more custom icons can be added.",
        "Made completed achievements pop more.",
        "Added completion date to completed achievements.",
      ],
      bugs: [
        "Fixed how achievements and sections are loaded into the app."
      ]
    }
  ]
};

export default function PatchNotesPage(){

  const mobile = (window.screen.width <= 800);
  const itemStyle = {margin: 8, width: "100%"};

  return (
    <div style={{...styles.CenterContent, ...{flexDirection: "column", paddingTop: 60}}}>

      <div style={{...styles.H2, ...styles.Bold, ...{marginBottom: 28, marginTop: 28}}}>Patch Notes</div>

      {patch_notes.map((notes, key)=>{
        const current = (key === 0) ? ("Current:") : (null);

        return (
          <React.Fragment>

            <div style={{...styles.H3, ...styles.Bold, marginBottom: 28, marginTop: 28}}>{current} v{notes.version_number}</div>
            <div style={{...styles.SubText, marginBottom: 28}}>{notes.release_date && `Release Date: ${notes.release_date}`}</div>

            <div style={{...styles.CenterContent, ...{flexDirection: "column", width: (mobile) ? (8 * 40) : (8 * 100)}}}>
              <div style={{...styles.H3, ...styles.Bold}}>Features</div>
              <div style={{...styles.line, borderBottom: 0}}></div>

              {notes.features.map((feature)=>{
                return (
                  <div style={itemStyle}>- {feature}</div>
                );
              })}

              <div style={{...styles.H3, ...styles.Bold}}>Bugs</div>

              <div style={{...styles.line, borderBottom: 0}}></div>

              {notes.bugs.map((bug)=>{
                return (
                  <div style={itemStyle}>- {bug}</div>
                );
              })}

              <div style={{...styles.line, borderWidth: 2}}></div>

            </div>
          </React.Fragment>
        );
      })}

    </div>
  );
}
