import moment     from "moment";

import dispatcher from "../dispatcher.js";
import apiRequest from "../apiRequest.js";

const initialState = {
  global_achievements: {},
  group_achievements: {},
  personal_achievements: {},
  suggested_achievements: {},
  user_achievements: {},
  viewed_profile_achievements: {},
  loading: false,
};

export default (state = initialState, action = {data: {}})=>{
  let newState = Object.assign({}, state);
  action.data = action.data || {};

  const {
    collection,
    achievement_id,
  } = action.data;
  const achievement_collection = `${collection}_achievements`;

  switch (action.type){
    case "GET_ALL_ACHIEVEMENTS": {
      const {
        operand_one,
        operator,
        operand_two,
      } = action.data;

      apiRequest({
        collection: achievement_collection,
        operand_one,
        operator,
        operand_two,
      }).then((snapshot)=>{

        snapshot.docs.forEach((doc)=>{

          dispatcher("RECEIVE_SINGLE_ACHIEVEMENT", {
            collection,
            achievement: {
              ...doc.data(),
              ...{achievement_id: doc.id}
            }
          });

        });

      }).catch((err)=>{
        console.log(err);
      });

      return state;
    }
    case "GET_SINGLE_ACHIEVEMENT": {

      apiRequest({
        collection: achievement_collection,
        id: achievement_id,
      }).then((doc)=>{

        if (doc.exists){
          dispatcher("RECEIVE_SINGLE_ACHIEVEMENT", {
            collection,
            achievement: {
              ...doc.data(),
              ...{achievement_id: doc.id}
            }
          });
        }

      });

      return state;
    }
    case "GET_USER_ACHIEVEMENTS": {
      const {
        user_id
      } = action.data;

      apiRequest({
        collection: "user_achievements",
        operand_one: "user_id",
        operator: "==",
        operand_two: user_id,
      }).then((snapshot)=>{

        snapshot.docs.forEach((doc)=>{

          dispatcher("RECEIVE_SINGLE_USER_ACHIEVEMENT", {
            user_achievement: doc.data()
          });

        });

      }).catch((err)=>{
        console.log(err);
      });

      return state;
    }
    case "GET_VIEWED_PROFILE_ACHIEVEMENTS": {
      const {
        user_id
      } = action.data;

      apiRequest({
        collection: "user_achievements",
        operand_one: "user_id",
        operator: "==",
        operand_two: user_id,
      }).then((snapshot)=>{

        snapshot.docs.forEach((doc)=>{

          dispatcher("RECEIVE_SINGLE_VIEWED_PROFILE_ACHIEVEMENT", {
            viewed_profile_achievement: doc.data()
          });

        });

      });

      return state;
    }
    case "RECEIVE_SINGLE_ACHIEVEMENT": {
      const {
        achievement
      } = action.data;

      if (newState[achievement_collection]){
        newState[achievement_collection][achievement.achievement_id] = achievement;
      } else {
        newState[achievement_collection] = {
          [achievement.achievement_id]: achievement
        };
      }

      return newState;
    }
    case "RECEIVE_SINGLE_USER_ACHIEVEMENT": {
      const {
        user_achievement
      } = action.data;

      newState.user_achievements[user_achievement.achievement_id] = {
        ...newState.user_achievements[user_achievement.achievement_id],
        ...user_achievement
      };

      return newState;
    }
    case "RECEIVE_SINGLE_VIEWED_PROFILE_ACHIEVEMENT": {
      const {
        viewed_profile_achievement
      } = action.data;

      const id = viewed_profile_achievement.achievement_id;

      newState.viewed_profile_achievements[id] = {
        ...newState.viewed_profile_achievements[id],
        ...viewed_profile_achievement
      };

      return newState;
    }
    case "RECEIVE_ACHIEVEMENT_LINK": {
      const {
        link,
      } = action.data;
      const {
        collection,
        subject,
        related_id
      } = link;

      //Example: personal_achievements.18dkn30skdnaukd.tools
      let subject_links = newState[`${collection}_achievements`][related_id][subject];

      if (subject_links){//checking if any links have been added to Redux achievement under this collection

        subject_links = {
          ...subject_links,
          [link.link_id]: link
        };

      } else {

        subject_links = {
          [link.link_id]: link
        };

      }

      newState[`${collection}_achievements`][related_id][subject] = subject_links;
      // console.log(newState[`${collection}_achievements`][related_id][subject]);

      return newState;
    }
    case "TOGGLE_COMPLETE_ACHIEVEMENT": {

      const modified = moment().toISOString();

      const payload = {
        created: modified,
        ...action.data.payload,
        collection,
        modified,
      };

      const id = `${payload.user_id}-${achievement_collection}-${payload.achievement_id}`;//userID-collection-achievementID

      window.db.collection("user_achievements").doc(id).set(payload).then(()=>{

        dispatcher("RECEIVE_SINGLE_USER_ACHIEVEMENT", {
          user_achievement: payload
        });

      });

      return state;
    }
    case "TOGGLE_FAVORITE_ACHIEVEMENT": {

      const modified = moment().toISOString();

      const payload = {
        created: modified,
        ...action.data.payload,
        collection,
        modified,
      };

      const id = `${payload.user_id}-${achievement_collection}-${payload.achievement_id}`;//userId-collection-achievementID

      window.db.collection("user_achievements").doc(id).set(payload).then(()=>{

        dispatcher("RECEIVE_SINGLE_USER_ACHIEVEMENT", {
          user_achievement: payload
        });

      });

      return state;
    }
    case "TOGGLE_TRACKING_ACHIEVEMENT": {

      const modified = moment().toISOString();

      const payload = {
        created: modified,
        ...action.data.payload,
        collection,
        modified,
      };

      const id = `${payload.user_id}-${achievement_collection}-${payload.achievement_id}`;//userID-collection-achievementID

      window.db.collection("user_achievements").doc(id).set(payload).then(()=>{

        dispatcher("RECEIVE_SINGLE_USER_ACHIEVEMENT", {
          user_achievement: payload
        });

      });

      return state;
    }
    case "CREATE_ACHIEVEMENT": {

      const modified = moment().toISOString();
      const version = (action.data.payload.version) ? (action.data.payload.version + 1) : (1);

      let payload = {
        created: modified,
        ...action.data.payload,
        deleted: "false",
        version,
        modified,
      };

      window.db.collection(achievement_collection).add(payload).then((docRef)=>{

        payload.achievement_id = docRef.id;

        dispatcher("RECEIVE_SINGLE_ACHIEVEMENT", {
          collection,
          achievement: payload
        });

        let message;
        if (payload.suggesting_user_id){
          message = "Your suggestion has been successfully submitted! Other users can now vote on your suggestion.";
        } else {
          message = "The new achievement has been successfully created.";
        }

        dispatcher("ADD_MODAL", {//TODO handle case where we don't want to show this message at all and instead want to use the callback prop
          name: "CONFIRMATION",
          modalProps: {
            header: "Success!",
            message,
            okButton: true,
            callback: ()=>{

              if (action.data.tools){//TODO move to a more reliable place where it is only created after the achievement has been created. cloud functions
                Object.keys(action.data.tools).forEach((link_id)=>{
                  dispatcher("CREATE_LINK", {
                    payload: {
                      ...action.data.tools[link_id],
                      link_collection: "global",
                      collection: "global",
                      related_id: docRef.id,
                    },
                  });
                });
              }

              if (action.data.info){//TODO move to a more reliable place where it is only created after the achievement has been created. cloud functions
                Object.keys(action.data.info).forEach((link_id)=>{
                  dispatcher("CREATE_LINK", {
                    payload: {
                      ...action.data.info[link_id],
                      link_collection: "global",
                      collection: "global",
                      related_id: docRef.id,
                    },
                  });
                });
              }

              if (action.data.callback){
                action.data.callback();
              } else {
                window.location.href = window.location.origin + "/achievement-workshop";
              }
            }
          }
        });

        if (collection === "global"){
          dispatcher("UPDATE_PARENT_SECTION", {
            collection,
            type: "CREATE",
            achievement_id: docRef.id,
            section_id: payload.section,
            modified
          });
        }

        if (payload.approved_admin){
          dispatcher("DELETE_ACHIEVEMENT", {
            collection: "suggested",
            section_id: payload.section,
            achievement_id: payload.suggested_achievement_id
          });

          dispatcher("CREATE_NOTIFICATION", {
            payload: {
              user_id: payload.suggested_user_id,
              message: `Your suggested achievement, ${payload.name}, has been approved!`,
              subject: "suggestion_achievement_approval",
              image_url: "",//TODO will need updating once unique images are sorted for notifications / achievements
              url: `/achievements/global/${payload.section}#${docRef.id}`,//TODO will need updating once url for achievements is established
            }
          });
        }

      }).catch((err)=>{
        console.log(err);
      });

      return state;
    }
    case "SAVE_NOTES": {
      const modified = moment().toISOString();

      const payload = {
        created: modified,
        ...action.data.payload,
        collection,
        modified,
      };

      const id = `${payload.user_id}-${achievement_collection}-${payload.achievement_id}`;//userId-collection-achievementID

      window.db.collection("user_achievements").doc(id).set(payload).then(()=>{

        dispatcher("RECEIVE_SINGLE_USER_ACHIEVEMENT", {
          user_achievement: payload
        });

      });

      return state;
    }
    case "EDIT_ACHIEVEMENT": {
      //change name, desc, or Stars - no action to parent section except updating modified date
      //change section - old and new sections need to be updated with new achievement data
      const {
        vote_type,
        user_id,
        admin_edit,
      } = action.data;

      const modified = moment().toISOString();
      const version = (action.data.payload.version) ? (action.data.payload.version + 1) : (1);

      let payload = {
        ...action.data.payload,
        version,
        modified,
      };

      const original_section = newState[achievement_collection][achievement_id].section;

      const section_updated = (payload.section !== original_section);

      let received_achievement = {...payload};
      if (vote_type){//for suggested achievement voting
        if (
          (vote_type === "up_votes" && newState.suggested_achievements[achievement_id].up_votes.includes(user_id))
          ||
          (vote_type === "down_votes" && newState.suggested_achievements[achievement_id].down_votes.includes(user_id))
        ){
          received_achievement[vote_type] = [];
          newState[achievement_collection][achievement_id][vote_type].forEach((uid)=>{
            if (uid !== user_id){
              received_achievement[vote_type].push(uid);
            }
          });

          payload[vote_type] = window.firebase.firestore.FieldValue.arrayRemove(user_id);
        } else {
          received_achievement[vote_type] = [...newState[achievement_collection][achievement_id][vote_type]];
          received_achievement[vote_type].push(user_id);

          payload[vote_type] = window.firebase.firestore.FieldValue.arrayUnion(user_id);
        }
      }

      window.db.collection(achievement_collection).doc(achievement_id).update(payload).then(()=>{

        payload = {
          ...newState[achievement_collection][achievement_id],
          ...received_achievement
        };
        payload.achievement_id = achievement_id;

        dispatcher("RECEIVE_SINGLE_ACHIEVEMENT", {
          collection,
          achievement: payload,
          achievement_id
        });

        if (!vote_type){
          dispatcher("ADD_MODAL", {
            name: "CONFIRMATION",
            modalProps: {
              header: "Success!",
              message: "The achievement has been successfully updated.",
              okButton: true,
              callback: ()=>{
                if (action.data.callback){
                  action.data.callback();
                } else {
                  window.location.href = window.location.origin + "/achievement-workshop";
                }
              }
            }
          });
        }
        // } else {
        //   dispatcher("ADD_MODAL", {
        //     name: "CONFIRMATION",
        //     modalProps: {
        //       header: "Success!",
        //       message: "The achievement has been successfully updated.",
        //       okButton: true,
        //       callback: ()=>{
        //         if (action.data.callback){
        //           action.data.callback();
        //         } else {
        //           window.location.href = window.location.origin + "/achievement-workshop";
        //         }
        //       }
        //     }
        //   });
        // }

        if (collection === "global"){

          dispatcher("UPDATE_PARENT_SECTION", {
            collection,
            type: "EDIT",
            achievement_id: (section_updated) ? (achievement_id) : (null),
            section_id: payload.section,
            modified
          });

          if (section_updated){
            dispatcher("UPDATE_PARENT_SECTION", {
              collection,
              type: "DELETE",
              achievement_id,
              section_id: original_section,
              modified,
            });
          }

        }

        if (collection === "suggested" && admin_edit){

          dispatcher("CREATE_NOTIFICATION", {
            payload: {
              user_id: payload.suggesting_user_id,
              message: `Your suggested achievement, ${payload.name}, has been edited by an admin.`,
              subject: "suggestion_achievement_change",
              image_url: "",//TODO will need updating once unique images are sorted for notifications / achievements
              url: "/achievement-workshop/suggestions",//TODO need to build space for user's suggested achievements
            }
          });

        }
      });

      return state;
    }
    case "DELETE_ACHIEVEMENT": {
      const {
        section_id,
        admin_edit,
        name,
        suggesting_user_id
      } = action.data;

      const modified = moment().toISOString();

      let payload = {
        deleted: "true",
        modified,
      };

      window.db.collection(achievement_collection).doc(achievement_id).update(payload).then(function(){

        if (section_id && (collection === "global")){//only if deleting single achievement, not needed when delete the entire section
          dispatcher("UPDATE_PARENT_SECTION", {
            collection,
            type: "DELETE",
            achievement_id,
            section_id,
            modified
          });
        }

        if (collection === "suggested" && admin_edit){
          dispatcher("CREATE_NOTIFICATION", {
            payload: {
              user_id: suggesting_user_id,
              message: `Your suggested achievement, ${name}, has been denied.`,
              subject: "suggestion_achievement_denial",
              image_url: "",//TODO will need updating once unique images are sorted for notifications / achievements
              url: "",
            }
          });
        }

      });

      newState[achievement_collection][achievement_id] = {
        ...newState[achievement_collection][achievement_id],
        ...payload
      };

      return newState;
    }
    case "DELETE_ACHIEVEMENT_FROM_REDUX": {

      delete newState[achievement_collection][achievement_id];

      return newState;
    }
    case "FLUSH_VIEWED_PROFILE_ACHIEVEMENTS": {

      newState.viewed_profile_achievements = {};

      return newState;
    }
    case "FLUSH_STATE": {
      return initialState;
    }
    case "LOGOUT": {

      newState.user_achievements = {};
      newState.group_achievements = {};
      newState.personal_achievements = {};
      newState.viewed_profile_achievements = {};

      return newState;
    }
    default: {
      return state;
    }
  }
};
