import React               from "react";
import { connect }         from "react-redux";

import { useNavigate }     from "react-router-dom";

import dispatcher          from "../../redux/dispatcher.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes }         from "@fortawesome/free-solid-svg-icons";

import styles              from "../../styles/styles.js";
//check edit / delete section options against personal collection
function SectionOptionsComponent(props){

  const {
    user_info,
    achievements,
    sections,
    section_id,
    collection,
    groups
  } = props;

  const history = useNavigate();

  const deleteModal = ()=>{

    const section_collection = sections[collection + "_sections"];

    if (section_collection[section_id] && section_collection[section_id].children && section_collection[section_id].children.length){
      dispatcher("ADD_MODAL", {
        name: "CONFIRMATION",
        modalProps: {
          header: "Not allowed to Delete",//probably better phrasing in the header
          message: "Before deleting this section, the child sections must either be deleted or moved to another parent section.",
          okButton: true,
        }
      });
    } else {
      dispatcher("ADD_MODAL", {
        name: "CONFIRMATION",
        modalProps: {
          header: "Are you sure? This is serious dude.",
          message: "Are you certain you want to delete this section? This is a big thing to do and it will also delete any and all achievements in this section. If you do not want to delete the achievements as well, then move them to another section first.",
          okButton: true,
          okButtonText: "DELETE",
          okButtonStyle: {backgroundColor: styles.Red},
          cancelButton: true,
          callback: ()=>{

            let admin_edit;
            if (section_collection[section_id].suggesting_user_id){
              admin_edit = (user_info.uid !== section_collection[section_id].suggesting_user_id);
            }

            dispatcher("DELETE_SECTION", {
              section_name: section_collection[section_id].name,
              collection,
              section_id,
              parent: section_collection[section_id].parent,
              suggesting_user_id: section_collection[section_id].suggesting_user_id,
              admin_edit,
              onSuccess: ()=>{
                history(-1, {replace: true});
                close();
              }
            });
          }
        }
      });
    }
  };

  const approveSuggestedSection = ()=>{

    const suggested_section = sections.suggested_sections[section_id];

    let suggested_achievement;
    Object.keys(achievements.suggested_achievements).some((achievement_id)=>{
      const achievement = achievements.suggested_achievements[achievement_id];
      if ((achievement.section === section_id) && (!achievement.deleted)){
        suggested_achievement = achievement;
        return true;
      } else {
        return false;
      }
    });

    dispatcher("GET_LINKS", {
      related_id: suggested_achievement.achievement_id,
      collection: "suggested",
    });

    dispatcher("ADD_MODAL", {
      name: "CONFIRMATION",
      modalProps: {
        header: "Are you sure?",
        message: "Are you certain you want to approve this section and it's achievement to become an official for all users?",
        okButton: true,
        okButtonText: "APPROVE",
        cancelButton: true,
        callback: ()=>{

          let achievement_payload = {
            ...suggested_achievement,
            suggested_user_id: suggested_achievement.suggesting_user_id,
            approved_admin: user_info.uid,
            suggested_achievement_id: suggested_achievement.achievement_id
          };
          delete achievement_payload.suggesting_user_id;
          delete achievement_payload.up_votes;
          delete achievement_payload.down_votes;
          delete achievement_payload.achievement_id;
          delete achievement_payload.info;
          delete achievement_payload.tools;
          delete achievement_payload.created;

          let section_payload = {
            ...suggested_section,
            suggested_user_id: suggested_section.suggesting_user_id,
            approved_admin: user_info.uid,
            suggested_section_id: suggested_section.section_id
          };
          delete section_payload.section_id;
          delete section_payload.suggesting_user_id;
          delete section_payload.down_votes;
          delete section_payload.up_votes;
          delete section_payload.achievements_loaded;
          delete section_payload.created;

          dispatcher("CREATE_SECTION", {
            collection: "global",
            section_payload,
            achievement_payload,
            tools: suggested_achievement.tools,
            info: suggested_achievement.info,
            callback: ()=>{
              history(-1, {replace: true});
            }
          });

        }
      }
    });
  };

  const getAdminOptions = ()=>{

    let group_admin;
    if (collection === "group"){
      if (groups[sections.group_sections[section_id].group_id].admins.includes(user_info.uid)){
        group_admin = true;
      }
    }

    const section = sections[collection + "_sections"][section_id];

    if (user_info.admin || (collection === "personal") || group_admin){

      let deleteSectionStyle;
      if ((section[section_id] && section[section_id].children && section[section_id].children.length)){
        deleteSectionStyle = {
          ...{backgroundColor: styles.Red, opacity: 0.33}
        };
      } else {
        deleteSectionStyle = {backgroundColor: styles.Red};
      }

      const AdminOptions = (user_info.admin) && (
        <React.Fragment>
          {(collection === "suggested") ? (
            <React.Fragment>
              <div
                id="section-options-add-new-achievement"
                style={{...styles.sectionOptionsAddNewAchievement}}
                onClick={approveSuggestedSection}
              >
                Approve Suggested Section
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div
                id="section-options-add-new-achievement"
                style={{...styles.sectionOptionsAddNewAchievement}}
                onClick={()=>{goTo(`create/achievement/global/${section_id}`);}}
              >
                Add New Achievement
              </div>
              <div
                id="section-options-add-new-child-section"
                style={{...styles.sectionOptionsAddNewChildSection}}
                onClick={()=>{goTo(`create/section/global/${section_id}`);}}
              >
                Add New Child Section
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      );
      const PersonalAdminOptions = (
        <React.Fragment>
          <div
            id="section-options-edit-this-section"
            style={{
              ...styles.sectionOptionsEditThisSection,
              backgroundColor: (collection === "personal") ? (styles.PersonalPrimaryColor) : ((collection === "group") ? (styles.GroupPrimaryColor) : ((collection === "suggested") ? (styles.Grey) : (styles.SecondaryColorMedium)))
            }}
            onClick={()=>{goTo(`edit/section/${collection}/${section_id}`);}}
          >
            Edit this section
          </div>
          <div
            id="section-options-delete-section"
            style={{...styles.sectionOptionsDeleteSection, ...deleteSectionStyle}}
            onClick={deleteModal}
          >
            Delete Section
          </div>
        </React.Fragment>
      );

      return (
        <React.Fragment>
          {AdminOptions}
          {PersonalAdminOptions}
        </React.Fragment>
      );
    }
  };

  const getGroupOptions = ()=>{

    let group_admin;

    Object.keys(user_info.groups).some((group_id)=>{
      if (groups[group_id].admins.includes(user_info.uid)){
        group_admin = true;
        return true;
      } else {
        return false;
      }
    });

    if (!group_admin){
      return;
    }

    return (
      <React.Fragment>
        <div
          id="section-options-add-new-group-achievement"
          style={{...styles.sectionOptionsAddNewGroupAchievement, backgroundColor: styles.GroupPrimaryColor}}
          onClick={()=>{goTo(`create/achievement/group/${section_id}`);}}
        >
          Add New Group Achievement
        </div>
        <div
          id="section-options-add-new-child-group-section"
          style={{...styles.sectionOptionsAddNewChildGroupSection, backgroundColor: styles.GroupPrimaryColor}}
          onClick={()=>{goTo(`create/section/group/${section_id}`);}}
        >
          Add New Child Group Section
        </div>
      </React.Fragment>
    );
  };

  const getPersonalOptions = ()=>{

    return (
      <React.Fragment>
        <div
          id="section-options-add-personal-achievement"
          style={{...styles.sectionOptionsAddPersonalAchievement}}
          onClick={()=>{goTo(`create/achievement/personal/${section_id}`);}}
        >
          Add Personal Achievement
        </div>
        <div
          id="section-options-add-personal-section"
          style={{...styles.sectionOptionsAddPersonalSection}}
          onClick={()=>{goTo(`create/section/personal/${section_id}`);}}
        >
          Add Personal Section
        </div>
      </React.Fragment>
    );
  };

  const getWorkshopOptions = ()=>{

    return (
      <React.Fragment>
        <div
          id="section-options-suggest-new-achievement"
          style={{...styles.sectionOptionsSuggestNewAchievement}}
          onClick={()=>{goTo(`create/achievement/suggested/${section_id}`);}}
        >
          Suggest New Achievement
        </div>
        <div
          id="section-options-suggest-new-section"
          style={{...styles.sectionOptionsSuggestNewSection}}
          onClick={()=>{goTo(`create/section/suggested/${section_id}`);}}
        >
          Suggest New Section
        </div>
      </React.Fragment>
    );
  };

  const goTo = (workshop_section)=>{
    history(`/achievement-workshop/${workshop_section}`);
    close();
  };

  const close = ()=>{
    dispatcher("REMOVE_MODAL", "SECTION_OPTIONS");
  };

  const loginModal = ()=>{
    dispatcher("ADD_MODAL", {
      name: "SESSION",
      modalProps: {
        login: true,
      }
    });
  };

  const openReportSection = ()=>{

    if (!user_info.uid){
      loginModal();
    } else {
      dispatcher("OPEN_DETAILS", {
        subject: "report",
        collection,
        section_id,
        group_id: sections[`${collection}_sections`][section_id].group_id
      });
      dispatcher("REMOVE_MODAL", "SECTION_OPTIONS");
    }
  };

  const AdminOptions = getAdminOptions();
  const GroupOptions = (collection !== "suggested") && getGroupOptions();
  const PersonalOptions = (collection !== "suggested") && getPersonalOptions();
  const WorkshopOptions = (collection === "global") && getWorkshopOptions();

  return (
    <div id="section-options" style={{...styles.sectionOptions}}>

      <div id="section-options-header-container" style={{...styles.sectionOptionsHeaderContainer}}>
        <div id="section-options-header-spacer" style={{...styles.sectionOptionsHeaderSpacer}}></div>
        <div id="section-options-header" style={{...styles.sectionOptionsHeader}}>Section Options</div>
        <FontAwesomeIcon
          id="section-options-close-button"
          style={{...styles.sectionOptionsCloseButton}}
          icon={faTimes}
          size="2x"
          onClick={close}
        />
      </div>

      {PersonalOptions}

      {GroupOptions}

      {WorkshopOptions}

      {AdminOptions}

      {(collection === "personal") ? (null) : (
        <div
          id="section-options-report-section"
          style={{...styles.sectionOptionsReportSection}}
          onClick={openReportSection}
        >
          Report Section
        </div>
      )}

    </div>
  );
}

const mapState = function(state){
  return {
    user_info: state.sessionReducer.user_info,
    sections: state.sectionReducer,
    achievements: state.achievementReducer,
    groups: state.groupReducer.groups,
  };
};

export default connect(mapState)(SectionOptionsComponent);
