import React       from "react";

import { connect } from "react-redux";

import Achievement from "../achievement/Achievement.js";

import styles      from "../../styles/styles.js";

function FavoriteAchievements(props){

  const {
    user_info,
    viewed_profile,
    viewing_profile,
    achievementReducer
  } = props;

  const getFavoriteAchievements = ()=>{

    const user_id = (viewing_profile) ? (viewed_profile.uid) : (user_info.uid);
    const user_achievements = (viewing_profile) ? (achievementReducer.viewed_profile_achievements) : (achievementReducer.user_achievements);

    return Object.keys(user_achievements).filter((achievement_id)=>{
      const collection = user_achievements[achievement_id].collection;
      const achievement = achievementReducer[collection + "_achievements"][achievement_id];

      const favorited = user_achievements[achievement_id] && user_achievements[achievement_id].favorited;

      return achievement && favorited && !achievement.deleted;
    }).map((achievement_id, key)=>{
      const collection = user_achievements[achievement_id].collection;
      const achievement = achievementReducer[collection + "_achievements"][achievement_id];

      const user_achievement = user_achievements[achievement_id];

      const completed = user_achievement && user_achievement.completed;
      const favorited = user_achievement && user_achievement.favorited;
      const tracking = user_achievement && user_achievement.tracking;

      return (
        <Achievement
          key={`achievement-${key}`}
          achievement={achievement}
          completed={completed}
          favorited={favorited}
          tracking={tracking}
          user_id={user_id}
        />
      );
    });

  };

  const FavoriteContent = getFavoriteAchievements();

  const Content = (FavoriteContent.length) ? (FavoriteContent) : (
    <div id="favorite-achievements-no-favorites-message" style={{...styles.favoriteAchievementsNoFavoritesMessage}}>This is where your favorited achievements will show up</div>
  );

  return Content;
}

const mapState = (state)=>{
  return {
    user_info: state.sessionReducer.user_info,
    viewed_profile: state.sessionReducer.viewed_profile,
    achievementReducer: state.achievementReducer
  };
};

export default connect(mapState)(FavoriteAchievements);
