import dispatcher from "../dispatcher.js";
import apiRequest from "../apiRequest.js";

import moment     from "moment";

const initialState = {
  user_info: {
    first_name: "",
    last_name: "",
    display_name: "",
    avatar: "",
    email: "",
    groups: {}
  },
  viewed_profile: {
    first_name: "",
    last_name: "",
    display_name: "",
    avatar: "",
    email: "",
    groups: {}
  },
  notifications: {},
  details: {},
  reports: {},
  showDetails: false,
  loggedIn: false,
};

export default (state = initialState, action)=>{
  let newState = Object.assign({}, state);

  switch (action.type){

    case "RECEIVE_BASIC_USER_INFO": {//FLAT ACTION.DATA BEING USED

      newState.loggedIn = true;
      newState.user_info = {
        ...state.user_info,
        ...action.data
      };

      return newState;
    }
    case "GET_USER_INFO": {
      const {
        user_id
      } = action.data;

      window.db.collection("users").doc(user_id).get().then((doc)=>{
        const user = doc.data();

        if (user){
          dispatcher("RECEIVE_USER_INFO", {user});

          if (user.groups){
            Object.keys(user.groups).forEach((group_id)=>{
              dispatcher("GET_SINGLE_GROUP", {
                group_id
              });
            });
          }

          dispatcher("CHECK_GROUP_INVITES", {
            email: user.email
          });//TODO will one day need to change this when adding more than just email invites
        }

      }).catch((err)=>{
        console.log(err);
      });

      return state;
    }
    case "RECEIVE_USER_INFO": {
      const {
        user
      } = action.data;

      newState.user_info = {
        ...newState.user_info,
        ...user,
        display_name: user.display_name || user.email,
        groups: user.groups || {}
      };

      return newState;
    }
    case "UPDATE_USER_SETTING": {
      const {
        user_id,
        payload
      } = action.data;

      window.db.collection("users").doc(user_id).update(payload);

      newState.user_info = {
        ...newState.user_info,
        ...payload
      };

      return newState;
    }
    case "GET_VIEWED_PROFILE_INFO": {//FLAT ACTION.DATA BEING USED

      window.db.collection("users").doc(action.data.user_id).get().then((doc)=>{
        const viewed_profile_data = doc.data();

        dispatcher("RECEIVE_VIEWED_PROFILE_INFO", {
          user: {
            ...viewed_profile_data,
            uid: action.data.user_id
          }
        });
        dispatcher("GET_VIEWED_PROFILE_ACHIEVEMENTS", {user_id: action.data.user_id});
      }).catch(()=>{
        dispatcher("ADD_MODAL", {
          name: "CONFIRMATION",
          modalProps: {
            header: "Profile is private",
            message: "Sorry, it looks like this user's profile is marked as private.",
            okButton: true,
            callback: ()=>{
              window.location.href = window.location.origin + "/";
            }
          }
        });
      });

      return state;
    }
    case "RECEIVE_VIEWED_PROFILE_INFO": {
      const {
        user
      } = action.data;

      newState.viewed_profile = {
        uid: user.uid,
        display_name: user.display_name || user.email,
        email: user.email,
        avatar: user.avatar,
      };

      return newState;
    }
    case "ADD_GROUP_TO_USER": {
      const {
        uid,
        group,
      } = action.data;

      const payload = {
        groups: {
          ...newState.user_info.groups,
          [group.group_id]: group
        }
      };

      window.db.collection("users").doc(uid).update(payload);

      newState.user_info.groups[group.group_id] = {
        ...group
      };

      return newState;
    }
    case "REMOVE_GROUP_FROM_CURRENT_USER": {
      const {
        user_id,
        group,
      } = action.data;

      const payload = {
        groups: {
          ...newState.user_info.groups,
        }
      };

      delete payload.groups[group.group_id];

      window.db.collection("users").doc(user_id).update(payload);

      delete newState.user_info.groups[group.group_id];

      return newState;
    }
    case "GET_REPORTS": {

      window.db.collection("reports").where("deleted", "==", false).get().then((snapshot)=>{
        snapshot.docs.forEach((doc)=>{
          const report = {
            ...doc.data(),
            report_id: doc.id
          };

          dispatcher("RECEIVE_REPORT", {report});

        });

      }).catch((err)=>{
        console.log(err);
      });

      return state;
    }
    case "RECEIVE_REPORT": {
      newState.reports = newState.reports || {};
      newState.reports[action.data.report.report_id] = action.data.report;

      return newState;
    }
    case "UPDATE_REPORT": {

      const modified = moment().toISOString();

      const payload = {
        ...action.data.payload,
        modified,
      };

      const id = action.data.report_id;

      window.db.collection("reports").doc(id).update(payload);

      delete newState.reports[action.data.report_id];

      return newState;
    }
    case "OPEN_DETAILS": {
      newState.showDetails = true;
      newState.details = {
        ...action.data
      };

      return newState;
    }
    case "GET_ALL_LINKS": {

      apiRequest({
        collection: `${action.data.collection}_links`,
        operand_one: "user_id",
        operator: "==",
        operand_two: action.data.user_id,
      }).then((snapshot)=>{

        snapshot.docs.forEach((doc)=>{
          const link = {
            ...doc.data(),
            link_id: doc.id
          };

          if (link.link_for === "achievement"){
            dispatcher("RECEIVE_ACHIEVEMENT_LINK", {link});
          } else {
            dispatcher("RECEIVE_SECTION_LINK", {link});
          }
        });

      }).catch((err)=>{
        console.log(err);
      });

      return state;
    }
    case "GET_LINKS": {

      apiRequest({
        collection: `${action.data.collection}_links`,
        operand_one: "related_id",
        operator: "==",
        operand_two: action.data.related_id,
      }, {
        operand_one: "user_id",
        operator: "==",
        operand_two: action.data.user_id,
      }).then((snapshot)=>{

        snapshot.docs.forEach((doc)=>{
          const link = {
            ...doc.data(),
            link_id: doc.id
          };

          if (link.link_for === "achievement"){
            dispatcher("RECEIVE_ACHIEVEMENT_LINK", {link});
          } else {
            dispatcher("RECEIVE_SECTION_LINK", {link});
          }
        });

      }).catch((err)=>{
        console.log(err);
      });

      return state;
    }
    case "CREATE_LINK": {

      const modified = moment().toISOString();

      let payload = {
        created: modified,
        ...action.data.payload,
        deleted: "false",
        modified,
      };

      window.db.collection(`${payload.link_collection}_links`).add(payload).then((docRef)=>{

        payload.link_id = docRef.id;

        dispatcher("RECEIVE_ACHIEVEMENT_LINK", {link: payload});

      }).catch((err)=>{
        console.log(err);
      });

      return state;
    }
    case "UPDATE_LINK": {

      const modified = moment().toISOString();

      const payload = {
        ...action.data.payload,
        modified,
      };

      window.db.collection(`${action.data.payload.link_collection}_links`).doc(action.data.payload.link_id).update(payload).then(()=>{

        dispatcher("RECEIVE_ACHIEVEMENT_LINK", {link: payload});

      }).catch((err)=>{
        console.log(err);
      });


      return state;
    }
    case "SUBMIT_REPORT": {

      const modified = moment().toISOString();

      let payload = {
        created: modified,
        ...action.data.payload,
        deleted: "false",
        modified,
      };

      window.db.collection("reports").add(payload).then(()=>{

        dispatcher("CLOSE_DETAILS");
        dispatcher("ADD_MODAL", {
          name: "CONFIRMATION",
          modalProps: {
            header: "Submitted",
            message: "Thank you for reporting this issue, we will look into it as soon as possible.",
            okButton: true,
          }
        });

      }).catch((err)=>{
        console.log(err);
      });

      return state;
    }
    case "CLOSE_DETAILS": {
      newState.showDetails = false;
      newState.details = {};

      return newState;
    }
    case "GET_NOTIFICATIONS": {

      apiRequest({
        collection: "user_notifications",
        operand_one: "user_id",
        operator: "==",
        operand_two: action.data.user_id,
      }, {
        operand_one: "deleted",
        operator: "==",
        operand_two: "false",
      }).then((snapshot)=>{
        snapshot.docs.forEach((doc)=>{
          const notification = {
            ...doc.data(),
            notification_id: doc.id
          };

          dispatcher("RECEIVE_NOTIFICATION", {notification});

        });

      }).catch((err)=>{
        console.log(err);
      });

      return newState;
    }
    case "RECEIVE_NOTIFICATION": {
      const {
        notification
      } = action.data;

      if (newState.notifications){
        newState.notifications[notification.notification_id] = {
          ...notification
        };
      } else {
        newState.notifications = {
          [notification.notification_id]: {
            ...notification
          }
        };
      }

      return newState;
    }
    case "CREATE_NOTIFICATION": {//TODO move to cloud function

      const modified = moment().toISOString();

      let payload = {
        created: modified,
        ...action.data.payload,
        deleted: "false",
        modified,
      };

      window.db.collection("user_notifications").add(payload).catch((err)=>{
        console.log(err);
      });

      return newState;
    }
    case "UPDATE_NOTIFICATION": {
      const {
        notification_id,
        payload
      } = action.data;

      window.db.collection("user_notifications").doc(notification_id).update(payload);

      if (payload.deleted){
        delete newState.notifications[notification_id];
      } else {
        newState.notifications[notification_id] = {
          ...newState.notifications[notification_id],
          ...payload
        };
      }

      return newState;
    }
    case "FLUSH_VIEWED_PROFILE": {

      newState.viewed_profile = initialState.viewed_profile;

      return newState;
    }
    case "LOGOUT": {

      window.auth.signOut().then(()=>{
        console.log("user logged out");
        window.location.href = window.location.origin;
      });

      return initialState;
    }
    default: {
      return state;
    }
  }
};
