import React     from "react";

import styles    from "../../styles/styles.js";

function GroupMenuItem(props){

  const {
    index,
    group,
    onClick,
  } = props;

  return (
    <div
      className="group-menu-item"
      style={{
        ...styles.groupMenuItem,
        borderTop: (index !== 0) ? ("1px solid black") : ("")
      }}
      onClick={onClick}
    >
      - {group.group_name}
    </div>
  );
}

export default GroupMenuItem;
