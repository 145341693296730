import React                  from "react";

import { connect }            from "react-redux";

import styles                 from "../../styles/styles.js";

import LinkDetailsComponent   from "./LinkDetailsComponent.js";
import NoteDetailsComponent   from "./NoteDetailsComponent.js";
import ReportDetailsComponent from "./ReportDetailsComponent.js";

function DetailsComponent(props){

  const {
    details,
    achievements,
    sections,
  } = props;

  const {
    subject,
    collection,
    achievement_id,
    section_id
  } = details;

  if (!subject){
    return null;
  }

  const achievement = achievement_id && achievements[`${collection}_achievements`][achievement_id];
  const section = section_id && sections[`${collection}_sections`][section_id];

  let Details;
  if (subject === "report"){
    Details = (
      <ReportDetailsComponent achievement={achievement} section={section}/>
    );
  } else {

    let labelBackroundColor = {};
    if (details.collection === "personal"){
      labelBackroundColor = {
        backgroundColor: styles.PersonalPrimaryColor
      };
    } else if (details.collection === "group"){
      labelBackroundColor = {
        backgroundColor: styles.GroupPrimaryColor
      };
    } else if (details.collection === "suggested"){
      labelBackroundColor = {
        backgroundColor: styles.Grey
      };
    }

    Details = (
      <React.Fragment>

        <div style={{...styles.achievementDetailsLabel, ...labelBackroundColor}}>Tools</div>
        <LinkDetailsComponent
          achievement={achievement}
          section={section}
          subject="tools"
        />

        <div style={{...styles.achievementDetailsLabel, ...labelBackroundColor}}>Info</div>
        <LinkDetailsComponent
          achievement={achievement}
          section={section}
          subject="info"
        />

        {(details.collection === "suggested") ? (null) : (
          <React.Fragment>

            <div style={{...styles.achievementDetailsLabel, ...labelBackroundColor}}>Notes</div>
            <NoteDetailsComponent
              achievement={achievement}
              section={section}
            />

          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  return (
    Details
  );
}


const mapState = (state)=>{

  return {
    sections: state.sectionReducer,
    achievements: state.achievementReducer,
    details: state.sessionReducer.details || {},
  };
};

export default connect(mapState)(DetailsComponent);
