import React                from "react";
import ReactDOM             from "react-dom/client";

import { BrowserRouter, Route, Routes } from "react-router-dom";
//Redux stuff
import { Provider }         from "react-redux";
import { store, persistor } from "./redux/store.js";
import { PersistGate }      from "redux-persist/integration/react";
import * as serviceWorker   from "./serviceWorker";

import ErrorBoundary        from "./components/common/ErrorBoundary.js";

import Nav                  from "./nav.js";
import HomePage             from "./components/pages/HomePage.js";

import About                from "./components/pages/AboutPage.js";
import Support              from "./components/pages/SupportPage.js";
import PrivacyPolicyAndTOS  from "./components/pages/PrivacyPolicyAndTOSPage.js";
import Social               from "./components/pages/SocialPage.js";
import Profile              from "./components/pages/ProfilePage.js";
import Reports              from "./components/pages/ReportsPage.js";
import Suggestions          from "./components/pages/SuggestionsPage.js";
import PatchNotes           from "./components/pages/PatchNotesPage.js";
import AchievementWorkshop  from "./components/pages/AchievementWorkshopPage.js";
import CreateAchievement    from "./components/pages/CreateEditAchievementPage.js";
import CreateSection        from "./components/pages/CreateEditSectionPage.js";
import GroupPage            from "./components/pages/GroupPage.js";
import GroupsOverviewPage   from "./components/pages/GroupsOverviewPage.js";
import PersonalAchievements from "./components/pages/PersonalAchievementsPage.js";

import Section              from "./components/pages/SectionPage.js";

import Modal                from "react-modal";

import styles               from "./styles/styles.js";

Modal.setAppElement("#root");

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  (
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <div style={{...styles.body}}>
            <BrowserRouter>
              <div>
                <Nav/>
                <Routes>
                  <Route path="/" element={<HomePage/>}/>
                  <Route path="/?mode=select" element={<HomePage/>}/>

                  <Route path="/achievements/:collection/:section" element={<Section/>}/>

                  <Route path="/personal-achievements" element={<PersonalAchievements/>}/>
                  <Route path="/personal-achievements/:section" element={<PersonalAchievements/>}/>

                  <Route path="/group" element={<GroupsOverviewPage/>}/>
                  <Route path="/group/:group_id" element={<GroupPage/>}/>
                  <Route path="/group/:group_id/achievements/:section" element={<GroupPage/>}/>

                  <Route path="/achievement-workshop" element={<AchievementWorkshop/>}/>

                  <Route path="/achievement-workshop/reports" element={<Reports/>}/>
                  <Route path="/achievement-workshop/suggestions" element={<Suggestions/>}/>

                  <Route path="/achievement-workshop/create/achievement/:collection" element={<CreateAchievement/>}/>
                  <Route path="/achievement-workshop/create/achievement/:collection/:section_id" element={<CreateAchievement/>}/>
                  <Route path="/achievement-workshop/edit/achievement/:collection/:achievement_id" element={<CreateAchievement/>}/>

                  <Route path="/achievement-workshop/create/section/:collection" element={<CreateSection/>}/>
                  <Route path="/achievement-workshop/create/section/:collection/:section_id" element={<CreateSection/>}/>
                  <Route path="/achievement-workshop/edit/section/:collection/:section_id" element={<CreateSection/>}/>

                  <Route path="/social" element={<Social/>}/>
                  <Route path="/profile" element={<Profile/>}/>
                  <Route path="/profile/:uid" element={<Profile/>}/>
                  <Route path="/patch-notes" element={<PatchNotes/>}/>
                  <Route path="/about" element={<About/>}/>
                  <Route path="/support" element={<Support/>}/>
                  <Route path="/privacy-policy-and-terms-of-service" element={<PrivacyPolicyAndTOS/>}/>

                  <Route element={<HomePage/>}/>
                </Routes>
              </div>
            </BrowserRouter>
          </div>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  )
);

serviceWorker.register();
