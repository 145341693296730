import React, { useState, useEffect, useRef } from "react";
import { connect }   from "react-redux";

import styles        from "../../styles/styles.js";

import dispatcher    from "../../redux/dispatcher.js";

// import groupsUtility from "../../functions/groups.js";

// import UpdateAvatar  from "./UpdateAvatar.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
} from "@fortawesome/free-regular-svg-icons";
import {
  faCheckCircle,
  faShareSquare
} from "@fortawesome/free-solid-svg-icons";

function ProfileSettings(props){

  const {
    // groups,
    user_info,
  } = props;

  // const [errors, setErrors] = useState({});
  // const [displayName, setDisplayName] = useState(user_info.display_name);
  // const [changeDisplayNameToggle, setDisplayNameToggle] = useState("FALSE");
  const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);

  const textAreaRef = useRef();

  useEffect(()=>{
    if (showCopiedTooltip === true){
      fadeOutTooltip();
    }
  }, [showCopiedTooltip]);

  // const changeDisplayName = ()=>{
  //   setDisplayNameToggle("TRUE");
  // };

  // const saveDisplayName = ()=>{
  //   // dispatcher("UPDATE_USER_SETTING", {
  //   //   user_id: user_info.uid,
  //   //   payload: {
  //   //     display_name: displayName
  //   //   }
  //   // });
  //   setDisplayNameToggle("SAVED");
  // };

  // const changeAvatar = ()=>{
  //   //open modal for uploading an image
  // };

  const toggleVisibleProfile = ()=>{

    dispatcher("UPDATE_USER_SETTING", {
      user_id: user_info.uid,
      payload: {
        visibility: (user_info.visibility === "private") ? ("public") : ("private")
      }
    });
  };

  const fadeOutTooltip = ()=>{
    let element = document.getElementById("profile-settings-copied-tooltip");

    let op = 1;
    let timer = setInterval(()=>{
      if (op <= 0.1){
        clearInterval(timer);
        element.style.display = "none";
        setShowCopiedTooltip(false);
      }
      element.style.opacity = op;
      element.style.filter = "alpha(opacity=" + op * 100 + ")";
      op -= op * 0.1;
    }, 125);
  };

  const shareProfile = ()=>{
    textAreaRef.current.select();
    document.execCommand("copy");

    setShowCopiedTooltip(true);
  };

  const logout = ()=>{
    dispatcher("LOGOUT");
  };

  // const toggleGroupJoinRequest = ()=>{
  //
  // };

  // const getGroupAdminSettings = ()=>{
  //
  //   const admined_groups = groupsUtility.getAdminedGroups(groups, user_info.uid);
  //
  //   return (
  //     <React.Fragment>
  //
  //       <div style={{...styles.line, borderBottom: 0, margin: 16, marginTop: 0, width: "unset"}}></div>
  //
  //       <div style={{color: styles.White, marginLeft: 16, marginBottom: 16, textAlign: "center"}}>Group Setings</div>
  //
  //       {admined_groups.map((group)=>{
  //
  //         return (
  //           <React.Fragment>
  //
  //             <div style={{...styles.line, borderBottom: 0, margin: 16, marginTop: 0, width: "unset"}}></div>
  //
  //             <div style={{color: styles.White, marginLeft: 16, textAlign: "center"}}>{group.name} Setings</div>
  //
  //             <div style={{color: styles.White, marginLeft: 16, marginTop: 16}}>Receive in-app notifications for:</div>
  //
  //             <div
  //               id="profile-settings-make-private-container"
  //               style={{...styles.profileSettingsMakePrivateContainer, marginLeft: 32}}
  //               onClick={toggleGroupJoinRequest}
  //             >
  //
  //               <div id="profile-settings-make-private-label">
  //                 requests to join your group
  //               </div>
  //
  //               <FontAwesomeIcon
  //                 id="profile-settings-make-private-icon"
  //                 style={{}}
  //                 icon={(user_info.group_request_to_join_notifications === true) ? (faCheckCircle) : (faCircle)}
  //               />
  //             </div>
  //
  //             <div
  //               id="profile-settings-make-private-container"
  //               style={{...styles.profileSettingsMakePrivateContainer, marginLeft: 32, paddingTop: 0}}
  //               onClick={toggleGroupJoinRequest}
  //             >
  //
  //               <div id="profile-settings-make-private-label">
  //                 when a user joins your group
  //               </div>
  //
  //               <FontAwesomeIcon
  //                 id="profile-settings-make-private-icon"
  //                 style={{}}
  //                 icon={(user_info.group_request_to_join_notifications === true) ? (faCheckCircle) : (faCircle)}
  //               />
  //             </div>
  //
  //             <div style={{...styles.line, borderBottom: 0, margin: 16, width: "unset"}}></div>
  //
  //           </React.Fragment>
  //         );
  //       })}
  //     </React.Fragment>
  //   );
  //
  // };

  // const GroupAdminSettings = getGroupAdminSettings();

  return (
    <div id="profile-settings" style={{...styles.profileSettings}}>

      {/*
        <div id="profile-settings-personal-options" style={{...styles.profileSettingsPersonalOptions}}>Personal Options</div>

        {changeDisplayNameComponent}

        <div
        id="profile-settings-change-avatar"
        style={{...styles.profileSettingsChangeAvatarButton}}
        onClick={changeAvatar}
        >
        Change Avatar
        </div>
      */}

      <div id="profile-settings-general-options" style={{...styles.profileSettingsGeneralOptions}}>General Options</div>

      <div
        id="profile-settings-make-private-container"
        style={{...styles.profileSettingsMakePrivateContainer}}
        onClick={toggleVisibleProfile}
      >

        <div id="profile-settings-make-private-label">
          Make Profile Private
        </div>

        <FontAwesomeIcon
          id="profile-settings-make-private-icon"
          style={{}}
          icon={(user_info.visibility === "private") ? (faCheckCircle) : (faCircle)}
        />
      </div>

      <div
        id="profile-settings-share-profile-container"
        style={{...styles.profileSettingsShareProfileContainer}}
        onClick={shareProfile}
      >

        <div id="profile-settings-share-profile-label">
          Share Profile
        </div>

        <FontAwesomeIcon
          id="profile-settings-share-profile-icon"
          style={{}}
          icon={faShareSquare}
        />

        <textarea
          id="profile-settings-share-profile-link"
          readOnly
          style={{opacity: 0, position: "absolute", zIndex: -1}}
          ref={textAreaRef}
          value={`${window.location.origin}/profile/${user_info.uid}`}
        />

        {(showCopiedTooltip && (
          <div id="profile-settings-copied-tooltip" style={{...styles.profileSettingsCopiedTooltip}}>Profile Url copied!</div>
        ))}

      </div>
      {/*
        {GroupAdminSettings}
      */}

      <div
        id="profile-settings-logout-button"
        style={{...styles.profileSettingsLogoutButton}}
        onClick={logout}
      >
        Logout
      </div>
    </div>
  );
}

const mapState = function(state){
  return {
    groups: state.groupReducer.groups,
    user_info: state.sessionReducer.user_info,
    visibility: state.sessionReducer.user_info.visibility
  };
};

export default connect(mapState)(ProfileSettings);
