import React, { useEffect }       from "react";

import { connect }                from "react-redux";

import { useNavigate }            from "react-router-dom";

import dispatcher                 from "../../redux/dispatcher.js";

import SectionCard                from "../section/SectionCard.js";
import SectionCardVotingComponent from "../section/SectionCardVotingComponent.js";
import Achievement                from "../achievement/Achievement.js";
import AchievementVotingComponent from "../achievement/AchievementVotingComponent.tsx";

import styles                     from "../../styles/styles.js";

//achievements could have their section listed below the description.
//build out an area in the side menu as well as a tab or button on the suggested page to store suggestions the user made.
//prompt user to update suggestion after creation
//show a note to the user 'during' creation telling them links and notes will be added after the initial creation
//allow users to search via a searchbar on the suggested page
//Maybe treat suggested achievement links as text since they have not been officially approved?

function SuggestionsPage(props){
  const {
    user_info,
    achievements,
    sections,
  } = props;

  const history = useNavigate();

  useEffect(()=>{
    dispatcher("GET_ALL_SECTIONS", {
      collection: "suggested",
      operand_one: "deleted",
      operator: "==",
      operand_two: "false",
    });
    dispatcher("GET_ALL_ACHIEVEMENTS", {
      collection: "suggested",
      operand_one: "deleted",
      operator: "==",
      operand_two: "false",
    });
  }, []);

  const getAchievementSuggestions = ()=>{

    const suggested_achievements = achievements.suggested_achievements;

    if (!suggested_achievements){
      return;
    }

    const achievementSuggestions = Object.keys(suggested_achievements).filter((achievement_id)=>{
      return (suggested_achievements[achievement_id].deleted !== "true") && (sections.global_sections[suggested_achievements[achievement_id].section]);
    }).sort((a, b)=>{
      const textA = suggested_achievements[a].name.toUpperCase();
      const textB = suggested_achievements[b].name.toUpperCase();

      return (textA < textB) ? (-1) : ((textA > textB) ? (1) : (0));
    }).map((achievement_id)=>{
      const achievement = suggested_achievements[achievement_id];

      let vote_number = 0;
      if (achievement.down_votes){
        vote_number = vote_number - achievement.down_votes.length;
      }
      if (achievement.up_votes){
        vote_number = vote_number + achievement.up_votes.length;
      }

      return (
        <div
          key={`suggested_achievements_${achievement_id}`}
          id={`suggested-achievement-container-${achievement_id}`}
          style={{...styles.suggestedAchievementContainer}}
        >

          <Achievement achievement={achievement}/>

          <AchievementVotingComponent
            achievement={achievement}
            achievement_id={achievement_id}
            user_info={user_info}
            vote_number={vote_number}
          />

        </div>
      );
    });

    return (
      <div style={{...styles.suggestionsPageAchievementsContainer}}>

        <div
          id="achievement-workshop-page-suggest-new-achievement-button"
          style={{...styles.achievementWorkshopPageSuggestNewAchievementButton}}
          onClick={()=>{
            history("/achievement-workshop/create/achievement/suggested");
          }}
        >
          Suggest New Achievement
        </div>

        <div className="suggestions-page-achievements-content" style={{...styles.suggestionsPageAchievementsContent}}>
          <div className="suggestions-page-achievements-label" style={{...styles.suggestionsPageAchievementsLabel}}>Achievements</div>
          {achievementSuggestions}
        </div>

      </div>
    );
  };

  const getSectionSuggestions = ()=>{

    const suggested_sections = sections.suggested_sections;

    if (!suggested_sections){
      return;
    }

    const sectionSuggestions = Object.keys(suggested_sections).filter((section_id)=>{

      return (suggested_sections[section_id].deleted !== "true");
    }).sort((a, b)=>{
      const textA = suggested_sections[a].name.toUpperCase();
      const textB = suggested_sections[b].name.toUpperCase();

      return (textA < textB) ? (-1) : ((textA > textB) ? (1) : (0));
    }).map((section_id)=>{
      const section = suggested_sections[section_id];

      let vote_number = 0;
      if (section.down_votes){
        vote_number = vote_number - section.down_votes.length;
      }
      if (section.up_votes){
        vote_number = vote_number + section.up_votes.length;
      }

      return (
        <div id={`suggested-section-container-${section_id}`} style={{...styles.suggestedSectionContainer}} key={`suggested_section_container_${section_id}`}>

          <SectionCard key={`suggested_section_${section_id}`} section={section}/>

          <SectionCardVotingComponent
            key={`suggested_section_voting_${section_id}`}
            section={section}
            section_id={section_id}
            user_info={user_info}
            vote_number={vote_number}
          />

        </div>
      );
    });

    return (
      <div className="suggestions-page-section-container" style={{...styles.suggestionsPageSectionsContainer}}>

        <div
          id="achievement-workshop-page-suggest-new-section-button"
          style={{...styles.achievementWorkshopPageSuggestNewSectionButton}}
          onClick={()=>{
            history("/achievement-workshop/create/section/suggested");
          }}
        >
          Suggest New Section
        </div>

        <div className="suggestions-page-sections-content" style={{...styles.suggestionsPageSectionsContent}}>
          <div className="suggestions-page-sections-label" style={{...styles.suggestionsPageSectionsLabel}}>Sections</div>
          {sectionSuggestions}
        </div>

      </div>
    );
  };

  const SectionSuggestions     = getSectionSuggestions();
  const AchievementSuggestions = getAchievementSuggestions();

  // <div>Vote on suggested achievements and sections here </div>
  return (
    <div id="suggestions-page" style={{...styles.suggestionsPage}}>

      <div id="suggestions-page-container" style={{...styles.suggestionsPageContainer}}>
        <div id="suggestions-page-header" style={{...styles.suggestionsPageHeader}}>Suggestions</div>

        <div id="suggestions-page-content" style={{...styles.suggestionsPageContent}}>
          {SectionSuggestions}
          {AchievementSuggestions}
        </div>

      </div>

    </div>
  );
}

const mapState = (state)=>{

  const hackToUpdate = Math.random();

  return {
    user_info: state.sessionReducer.user_info,
    achievements: state.achievementReducer,
    sections: state.sectionReducer,
    hackToUpdate,
  };
};

export default connect(mapState)(SuggestionsPage);
