import React          from "react";

import { connect }    from "react-redux";

import dispatcher     from "../../redux/dispatcher.js";

import groupUtility   from "../../functions/groups.js";

import styles         from "../../styles/styles.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faUserShield } from "@fortawesome/free-solid-svg-icons";

function GroupMemberDetails(props){

  const {
    member,//should change to member_id and pull in the current member data directly from redux store
    viewer,
    groups,
    request,
    user_info,
    onRequestClose,
  } = props;

  const deleteMember = ()=>{

    let message;
    if (member.joined){
      message = "This will remove the member from this group, but you can always reinvite them.";
    } else if (request){
      message = "This will remove this person's request, but you can always reinvite them.";
    } else {
      message = "This will remove the invite for this person, but you can always reinvite them.";
    }

    dispatcher("ADD_MODAL", {
      name: "CONFIRMATION",
      modalProps: {
        header: "Are you sure?",
        message,
        okButton: true,
        okButtonText: "DELETE",
        okButtonStyle: {backgroundColor: styles.Red},
        cancelButton: true,
        callback: ()=>{
          dispatcher("DELETE_GROUP_MEMBER", {
            member_id: member.member_id,
            group_id: member.group_id,
            user_id: member.user_id,
            group_name: member.group_name
          });

          const group = groups[member.group_id];
          const member_is_admin = groupUtility.isGroupMemberAdmin(group, member, user_info);

          if (member_is_admin){
            dispatcher("UPDATE_GROUP", {
              admin_edit: "demote",
              group_id: member.group_id,
              user_id: member.user_id,
            });
          }

          onRequestClose();
        }
      }
    });

  };

  const reinviteMember = ()=>{

    dispatcher("REINVITE_GROUP_MEMBER", {
      member_id: member.member_id,
      onSuccess: ()=>{
        dispatcher("ADD_MODAL", {
          name: "CONFIRMATION",
          modalProps: {
            header: "Success",
            message: "Invitation has been resent.",
            okButton: true,
          }
        });
      }
    });

  };

  const approveMember = ()=>{

    dispatcher("JOIN_GROUP", {
      by_admin: true,
      group_id: member.group_id,
      group_name: member.group_name,
      user_id: member.user_id,
      display_name: member.display_name,
      group_invite_id: member.member_id,
      joining_type: "request",
      onSuccess: ()=>{
        dispatcher("REMOVE_MODAL", "GROUP_MEMBER_DETAILS");
        dispatcher("ADD_MODAL", {
          name: "CONFIRMATION",
          modalProps: {
            header: "Success",
            message: "Request has been approved.",
            okButton: true,
          }
        });
      }
    });
  };

  const promoteAdmin = ()=>{

    dispatcher("UPDATE_GROUP", {
      admin_edit: "promote",
      group_id: member.group_id,
      user_id: member.user_id,
      onSuccess: ()=>{
        dispatcher("ADD_MODAL", {
          name: "CONFIRMATION",
          modalProps: {
            header: "Success",
            message: "The member is now an admin for this group. This gives them all the same permissions and abilities as you except they cannot delete the group or demote / remove the creator of the group.",
            okButton: true,
          }
        });

        dispatcher("CREATE_NOTIFICATION", {
          payload: {
            user_id: member.user_id,
            message: `You've been promoted to an admin of ${member.group_name}!`,
            subject: "group_admin_promotion",
            image_url: "",//TODO will need updating once unique images are sorted for notifications / achievements
            url: `/group/${member.group_id}`,
          }
        });
      }
    });
  };

  const demoteAdmin = ()=>{

    dispatcher("UPDATE_GROUP", {
      admin_edit: "demote",
      group_id: member.group_id,
      user_id: member.user_id,
      onSuccess: ()=>{
        dispatcher("ADD_MODAL", {
          name: "CONFIRMATION",
          modalProps: {
            header: "Success",
            message: "Member is no longer an admin for this group.",
            okButton: true,
          }
        });

        dispatcher("CREATE_NOTIFICATION", {
          payload: {
            user_id: member.user_id,
            message: `You've been demoted to a regular member of ${member.group_name}.`,
            subject: "group_admin_demotion",
            image_url: "",//TODO will need updating once unique images are sorted for notifications / achievements
            url: `/group/${member.group_id}`,
          }
        });
      }
    });
  };

  const joined = (member.status === "joined");

  const name = (viewer === "admin" && member.status !== "joined" && !request) ? (member.invitation_sent_to) : (member.display_name);
  const group = groups[member.group_id];
  const member_is_creator = group.creator === member.invitation_sent_to;
  const member_is_admin = member_is_creator || groupUtility.isGroupMemberAdmin(group, member);
  const member_is_viewer = user_info.uid === member.user_id;

  return (
    <div className="group-member-details" style={{...styles.groupMemberDetails}}>

      <div className="group-member-details-header-container" style={{...styles.groupMemberDetailsHeaderContainer}}>

        <div className="group-member-details-header-spacer" style={{...styles.groupMemberDetailsHeaderSpacer}}></div>

        <div className="group-member-details-header" style={{...styles.groupMemberDetailsHeader}}>
          {(request) ? ("Request Details") : ("Member Details")}
        </div>

        <FontAwesomeIcon
          className="group-member-details-close-icon"
          style={{...styles.groupMemberDetailsCloseIcon}}
          icon={faTimes}
          size="2x"
          onClick={onRequestClose}
        />

      </div>

      <div className="group-member-details-name-container" style={{...styles.groupMemberDetailsNameContainer}}>
        {(joined) ? (
          <div className="group-member-details-name-label" style={{...styles.groupMemberDetailsNameLabel}}>Member Name: </div>
        ) : (
          (request) ? (
            <div className="group-member-details-requester-name-label" style={{...styles.groupMemberDetailsRequesterNameLabel}}>Name: </div>
          ) : (
            <div className="group-member-details-invitee-name-label" style={{...styles.groupMemberDetailsInviteeNameLabel}}>Invite Sent To: </div>
          )
        )}
        <div className="group-member-details-name" style={{...styles.groupMemberDetailsName}}>
          {(member_is_admin) ? (
            <FontAwesomeIcon
              className="group-member-details-admin-icon"
              style={{...styles.groupMemberDetailsAdminIcon}}
              icon={faUserShield}
            />
          ) : (null)}
          {name}
        </div>
      </div>

      {(!joined) ? (
        <div className="group-member-details-status-container" style={{...styles.groupMemberDetailsStatuscontainer}}>
          <div className="group-member-details-status-label" style={{...styles.groupMemberDetailsStatusLabel}}>Invite Status:</div>
          <div className="group-member-details-status" style={{...styles.groupMemberDetailsStatus}}>{member.status}</div>
        </div>
      ) : (null)}

      {(viewer === "admin" && !member_is_creator && !member_is_viewer) ? (
        <div className="group-member-details-buttons-container" style={{...styles.groupMemberDetailsButtonsContainer}}>
          {(request) ? (
            <div
              className="group-member-details-reject-button"
              style={{...styles.groupMemberDetailsRejectButton}}
              onClick={deleteMember}
            >
              Reject
            </div>
          ) : (
            <div
              className="group-member-details-delete-button"
              style={{...styles.groupMemberDetailsDeleteButton}}
              onClick={deleteMember}
            >
              Delete
            </div>
          )}

          {(!joined) ? (
            (request) ? (
              <div
                className="group-member-details-approve-button"
                style={{...styles.groupMemberDetailsApproveButton}}
                onClick={approveMember}
              >
                Approve
              </div>
            ) : (
              <div
                className="group-member-details-reinvite-button"
                style={{...styles.groupMemberDetailsReinviteButton}}
                onClick={reinviteMember}
              >
                Reinvite
              </div>
            )
          ) : (
            (member_is_admin) ? (
              <div
                className="group-member-details-demote-button"
                style={{...styles.groupMemberDetailsDemoteButton}}
                onClick={demoteAdmin}
              >
                Demote Admin
              </div>
            ) : (
              <div
                className="group-member-details-promote-button"
                style={{...styles.groupMemberDetailsPromoteButton}}
                onClick={promoteAdmin}
              >
                Make Admin
              </div>
            )
          )}

        </div>
      ) : (null)}
    </div>
  );
}

const mapState = (state)=>{

  return {
    groups: state.groupReducer.groups,
    user_info: state.sessionReducer.user_info,
  };
};

export default connect(mapState)(GroupMemberDetails);
