module.exports = {

  groupMemberStatus: function(user_info, admin, group_id){

    let group_member_status;
    if (admin){
      group_member_status = "admin";
    } else if (user_info.groups && !user_info.groups[group_id]){
      group_member_status = "non-member";
    } else {
      group_member_status = "member";
    }

    return group_member_status;
  },

  isGroupMemberAdmin: function(group, member){
    return group.admins.includes(member.user_id);
  },

  getAdminedGroups: function(groups, user_id){

    let admined_groups = [];
    if (groups && Object.keys(groups).length){

      Object.keys(groups).forEach((group_id)=>{

        if (groups[group_id].admins.includes(user_id)){
          admined_groups.push(groups[group_id]);
        }

      });

    }

    return admined_groups;

  },

};
