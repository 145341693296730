import React               from "react";

import dispatcher          from "../../redux/dispatcher.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleUp,
  faAngleDown,
}  from "@fortawesome/free-solid-svg-icons";

import styles    from "../../styles/styles.js";

function SectionCardVotingComponent(props){

  const {
    section,
    section_id,
    user_info,
    vote_number
  } = props;

  const loginModal = ()=>{
    dispatcher("ADD_MODAL", {
      name: "SESSION",
      modalProps: {
        login: true,
      }
    });
  };

  const sectionVote = (section_id, vote_type)=>{

    if (!user_info.uid){
      loginModal();
    } else {
      dispatcher("EDIT_SECTION", {
        collection: "suggested",
        payload: {},
        vote_type,
        section_id,
        user_id: user_info.uid,
      });
    }

  };

  return (
    <div id={`suggested-section-voting-container-${section_id}`} style={{...styles.suggestedSectionVotingContainer}}>

      <div
        id={`suggested-section-vote-up-${section_id}`}
        style={{}}
        onClick={()=>{
          if (section.down_votes && section.down_votes.includes(user_info.uid)){
            sectionVote(section.section_id, "down_votes");
          }

          sectionVote(section.section_id, "up_votes");
        }}
      >
        <FontAwesomeIcon
          id={`suggested-section-vote-up-arrow-${section_id}`}
          style={{
            ...styles.Pointer,
            color: (section.up_votes && section.up_votes.includes(user_info.uid)) ? ("#7dbd81") : ("black"),
            opacity: (section.up_votes && section.up_votes.includes(user_info.uid)) ? (0.66) : (1)
          }}
          size="2x"
          icon={faAngleUp}
        />
      </div>

      {vote_number}

      <div
        id={`suggested-section-vote-down-${section_id}`}
        style={{}}
        onClick={()=>{
          if (section.up_votes && section.up_votes.includes(user_info.uid)){
            sectionVote(section.section_id, "up_votes");
          }

          sectionVote(section.section_id, "down_votes");
        }}
      >
        <FontAwesomeIcon
          id={`suggested-section-vote-down-arrow-${section_id}`}
          style={{
            ...styles.Pointer,
            color: (section.down_votes && section.down_votes.includes(user_info.uid)) ? (styles.Red) : ("black"),
            opacity: (section.down_votes && section.down_votes.includes(user_info.uid)) ? (0.66) : (1),
          }}
          size="2x"
          icon={faAngleDown}
        />
      </div>

    </div>
  );
}

export default SectionCardVotingComponent;
