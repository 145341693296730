import React               from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy }        from "@fortawesome/free-solid-svg-icons";

import styles              from "../../styles/styles.js";

interface Props {
  achievement: {
    name: string,
    stars: number
  },
  achievementNameStyle: {},
  achievementButtonStyle: {},
  completed: boolean
}

const achievementTopBackground: React.CSSProperties = {
  ...styles.achievementTopBackground,
  position: "absolute",
}

const AchievementTopContainer: React.FC<Props> = (props)=>{

  const {
    achievement,
    achievementNameStyle,
    achievementButtonStyle,
    completed
  } = props;

  const achievementIconStyle = {...achievementButtonStyle, ...styles.achievementIcon,  opacity: (completed) ? (1) : (0.33)};

  const achievementNameAltStyle = {...achievementNameStyle, borderBottom: "1px solid #f2ead3"};

  const achievementPointsStyle = {...achievementButtonStyle, ...styles.achievementPoints, opacity: (completed) ? (1) : (0.33), cursor: "unset"};

  return (
    <div className="achievement-top-container" style={{...styles.achievementTopContainer}}>

      <div className="achievement-top-background" style={{...achievementTopBackground}}></div>

      <div className="achievement-icon-name-container" style={{...styles.achievementIconNameContainer}}>

        <div className="achievement-icon-container" style={{...styles.achievementIconContainer}}>
          <FontAwesomeIcon
            className="achievement-icon"
            style={achievementIconStyle}
            size="2x"
            icon={faTrophy}
          />
        </div>

        <div className="achievement-name" style={achievementNameAltStyle}>
          {achievement.name}
        </div>

        <div className="achievement-points" style={achievementPointsStyle}>
          {achievement.stars}
        </div>
      </div>
    </div>
  );

}

export default AchievementTopContainer;
