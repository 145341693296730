module.exports = {

  upperCase: function(string){
    return string.split(" ").map((word)=>{
      if (word === "and"){
        return word;
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
    }).join(" ");
  },

  isMobile: function(){
    // const height = window.screen.height;
    // const width = window.screen.width;

    return (window.screen.width <= 800);
  },

};
