import moment     from "moment";

import dispatcher from "../dispatcher.js";
import apiRequest from "../apiRequest.js";

const initialState = {
  global_sections: {},
  group_sections: {},
  personal_sections: {},
  suggested_sections: {},
  user_sections: {},
  first_load: true,
  loading: true,
};

//remember to pay attention to the achievements_loaded flag that only exists on the frontend.

export default (state = initialState, action = {data: {}})=>{
  let newState = Object.assign({}, state);
  action.data = action.data || {};
  const {
    collection,
    section_id,
  } = action.data;
  const section_collection = `${collection}_sections`;
  //to get the section, I need to know the ids
  //global sections have the children section ids stored on itself, which is a shortcut, but personal / group section ids can't be stored here. Instead, they need to be queried from firebase with a where() with the user or group id. This will not be able to fit into the same redux query

  //
  switch (action.type){

    case "GET_SINGLE_SECTION": {

      apiRequest({
        collection: section_collection,
        id: section_id
      }).then((doc)=>{

        if (doc.exists){
          dispatcher("RECEIVE_SINGLE_SECTION", {
            collection,
            section_id: doc.id,
            section: {
              ...doc.data(),
              ...{
                section_id: doc.id,
                achievements_loaded: true,
              }
            }
          });
        }

      });

      return state;
    }
    case "GET_SECTION_AND_CHILDREN": {

      apiRequest({
        collection: section_collection,
        id: section_id
      }).then((doc)=>{

        const section = doc.data();

        if (section){
          dispatcher("RECEIVE_SINGLE_SECTION", {
            collection,
            section,
            section_id: doc.id
          });

          apiRequest({
            collection: section_collection,
            operand_one: "parent",
            operator: "==",
            operand_two: section_id,
          }).then((snapshot)=>{

            snapshot.docs.forEach((doc)=>{

              dispatcher("RECEIVE_SINGLE_SECTION", {
                collection,
                section: doc.data(),
                section_id: doc.id
              });

            });

          });

          if (section.achievements){

            section.achievements.forEach((achievement_id)=>{//EDIT to query firestore for this instead of

              dispatcher("GET_SINGLE_ACHIEVEMENT", {
                collection,
                achievement_id
              });

            });

          }
        } else {
          dispatcher("ADD_MODAL", {
            name: "SECTION_NOT_FOUND",
            modalProps: {
              header: "Section Not Found",
              message: "It doesn't look like this section exists.",
              okButton: true,
            }
          });
        }

      });

      return state;
    }
    case "GET_ALL_SECTIONS": {
      const {
        operand_one,
        operator,
        operand_two,
      } = action.data;

      apiRequest({
        collection: section_collection,
        operand_one,
        operator,
        operand_two
      }).then((snapshot)=>{
        let sections = {};

        snapshot.docs.forEach((section)=>{

          sections[section.id] = {
            ...section.data(),
            section_id: section.id,
            achievements_loaded: false,
          };

        });

        dispatcher("RECEIVE_SECTIONS", {
          collection,
          sections
        });
      }).catch((err)=>{
        console.log(err);
      });

      return state;
    }
    case "RECEIVE_SECTIONS": {
      const {
        sections
      } = action.data;

      // newState[section_collection] = sections;
      newState[section_collection] = {
        ...newState[section_collection],
        ...sections
      };
      newState.first_load = false;
      newState.loading = false;

      return newState;
    }
    case "RECEIVE_SINGLE_SECTION": {
      const {
        section,
        achievements_loaded
      } = action.data;

      if (newState[section_collection]){
        newState[section_collection][section_id] = {
          ...section,
          section_id,
        };
      } else {
        newState[section_collection] = {
          [section_id]: {
            ...section,
            section_id,
          }
        };
      }

      newState[section_collection][section_id].achievements_loaded = (achievements_loaded) ? (true) : (false);

      newState.loading = false;

      return newState;
    }
    case "GET_USER_SECTIONS": {
      const {
        user_id
      } = action.data;

      apiRequest({
        collection: "user_sections",
        operand_one: "user_id",
        operator: "==",
        operand_two: user_id
      }).then((snapshot)=>{

        snapshot.docs.forEach((doc)=>{

          dispatcher("RECEIVE_SINGLE_USER_SECTION", {
            user_section: doc.data(),
          });

        });

      }).catch((err)=>{
        console.log(err);
      });

      return state;
    }
    case "RECEIVE_SINGLE_USER_SECTION": {
      const {
        user_section,
      } = action.data;

      newState.user_sections[user_section.section_id] = {
        ...newState.user_sections[user_section.section_id],
        ...user_section
      };

      return newState;
    }
    case "SECTION_CHECK": {
      const {
        section_modified,
      } = action.data;

      apiRequest({
        collection: section_collection,
        id: section_id
      }).then((doc)=>{
        const section = doc.data();

        if (section.modified !== section_modified){//checking if section has been modified

          if (section.children){

            section.children.forEach((child_id)=>{

              dispatcher("GET_SINGLE_SECTION", {
                collection,
                section_id: child_id
              });

            });

          }

          if (section.achievements){

            section.achievements.forEach((achievement_id)=>{

              dispatcher("GET_SINGLE_ACHIEVEMENT", {
                collection,
                achievement_id
              });

            });

          }

        } else if (!state[section_collection][section_id].achievements_loaded){

          if (section.achievements){//really, every section should have achievements, but just in case

            section.achievements.forEach((achievement_id)=>{

              dispatcher("GET_SINGLE_ACHIEVEMENT", {
                collection,
                achievement_id
              });

            });

          }
        }

        dispatcher("RECEIVE_SINGLE_SECTION", {
          collection,
          section_id: doc.id,
          section,
          achievements_loaded: true
        });

      });

      newState.loading = false;

      return newState;
    }
    case "CREATE_SECTION": {//not keeping track of achievements array of section
      const {
        achievement_payload
      } = action.data;

      const modified = moment().toISOString();
      const version = (action.data.section_payload.version) ? (action.data.section_payload.version + 1) : (1);

      let section_payload = {
        created: modified,
        ...action.data.section_payload,
        deleted: "false",
        version,
        modified,
      };

      window.db.collection(section_collection).add(section_payload).then((docRef)=>{

        section_payload.section_id = docRef.id;
        section_payload.achievements_loaded = false;

        dispatcher("RECEIVE_SINGLE_SECTION", {
          collection,
          section_id: docRef.id,
          section: section_payload
        });

        if (collection === "global"){
          dispatcher("UPDATE_PARENT_SECTION", {
            collection,
            type: "CREATE",
            child_id: docRef.id,
            section_id: section_payload.parent,
            modified
          });
        }

        if (section_payload.approved_admin){
          dispatcher("REMOVE_MODAL", "SECTION_OPTIONS");
          dispatcher("DELETE_SECTION", {
            collection: "suggested",
            section_id: section_payload.suggested_section_id,
            parent: section_payload.parent,
            onSuccess: ()=>{
              //maybe leaving empty?
            }
          });
          dispatcher("CREATE_NOTIFICATION", {
            payload: {
              user_id: section_payload.suggested_user_id,
              message: `Your suggested section, ${section_payload.name}, has been approved!`,
              subject: "suggestion_section_approval",
              image_url: "",//TODO will need updating once unique images are sorted for notifications / achievements
              url: `/achievements/global/${docRef.id}`,
            }
          });
        }

        let achievements_submitted = false;

        Object.keys(achievement_payload.achievements).map((key)=>{

          const achievement = achievement_payload.achievements[key];

          if (achievement.name !== "" && achievement.description !== ""){//relying on achievement creation to push user to next page. need to fix

            achievements_submitted = true;

            let payload = {
              ...achievement,
              section: docRef.id,
            };

            if (achievement_payload.suggesting_user_id){
              payload.suggesting_user_id = achievement_payload.suggesting_user_id;
              payload.up_votes = [achievement_payload.suggesting_user_id];
              payload.down_votes = [];
            }

            if (achievement_payload.group_id){
              payload.group_id = achievement_payload.group_id;
            } else {
              delete payload.group_id;
            }

            if (achievement_payload.user_id){
              payload.user_id = achievement_payload.user_id;
            }

            dispatcher("CREATE_ACHIEVEMENT", {
              collection,
              payload,
              tools: action.data.tools,
              info: action.data.info,
              callback: action.data.callback
            });
          }

        });

        if (!achievements_submitted){

          let message;
          if (achievement_payload.suggesting_user_id){
            message = "Your suggestion has been successfully submitted! Other users can now vote on your suggestion.";
          } else {
            message = "The new section has been successfully created.";
          }

          dispatcher("ADD_MODAL", {
            name: "CONFIRMATION",
            modalProps: {
              header: "Success!",
              message,
              okButton: true,
              callback: ()=>{

                if (action.data.callback){
                  action.data.callback();
                } else {
                  window.location.href = window.location.origin + "/achievement-workshop";
                }
              }

            }
          });

        }

      });

      return state;
    }
    case "EDIT_SECTION": {
      const {
        user_id,
        vote_type,
        section_id,
        admin_edit,
      } = action.data;

      const modified = moment().toISOString();
      const version = (action.data.payload.version) ? (action.data.payload.version + 1) : (1);

      let payload = {
        ...action.data.payload,
        version,
        modified,
      };

      const original_parent = newState[section_collection][section_id].parent;

      const parent_updated = (payload.parent !== original_parent);

      delete payload.achievements_loaded;

      let received_section = {...payload};
      if (vote_type){//for suggested section voting
        if (
          (vote_type === "up_votes" && newState.suggested_sections[section_id].up_votes.includes(user_id))
          ||
          (vote_type === "down_votes" && newState.suggested_sections[section_id].down_votes.includes(user_id))
        ){
          received_section[vote_type] = [];
          newState[section_collection][section_id][vote_type].forEach((uid)=>{
            if (uid !== user_id){
              received_section[vote_type].push(uid);
            }
          });

          payload[vote_type] = window.firebase.firestore.FieldValue.arrayRemove(user_id);
        } else {
          received_section[vote_type] = [...newState[section_collection][section_id][vote_type]];
          received_section[vote_type].push(user_id);

          payload[vote_type] = window.firebase.firestore.FieldValue.arrayUnion(user_id);
        }
      }

      window.db.collection(section_collection).doc(section_id).update(payload).then(()=>{

        payload = {
          ...newState[section_collection][section_id],
          ...received_section
        };

        dispatcher("RECEIVE_SINGLE_SECTION", {
          collection,
          section: payload,
          section_id
        });

        // if (collection !== "suggested"){
          dispatcher("ADD_MODAL", {
            name: "CONFIRMATION",
            modalProps: {
              header: "Success!",
              message: "The section has been successfully updated.",
              okButton: true,
              callback: ()=>{
                if (action.data.callback){
                  action.data.callback();
                } else {
                  window.location.href = window.location.origin + "/achievement-workshop";
                }
              }
            }
          });
        // }

        if (collection === "global"){

          dispatcher("UPDATE_PARENT_SECTION", {
            collection,
            type: "EDIT",
            child_id: (parent_updated) ? (section_id) : (null),
            section_id: payload.parent,
            modified
          });

          if (parent_updated){
            dispatcher("UPDATE_PARENT_SECTION", {
              collection,
              type: "DELETE",
              child_id: section_id,
              section_id: original_parent,
              modified,
            });
          }

        }

        if (collection === "suggested" && admin_edit){
          dispatcher("CREATE_NOTIFICATION", {
            payload: {
              user_id: payload.suggesting_user_id,
              message: `Your suggested section, ${payload.name}, has been edited by an admin.`,
              subject: "suggestion_section_change",
              image_url: "",//TODO will need updating once unique images are sorted for notifications / achievements
              url: `/achievements/suggested/${section_id}`,
            }
          });
        }

      });

      return state;
    }
    case "UPDATE_PARENT_SECTION": {//seems a little messy, maybe figure out a better way
      const {
        type,
        modified,
        achievement_id,
        child_id
      } = action.data;

      let payload = {
        modified
      };

      if (section_collection === "personal" || section_collection === "group"){
        return newState;
      }

      const section = newState[section_collection][section_id];
      //because collection coming in as personal section even though id is for a global section
      section.modified = modified;

      if (type === "CREATE"){
        if (achievement_id){//CREATING ACHIEVEMENT

          payload.achievements = window.firebase.firestore.FieldValue.arrayUnion(achievement_id);

          if (section.achievements){//TODO fix not all sections having childred and achievements arrays
            section.achievements.push(achievement_id);
          } else {
            section.achievements = [achievement_id];
          }

        } else if (child_id){//CREATING SECTION

          payload.children = window.firebase.firestore.FieldValue.arrayUnion(child_id);

          if (section.children){//TODO fix not all sections having childred and achievements arrays
            section.children.push(child_id);
          } else {
            section.children = [child_id];
          }

        }
      } else if (type === "EDIT"){
        if (achievement_id){//EDITING ACHIEVEMENT AND UPDATED IT'S SECTION

          payload.achievements = window.firebase.firestore.FieldValue.arrayUnion(achievement_id);

          if (section.achievements){//TODO fix not all sections having childred and achievements arrays
            section.achievements.push(achievement_id);
          } else {
            section.achievements = [achievement_id];
          }

        } else if (child_id){//EDITING SECTION AND UPDATED IT'S PARENT

          payload.children = window.firebase.firestore.FieldValue.arrayUnion(child_id);

          if (section.children){//TODO fix not all sections having childred and achievements arrays
            section.children.push(child_id);
          } else {
            section.children = [child_id];
          }
        }
      } else if (type === "DELETE"){

        if (achievement_id){//DELETING or EDITING ACHIEVEMENT'S SECTION
          payload.achievements = window.firebase.firestore.FieldValue.arrayRemove(achievement_id);

          const index = section.achievements.indexOf(achievement_id);
          if (index > -1){
            section.achievements.splice(index, 1);
          }

        } else if (child_id){//DELETING or EDITING SECTION'S PARENT
          payload.children = window.firebase.firestore.FieldValue.arrayRemove(child_id);

          const index = section.children.indexOf(child_id);
          if (index > -1){
            section.children.splice(index, 1);
          }

        }
      }

      window.db.collection(section_collection).doc(section_id).update(payload);

      return newState;
    }
    case "TOGGLE_SECTION_BOOKMARK": {

      const modified = moment().toISOString();

      const payload = {
        created: modified,
        ...action.data.payload,
        modified,
      };

      const id = `${payload.user_id}-${section_collection}-${section_id}`;//userID-collection-sectionID

      window.db.collection("user_sections").doc(id).set(payload).then(()=>{

        dispatcher("RECEIVE_SINGLE_USER_SECTION", {
          user_section: payload
        });

      });

      return state;

    }
    case "DELETE_SECTION": {
      const {
        parent,
        onSuccess,
        admin_edit,
        section_name,
        suggesting_user_id
      } = action.data;

      const modified = moment().toISOString();

      let payload = {
        deleted: "true",
        modified,
      };

      const section  = newState[section_collection][section_id];

      window.db.collection(section_collection).doc(section_id).update(payload).then(()=>{

        if (section?.achievements?.length){

          section.achievements.forEach((achievement_id)=>{

            dispatcher("DELETE_ACHIEVEMENT", {
              collection,
              achievement_id,
            });

          });

        }

        if (collection === "global"){
          dispatcher("UPDATE_PARENT_SECTION", {
            collection,
            type: "DELETE",
            child_id: section_id,
            section_id: parent,
            modified
          });
        }

        if (collection === "suggested" && admin_edit){
          dispatcher("CREATE_NOTIFICATION", {
            payload: {
              user_id: suggesting_user_id,
              message: `Your suggested section, ${section_name}, has been denied.`,
              subject: "suggestion_section_denial",
              image_url: "",//TODO will need updating once unique images are sorted for notifications / achievements
              url: "",
            }
          });
        }

        dispatcher("ADD_MODAL", {
          name: "CONFIRMATION",
          modalProps: {
            header: "Success!",
            message: "The section has been successfully deleted.",
            okButton: true,
            callback: ()=>{
              onSuccess();
            }
          }
        });

      });

      newState[section_collection][section_id] = {
        ...section,
        ...payload
      };

      return newState;
    }
    case "DELETE_SECTION_FROM_REDUX": {

      delete newState[section_collection][section_id];
      newState.loading = false;

      return newState;
    }
    case "FLUSH_GLOBAL_SECTIONS": {

      newState.global_sections = {};

      return newState;
    }
    case "FLUSH_STATE": {
      return initialState;
    }
    case "LOGOUT": {

      newState.user_sections = {};
      newState.group_sections = {};
      newState.personal_sections = {};

      return newState;
    }
    default: {
      return state;
    }
  }
};
