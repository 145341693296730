import React, { useState, useEffect } from "react";
import { connect }   from "react-redux";

import { useParams, useNavigate } from "react-router-dom";

import Select        from "react-select";

import styles        from "../../styles/styles.js";

import dispatcher    from "../../redux/dispatcher.js";

import utility       from "../../functions/utility.js";
import groupsUtility from "../../functions/groups.js";

import CreateEditAchievementPage from "./CreateEditAchievementPage.js";

function CreateEditSectionPage(props){

  const {
    groups,
    user_info,
    sections,
  } = props;

  const params = useParams();
  const history = useNavigate();

  const [errors, setErrors] = useState({});
  const [editing, setEditing] = useState();
  const [styleColor, setStyleColor] = useState({});
  const [admined_groups, setAdminedGroups] = useState([]);
  const [default_name, setDefaultName] = useState();
  const [achievements, setAchievements] = useState([]);
  const [group_id, setGroupId] = useState();
  const [section, setSection] = useState({
    section_name: "",
    section_parent: params.section_id || "",
  });

  useEffect(()=>{

    const collection = params.collection;
    const editing_param  = window.location.href.includes("edit");

    const section_id = params.section_id;
    const section_collection = params.collection + "_sections";

    const admined_groups = (params.collection === "group") ? (groupsUtility.getAdminedGroups(groups, user_info.uid)) : ([]);

    let initial_default_parent_id;
    if (editing_param){
      default_parent_id = sections[collection + "_sections"][params.section_id].parent;
    } else {
      default_parent_id = params.section_id;
    }

    //Because what collection the parent is coming from is not specified.
    const global_section   = sections.global_sections[initial_default_parent_id];
    const group_section    = sections.group_sections[initial_default_parent_id];
    const personal_section = sections.personal_sections[initial_default_parent_id];

    let initial_default_name;
    if (global_section){
      initial_default_name = global_section.name;
    } else if (group_section){
      initial_default_name = group_section.name;
    } else if (personal_section){
      initial_default_name = personal_section.name;
    }

    let styleColor;
    if (collection === "group"){
      styleColor = styles.GroupPrimaryColor;
    } else if (collection === "personal"){
      styleColor = styles.PersonalPrimaryColor;
    } else if (collection === "suggested"){
      styleColor = styles.Grey;
    } else {
      styleColor = styles.SecondaryColorMedium;
    }

    if (editing_param && sections[section_collection][section_id]){

      const global_section = sections.global_sections[params.section_id];
      const group_section = sections.group_sections[params.section_id];
      const personal_section = sections.personal_sections[params.section_id];

      let section_parent;
      if (global_section){
        section_parent = global_section.parent;
      } else if (group_section){
        section_parent = group_section.parent;
      } else if (personal_section){
        section_parent = personal_section.parent;
      }

      setSection({
        ...props.sections[section_collection][section_id],
        section_name: props.sections[section_collection][section_id].name,
        section_parent
      });

    } else {

      let group_id_param = window.localStorage.getItem("group_id");
      window.localStorage.removeItem("group_id");

      if (!group_id_param){
        group_id_param = admined_groups[0] && admined_groups[0].group_id;
      }

      setSection({
        section_name: "",
        section_parent: params.section_id || "",
      });
      setGroupId(group_id);
    }

    setAdminedGroups(admined_groups);
    setEditing(editing);
    setStyleColor(styleColor);
    setDefaultName(initial_default_name);

  }, []);

  const determineSectionCollection = (section_id)=>{

    const global_section   = sections.global_sections[section_id];
    const group_section    = sections.group_sections[section_id];
    const personal_section = sections.personal_sections[section_id];

    let section_collection;

    if (global_section){
      section_collection = "global_sections";
    } else if (group_section){
      section_collection = "group_sections";
    } else if (personal_section){
      section_collection = "personal_sections";
    }

    return section_collection;
  };

  const onChangeGroupId = (e)=>{
    if (e !== undefined){
      setGroupId(e.target.value);
    }
  };

  const onChangeSectionName = (e)=>{

    let state = {
      ...section
    };
    if (e !== undefined){
      state[e.target.name] = e.target.value;
      setSection(state);
    }

  };

  const onAchievementChange = (new_achievements)=>{
    setAchievements({
      ...new_achievements.achievements
    });
  };

  const validate = ()=>{//UPDATE or achievement inclusion
    const {
      section_name,
      section_parent,
    } = section;

    let new_errors = {};

    if (section_name === ""){
      // if (Object.keys(errors).length === 0){
      //   this.name_ref.current.focus();
      // }
      new_errors.section_name = "Please fill out the name field";
    }

    if (section_parent === ""){
      // if (Object.keys(errors).length === 0){
      //   this.section_ref.current.focus();
      // }
      // errors.parent_section = "Please choose the parent section";
    }

    if (!params.section_id){//HERE need to iterate over object and check each field
      // if (Object.keys(errors).length === 0){
      //   this.section_ref.current.focus();
      // }
      // errors.section = "Please fill out the name field";
    }

    Object.keys(achievements).forEach((key)=>{

      const achievement = achievements[key];

      if (achievement.name !== "" || achievement.description !== ""){

        if (achievement.name === ""){
          // if (Object.keys(errors).length === 0){
          //   this.name_ref.current.focus();
          // }
          if (new_errors[key]){
            new_errors[key] = {
              ...new_errors[key],
              name: "Please fill out the name field"
            };
          } else {
            new_errors[key] = {
              name: "Please fill out the name field"
            };
          }
        }

        if (achievement.description === ""){
          if (new_errors[key]){
            new_errors[key] = {
              ...new_errors[key],
              description: "Please fill out the description of the achievement"
            };
          } else {
            new_errors[key] = {
              description: "Please fill out the description of the achievement"
            };
          }
        }

      }

    });

    return new_errors;

  };

  const onSubmit = ()=>{
    const new_errors = validate();
    if (Object.keys(new_errors).length !== 0){
      setErrors(new_errors);
      return;
    }

    const collection = params.collection;
    const user_id = (collection === "personal") ? (user_info.uid) : (null);

    if (editing){

      const payload = {
        name: section.section_name,
        parent: section.parent,
        section_id: params.section_id,
      };

      let admin_edit;
      if (section.suggesting_user_id){
        admin_edit = (user_info.uid !== section.suggesting_user_id);
      }

      dispatcher("EDIT_SECTION", {
        collection,
        payload,
        section_id: params.section_id,
        admin_edit,
        callback: ()=>{
          history(-1, {replace: true});
        }
      });

    } else {

      let section_payload = {
        name: section.section_name,
        parent: (section.section_parent === "") ? (null) : (section.section_parent),
        user_id,
        group_id: (group_id) ? (group_id) : (null)
      };

      let achievement_payload = {
        achievements,
        user_id,
        group_id: (group_id) ? (group_id) : (null)
      };

      if (params.collection === "suggested"){
        section_payload.suggesting_user_id = user_info.uid;
        section_payload.up_votes = [user_info.uid];
        section_payload.down_votes = [];

        achievement_payload.suggesting_user_id = user_info.uid;
        achievement_payload.up_votes = [user_info.uid];
        achievement_payload.down_votes = [];
      }

      dispatcher("CREATE_SECTION", {//HERE need to update the CREATE_SECTION reducer to handle achievement creation as an array
        collection,
        section_payload,
        achievement_payload,
        callback: ()=>{
          history(-1, {replace: true});
        }
      });
    }
  };

  const getDropdownOptions = ()=>{

    let options;

    let first_collection;
    if (params.collection === "suggested"){
      first_collection = "global";
    } else {
      first_collection = (params.collection.split("section")[0]) || (params.collection && params.collection.split("section")[0]);
    }

    let first_options = getSectionOptions(first_collection);
    let first_label;
    let second_options;
    let second_label;
    let third_options;
    let third_label;

    if (params.collection.includes("group")){
      first_label = "Group Sections";
      second_options = getSectionOptions("personal");
      second_label = "Personal Sections";
      third_options = getSectionOptions("global");
      third_label = "Global Sections";

      options = [
        {
          label: first_label,
          options: first_options,
        },
        {
          label: second_label,
          options: second_options,
        },
        {
          label: third_label,
          options: third_options,
        },
      ];
    } else if (params.collection.includes("personal")){
      first_label = "Personal Sections";
      second_options = getSectionOptions("group");
      second_label = "Group Sections";
      third_options = getSectionOptions("global");
      third_label = "Global Sections";

      options = [
        {
          label: first_label,
          options: first_options,
        },
        {
          label: second_label,
          options: second_options,
        },
        {
          label: third_label,
          options: third_options,
        },
      ];
    } else {
      first_label = "Global Sections";

      options = [{
        label: first_label,
        options: first_options,
      }];
    }

    return options;
  };

  const getSectionOptions = (collection)=>{

    const section_collection = Object.keys(sections[`${collection}_sections`]);

    return section_collection.filter((section_id)=>{
      const section = sections[`${collection}_sections`][section_id];

      return (section.deleted !== "true" && section.deleted !== true);
    }).sort((a, b)=>{
      if (sections[`${collection}_sections`][a].name < sections[`${collection}_sections`][b].name){ return -1; }
      if (sections[`${collection}_sections`][a].name > sections[`${collection}_sections`][b].name){ return 1; }
      return 0;
    }).map((section_id)=>{

      const uppercase_section_title = utility.upperCase(sections[`${collection}_sections`][section_id].name);

      return ({
        name: "section_parent",
        value: section_id,
        label: uppercase_section_title
      });
    });
  };

  const onInputChange = (e)=>{
    let state = {
      ...section
    };

    // state.section.section_parent_name = e.label;
    state.section_parent = e.value;
    setSection(state);
  };

  const getAchievementInputs = ()=>{

    return (
      <div style={{width: 336}}>
        <div className="line" style={{...styles.line}}></div>

        <div id="create-edit-section-page-add-achievements-label" style={{...styles.createEditSectionPageAddAchievementsLabel, color: styleColor}}>
          <div>Add Achievement/s (Optional)</div>
        </div>

        <div id="create-edit-section-page-achievement-page-container">
          <CreateEditAchievementPage
            errors={errors}
            section_page={true}
            onChange={onAchievementChange}
          />
        </div>

        <div className="line" style={{...styles.line}}></div>
      </div>
    );

  };

  const formatGroupLabel = (data)=>{
    return (
      <div id="create-edit-section-page-parent-options-label-container" style={{...styles.createEditSectionPageParentOptionsLabelContainer}}>
        <span id="create-edit-section-page-parent-options-label-name">{data.label}</span>
        <span id="create-edit-section-page-parent-options-count" style={{...styles.createEditSectionPageParentOptionsCount}}>{data.options.length}</span>
      </div>
    );
  };

  const getGroupSelection = ()=>{

    if ((!params.collection === "group") || admined_groups.length <= 1){
      return;
    }

    return (
      <div id="create-edit-section-page-group-container" style={{...styles.createEditSectionPageGroupContainer}}>

        <div id="create-edit-section-page-group-label" style={{...styles.createEditSectionPageGroupLabel, color: styleColor}}>Choose Group: </div>

        <select
          id="create-edit-section-page-group-select"
          style={{...styles.createEditSectionPageGroupSelect, color: styleColor}}
          name="group_id"
          value={group_id}
          onChange={onChangeGroupId}
        >
          {admined_groups.map((group, key)=>{
            return (
              <option
                key={key}
                id={`create-edit-section-page-group-${key}`}
                value={group.group_id}
              >
                {group.name}
              </option>
            );
          })}
        </select>

      </div>
    );
  };

  const collection  = params.collection;

  const section_collection = utility.upperCase(collection);

  let page_title;
  let submit_text;
  if (editing){
    page_title = `Edit ${section_collection} Section`;
    submit_text = "Update";
  } else {
    page_title = `Create ${section_collection} Section`;
    submit_text = "Submit";
  }

  const options = getDropdownOptions();

  let default_parent_id;
  if (editing){
    default_parent_id = sections[collection + "_sections"][params.section_id].parent;
  } else {
    default_parent_id = params.section_id;
  }

  const GroupSelection = getGroupSelection();

  // sections[`${params.collection}_sections`][achievement.section].name

  return(
    <div id="create-edit-section-page" style={{...styles.createEditSectionPage}}>

      <div id="create-edit-section-page-header" style={{...styles.createEditSectionPageHeader, color: styleColor}}>{page_title}</div>

      <div id="create-edit-section-page-name-container" style={{...styles.createEditSectionPageNameContainer}}>

        {GroupSelection}

        <div id="create-edit-section-page-name-label" style={{...styles.createEditSectionPageNameLabel, color: styleColor}}>Section Name</div>

        <input
          id="create-edit-section-page-name-input"
          name="section_name"
          type="text"
          placeholder="Section name"
          value={section.section_name}
          required={true}
          onChange={onChangeSectionName}
          style={{...styles.Input}}
        />
        {(errors.section_name) && (
          <div id="create-edit-section-page-name-error" style={{...styles.error}}>{errors.section_name}</div>
        )}
      </div>

      <div id="create-edit-section-page-parent-container" style={{...styles.createEditSectionPageParentContainer}}>

        <div id="create-edit-section-page-parent-label" style={{...styles.createEditSectionPageParentLabel, color: styleColor}}>Section Parent (Optional)</div>

        <Select
          id="create-edit-section-page-parent-select"
          name="section_parent"
          onChange={onInputChange}
          value={(section.section_parent) ? ({
            label: utility.upperCase(sections[determineSectionCollection(section.section_parent)][section.section_parent].name),
            name: "section_parent",
            value: section._section_parent
          }) : (undefined)}
          defaultValue={(default_name) ? ({
            label: utility.upperCase(default_name),
            name: "section_parent",
            value: default_parent_id
          }) : (null)}
          placeholder={default_name || "Select..."}
          options={options}
          formatGroupLabel={formatGroupLabel}
          styles={{
            menu: (style)=>{
              return {...style, ...styles.createEditSectionPageParentSelectMenu};
            },
            input: (style)=>{
              return {...style, ...styles.createEditSectionPageParentSelectInput};
            },
            control: (style)=>{
              return {...style, ...styles.createEditSectionPageParentSelectControl};
            },
            placeholder: (style)=>{
              return {...style, ...styles.createEditSectionPageParentSelectPlaceholder};
            },
          }}
        />
      </div>

      {getAchievementInputs()}

      <div
        id="create-edit-section-page-submit-button"
        style={{
          ...styles.createEditSectionPageSubmitButton,
          backgroundColor: styleColor,
          marginTop: (editing) ? (16) : (0)
        }}
        onClick={onSubmit}
      >
        {submit_text}
      </div>

    </div>
  );
}

const mapState = (state)=>{
  return {
    user_info: state.sessionReducer.user_info,
    sections: state.sectionReducer,
    user_groups: state.sessionReducer.user_info.groups,
    groups: state.groupReducer.groups
  };
};

export default connect(mapState)(CreateEditSectionPage);
