import React, { useState, useEffect } from "react";

import { connect }             from "react-redux";
import { useNavigate }         from "react-router-dom";

import dispatcher              from "./redux/dispatcher.js";

import ModalSwitchComponent    from "./ModalSwitchComponent.js";

import DetailsComponent        from "./components/common/DetailsComponent.js";
import SideMenu                from "./components/user/SideMenu.js";

import SideNav                 from "react-simple-sidenav";

import Select                  from "react-select";

import moment                  from "moment";

import { FontAwesomeIcon }     from "@fortawesome/react-fontawesome";
import { faBars, faBell, faTimes }      from "@fortawesome/free-solid-svg-icons";

import { isMobile, upperCase } from "./functions/utility.js";

import styles                  from "./styles/styles.js";
//SBmuY1bMIdMUGJ2MIB4gLcWAJnJ3 - deleted user id. Check personal achievements / sections for deletion

// SF0OObpdwJADN2PS7ix9 - delete achievement with section id equal to
// 0HsjADvp9jat7F81UXfQ - delete achievement with section id equal to
// 6afQDhBxRl1SQhZy34eB - delete achievement with section id equal to

// mG2CHFteTDu8iMJ9x9Hy - delete achievement with section id equal to
function Nav(props){
  const {
    details,
    user_info,
    loggedIn,
    location,
    group_invites,
    showDetails,
    notifications,
  } = props;

  const [showNav, setShowNav] = useState(false);
  const [force_section_achievement_update_version_number] = useState("5");
  const [force_section_achievement_flush_version_number] = useState("5");
  // const [app_version_number, setAppVersionNumber] = useState("v.0.0.8");
  const [showNotificationsMenu, setShowNotificationMenu] = useState(false);

  const history = useNavigate();

  useEffect(()=>{
    welcomeModal(props);

    //TODO Does not work offline, investigate
    window.auth.onAuthStateChanged((user)=>{
      if (user){
        // console.log("user if")
        user.getIdTokenResult().then((idTokenResult)=>{
          // console.log("getIdToken")
          dispatcher("RECEIVE_BASIC_USER_INFO", {
            uid: user.uid,
            display_name: user.displayName || user.email,
            email: user.email,
            avatar: user.photoURL,
            admin: idTokenResult.claims.admin
          });

          setTimeout(()=>{//Hack to handle user data not being handled before sign in
            dispatcher("GET_USER_INFO", {
              user_id: user.uid,
            });

            dispatcher("GET_USER_ACHIEVEMENTS", {
              user_id: user.uid,
            });

            dispatcher("GET_USER_SECTIONS", {
              user_id: user.uid,
            });

            dispatcher("GET_ALL_SECTIONS", {
              collection: "personal",
              operand_one: "user_id",
              operator: "==",
              operand_two: user.uid,
            });

            dispatcher("GET_ALL_ACHIEVEMENTS", {
              collection: "personal",
              operand_one: "user_id",
              operator: "==",
              operand_two: user.uid,
            });

            dispatcher("GET_NOTIFICATIONS", {
              user_id: user.uid
            });

          }, 2000);

          dispatcher("REMOVE_MODAL", "SESSION");

        });

      }
    });

    //TODO Does not work offline, investigate

    if (props.first_load || (window.localStorage.getItem("force_section_achievement_update_version_number") !== force_section_achievement_update_version_number)){//need to also query for bookmarked sections?

      if (window.localStorage.getItem("force_section_achievement_flush_version_number") !== force_section_achievement_flush_version_number){

        if (!props.first_load){
          dispatcher("FLUSH_GLOBAL_SECTIONS");
        }

        window.localStorage.setItem("force_section_achievement_flush_version_number", force_section_achievement_flush_version_number);
      }

      dispatcher("GET_ALL_SECTIONS", {collection: "global"});

      window.localStorage.setItem("force_section_achievement_update_version_number", force_section_achievement_update_version_number);
    }
  }, []);

  useEffect(()=>{

    const {
      group_redirect_id
    } = props;


    if (group_redirect_id){
      dispatcher("FLUSH_REDIRECT");
      history(`/group/${group_redirect_id}`);
    }
  });

  const welcomeModal = (passed_props)=>{
    if (!window.localStorage.getItem("welcome_modal_dismissed") && !passed_props.user_info.welcome_modal_dismissed){
      dispatcher("ADD_MODAL", {
        name: "CONFIRMATION",
        modalProps: {
          header: "Welcome to Life Nav!",
          message: "Life Nav is currently in Alpha, so while I still encourage you to use this app, many features are still be added and refined. If you would like to learn more about the state of the app, you can check out the \"About\" page, located in the side menu. If you would like to learn more about the roadmap of features and what has been added already, check out the patch notes and / or our Trello for the roadmap found in the \"About\" page. And if you would like to support the project, you can do so through Patreon.",
          okButton: true,
          okButtonText: "Okay",
          callback: ()=>{
            window.localStorage.setItem("welcome_modal_dismissed", true);
          }
        }
      });
    }
  };

  const navigate = (url)=>{
    history(url);
  };

  const toggleMenu = ()=>{
    setShowNav(true);
  };

  const formatGroupLabel = (data) => {
    return (
      <div className="section-search-label-container" style={{...styles.sectionSearchLabelContainer}}>
        <span className="section-search-label">{data.label}</span>
        <span className="section-search-length" style={{...styles.sectionSearchLength}}>{data.options.length}</span>
      </div>
    );
  };

  const getDropdownOptions = ()=>{

    return ([
      {
        label: "Global Sections",
        options: getSectionOptions("global"),
      },
      {
        label: "Personal Sections",
        options: getSectionOptions("personal"),
      },
      {
        label: "Group Sections",
        options: getSectionOptions("group"),
      },
    ]);

  };

  const getSectionOptions = (collection)=>{
    const { sections } = props;

    const section_collection = Object.keys(sections[`${collection}_sections`]);

    return section_collection.filter((section_id)=>{
      const section = sections[`${collection}_sections`][section_id];

      return (section.deleted !== "true" && section.deleted !== true);
    }).sort((a, b)=>{
      if (sections[`${collection}_sections`][a].name < sections[`${collection}_sections`][b].name){ return -1; }
      if (sections[`${collection}_sections`][a].name > sections[`${collection}_sections`][b].name){ return 1; }
      return 0;
    }).map((section_id)=>{

      const uppercase_section_title = upperCase(sections[`${collection}_sections`][section_id].name);

      return ({
        name: "section_parent",
        value: section_id,
        label: uppercase_section_title,
        collection
      });
    });
  };

  const onInputChange = (e)=>{
    setShowNav(false);
    history(`/achievements/${e.collection}/${e.value}`);
  };

  const login = ()=>{

    dispatcher("ADD_MODAL", {
      name: "SESSION",
      modalProps: {
        login: true,
      }
    });

  };

  const openNotifcationMenu = (sortedNotifications)=>{

    setShowNotificationMenu(!showNotificationsMenu);

    if (!showNotificationsMenu && sortedNotifications.length){
      dispatcher("UPDATE_USER_SETTING", {
        user_id: user_info.uid,
        payload: {
          last_notification_seen: moment(sortedNotifications[0].created).toISOString()
        }
      });
    }

  };

  const deleteNotification = (notification_id)=>{
    dispatcher("UPDATE_NOTIFICATION", {
      notification_id,
      payload: {
        deleted: "true"
      }
    });
  };


  const options = getDropdownOptions();

  const mobile = (window.screen.width <= 800);

  const SelectSearch = (
    <Select
      name="section_parent"
      placeholder={"Search Sections"}
      options={options}
      onChange={onInputChange}
      formatGroupLabel={formatGroupLabel}
      styles={{
        container: (style)=>{
          return {...style, ...styles.navSectionSearchContainer};
        },
        menu: (style)=>{
          return {...style, ...styles.navSectionSearchMenu};
        },
        input: (style)=>{
          return {...style, ...styles.navSectionSearchInput};
        },
        control: (style)=>{
          return {...style, ...styles.navSectionSearchControl};
        },
        placeholder: (style)=>{
          return {...style, ...styles.navSectionSearchPlaceholder};
        },
      }}
    />
  );

  const userAvatarStyle = {
    background: `url(${(user_info.avatar || "../images/no-avatar.png")}) center center / contain no-repeat`,
  };

  const sortedNotifications = Object.keys(notifications).sort((a, b)=>{
    const A = moment(notifications[a].created);
    const B = moment(notifications[b].created);

    return (A.isAfter(B)) ? (-1) : ((A.isBefore(B)) ? (1) : (0));
  });

  let detailsTitle;
  if (details.subject === "tools"){
    detailsTitle = "Tools and More";
  } else {
    detailsTitle = details.subject && upperCase(details.subject);
  }

  const OnClickCloseLayer = (showNotificationsMenu) ? (
    <div
      id="onclick-close-layer"
      style={{...styles.onClickCloseLayer}}
      onClick={()=>{
        setShowNotificationMenu(false);
      }}>
    </div>
  ) : (null);

  return (
    <div id="nav" style={{...styles.nav}}>

      {OnClickCloseLayer}

      <ModalSwitchComponent location={location}/>

      <div id="nav-container" style={{...styles.navContainer}}>

        <div
          id="nav-menu-container"
          style={{...styles.navMenuContainer}}
          onClick={toggleMenu}
        >

          <FontAwesomeIcon
            id="nav-menu-icon"
            style={{...styles.navMenuIcon}}
            size="2x"
            icon={faBars}
          />

          {(Object.keys(group_invites).length) ? (
            <div id="nav-menu-notification-dot" style={{...styles.navMenuNotificationDot}}></div>
          ) : (null)}

          <span id="nav-menu-text" style={{...styles.navMenuText}}>{(isMobile()) ? ("") : ("Menu")}</span>

        </div>

        <SideNav
          showNav={showNav}
          onHideNav={() => setShowNav(false)}
          title="Menu"
          items={[
            <SideMenu hideNav={()=>{setShowNav(false);}}/>
          ]}
          navStyle={styles.menuStyle}
          titleStyle={styles.menuTitleStyle}
        />

        {(mobile) ? (null) : (
          <div id="nav-section-search" style={{...styles.navSectionSearch}}>
            {SelectSearch}
          </div>
        )}

        <div
          id="nav-home-logo"
          style={{...styles.navHomeLogo}}
          onClick={()=>{navigate("/");}}>
        </div>

        <div id="nav-user-container" style={{...styles.navUserContainer}}>
          {(loggedIn) ? (
            <div style={{...styles.CenterContent, cursor: "pointer"}}>

              <FontAwesomeIcon
                id="nav-notification-icon"
                style={{...styles.navNotificationIcon}}
                size="2x"
                icon={faBell}
                onClick={()=>{openNotifcationMenu(sortedNotifications);}}
              />

              {(sortedNotifications.length && moment(notifications[sortedNotifications[0]].created).isAfter(moment(user_info.last_notification_seen))) ? (
                <div id="nav-notification-dot" style={{...styles.navMenuNotificationDot}}></div>
              ) : (null)}

              {(showNotificationsMenu && sortedNotifications.length) ? (
                <div id="nav-notification-container" style={{...styles.navNotificationContainer}}>
                  {sortedNotifications.map((notification_id)=>{
                    const notification = notifications[notification_id];

                    return (
                      <div key={notification_id} style={{display: "flex", borderBottom: "1px solid black", marginBottom: 8, paddingBottom: 8}}>
                        <div style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                          cursor: "pointer"
                        }}>
                          <FontAwesomeIcon
                            id="nav-notification-icon"
                            style={{}}
                            icon={faTimes}
                            onClick={()=>{deleteNotification(notification_id);}}
                          />
                          <div
                            onClick={()=>{
                              if (notification.url !== ""){
                                history(notification.url);
                                openNotifcationMenu();
                              }
                            }}
                          >
                            {notification.message}
                          </div>
                          <div style={{...styles.SubText}}>{moment(notification.created).fromNow()}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (showNotificationsMenu) ? (
                <div id="nav-notification-container" style={{...styles.navNotificationContainer}}>
                  You have no notifications
                </div>
              ) : (null)}

              <div
                id="nav-user-content"
                style={{...styles.navUserContent}}
                onClick={()=>{navigate("/profile");}}
              >
                <div id="nav-user-avatar" style={{...styles.navUserAvatar, ...userAvatarStyle}}></div>
                <span id="nav-user-name" style={{...styles.navUserName}}>
                  {(isMobile()) ? ("") : (user_info.display_name)}
                </span>
              </div>
            </div>
          ) : (
            <div id="nav-login-container" style={{...styles.navLoginContainer}}>
              <div
                id="nav-login-button"
                style={{...styles.navLoginButton}}
                onClick={login}
              >
                Login
              </div>
            </div>
          )}
        </div>

        <SideNav
          showNav={showDetails}
          onHideNav={() => dispatcher("CLOSE_DETAILS")}
          title={detailsTitle}
          openFromRight={true}
          items={[
            <DetailsComponent/>
          ]}
          navStyle={styles.menuStyle}
          titleStyle={styles.menuTitleStyle}
          itemStyle={styles.menuItemStyle}
        />

      </div>

    </div>
  );

}

const mapState = (state)=>{

  const hackToUpdate = Math.random();

  return {
    loggedIn: state.sessionReducer.loggedIn,
    user_info: state.sessionReducer.user_info,
    first_load: state.sectionReducer.first_load,
    sections: state.sectionReducer,
    modals: state.modalReducer,
    group_invites: state.groupReducer.group_invites,
    group_redirect_id: state.groupReducer.group_redirect_id,
    showDetails: state.sessionReducer.showDetails,
    details: state.sessionReducer.details || {},
    notifications: state.sessionReducer.notifications || {},
    hackToUpdate
  };
};

export default connect(mapState)(Nav);
