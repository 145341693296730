import React  from "react";

import styles from "../../styles/styles.js";

export default function PrivacyPolicyAndTOSPage(){

  return (
    <div style={{...styles.CenterContent, ...{paddingTop: 60}}}>
      <div style={{...styles.CenterContent, ...{flexDirection: "column", maxWidth: 8*60}}}>

        <div style={{...styles.H2, ...styles.Bold}}>Privacy Policy</div>

        <div>This page is being worked on, but to cover the basics, we do not sell your data to anyone. Any issues or concerns and you can contact me at calebsundance3@gmail.com</div>

      </div>

    </div>
  );
}
