const PrimaryColorLight = "#7D9099";// Light blue
const PrimaryColorMedium = "#2f5e75";// Medium blue

const SecondaryColorLight = "#F2EAD3";// Light brown
const SecondaryColorMedium =  "#75462F";// Medium brown

const PersonalPrimaryColor = "#2F5E75";//Medium blue
const PersonalSecondaryColor = "#7EABC4";//Light blue

const GroupPrimaryColor = "#752F5F";//Purple
const GroupSecondaryColor = "#F0DBE9";//Violet

const White = "#FFFFFF";
const Grey  = "#808080";
const Black = "#000000";

const Red = "#A71F29";// Maybe not final

const boxShadow = "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)";
const fontFamily = "'Times New Roman', serif";

const h1 = 38;
const h2 = 28;
const h3 = 21;
const text = 16;
const subtext = 12;

const sizing = 8;
const center = "center";
const flex = "flex";
const cursor = "pointer";

// const height = window.screen.height;
const width = window.screen.width;

const mobile = (width <= 800);

const H1 = {
  fontSize: h1
};

const H2 = {
  fontSize: h2
};

const H3 = {
  fontSize: h3
};

const Bold = {
  fontWeight: "bold"
};

const Text = {
  fontSize: text
};

const SubText = {
  fontSize: subtext
};

const Button = {
  color: White,
  padding: (sizing * 2),
  textAlign: "center",
  background: SecondaryColorMedium,
  cursor,
  borderRadius: sizing,
  boxShadow,
};

const Input = {
  width: "100%",
  marginBottom: sizing * 2,
  padding: sizing * 2,
  borderRadius: 2,
  border: 0,
  boxShadow,
  fontFamily,
  boxSizing: "border-box",
};

const CenterContent = {
  display: flex,
  alignItems: center,
  justifyContent: center
};

const CenterContentColumn = {
  ...CenterContent,
  flexDirection: "column"
};

const MainSubSectionButton = {
  width: (sizing * 38),
  fontSize: h3,
  padding: (sizing * 2),
  textAlign: center,
  background: SecondaryColorMedium,
  borderTop: "1px solid",
  borderColor: SecondaryColorLight,
  color: White,
  cursor
};

const MainSectionAltButton = {
  background: Grey,
  borderRadius: sizing,
  margin: sizing,
  boxShadow,
};

const ActiveTabContainer = {
  maxHeight: "80vh",
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  overflow: "auto",
  backgroundColor: PrimaryColorMedium,
  borderBottomLeftRadius: sizing,
  borderBottomRightRadius: sizing,
};

const tab = {
  cursor,
  padding: (sizing * 2),
  backgroundColor: SecondaryColorMedium,
  color: "white",
  borderTopRightRadius: sizing,
  borderTopLeftRadius: sizing,
};

const activeTab = {
  cursor,
  padding: (sizing * 2),
  backgroundColor: PrimaryColorMedium
};

const UnderlineFlair = {
  textAlign: center,
  marginTop: sizing,
  borderBottom: "1px solid black",
  borderRadius: 3
};

const achievementTemplate = {
  width: (sizing * 42),
  padding: (sizing * 2),
  boxShadow,
  boxSizing: "border-box",
  borderRadius: sizing,
  margin: "16px 0px",
  display: "block",
  alignItems: center,
  textAlign: center,
  background: SecondaryColorMedium,
  // opacity: 0.8,
};

const achievementTemplateCompleted = {
  ...achievementTemplate,
  opacity: 1
};

const achievementButtonIcons = {
  display: "inline-flex",
  flexDirection: "column",
  justifyContent: center,
  height: (sizing * 3),
  width: (sizing * 3),
  padding: sizing,
  marginBottom: sizing,
  borderRadius: sizing,
  boxShadow,
  cursor,
  background: SecondaryColorLight,
};

const achievementButton = {
  margin: sizing
};

const sectionContainer = {
  justifyContent: center,
  width: "100%",
  maxWidth: sizing * 42,
  padding: sizing,
  margin: "16px 0px",
  boxSizing: "border-box",
  background: SecondaryColorMedium,
  borderRadius: sizing,
  font: "bold 24px  Times New Roman, serif",
  boxShadow,
  cursor
};

const option = {
  borderRadius: sizing,
  padding: sizing * 2,
  textAlign: "center",
  cursor,
  margin: sizing,
  backgroundColor: SecondaryColorMedium,
  color: White
};

const redNotificationDot = {
  borderRadius: "50%",
  width: 12,
  height: 12,
  backgroundColor: Red,
  boxShadow
};

const close = {
  color: SecondaryColorMedium,
  cursor,
  margin: sizing
};

//Quick Jump Reference

//ACHIEVEMENTS
//COMMON
//GROUPS
//PAGES
//REPORTS
//SECTIONS
//SESSION
//USER
//NAV
//TABS
//MISC

export default {

  /*-----General Template-----*/

  SecondaryColorLight,
  SecondaryColorMedium,

  PrimaryColorLight,
  PrimaryColorMedium,

  GroupPrimaryColor,
  GroupSecondaryColor,

  PersonalPrimaryColor,
  PersonalSecondaryColor,

  White,
  Black,
  Grey,
  Red,

  H1,
  H2,
  H3,
  Text,
  SubText,

  Bold,

  UnderlineFlair,

  CenterContent,
  CenterContentColumn,

  Button,
  Input,

  tab,
  activeTab,
  close,

  achievementTemplate,
  achievementTemplateCompleted,
  achievementButtonIcons,
  achievementButton,
  sectionContainer,
  option,
  redNotificationDot,

  Pointer: {
    cursor
  },

  BoxShadow: {
    boxShadow
  },

  body: {
    font: "18px Times New Roman, serif",
    background: SecondaryColorLight,
  },

  error: {
    color: Red
  },

  hidden: {
    display: "none",
  },

  notReleased: {
    display: "none",
  },

  notReleasedYet: {
    backgroundColor: Grey,
    opacity: 0.33,
    color: White
  },

  icon: {
    minWidth: (sizing * 5),
    height: (sizing * 5),
  },

  // input[type=submit] {
  //   border: none;
  //   background: #b3572a;
  //   padding: 2px;
  //   margin: 2px;
  //   border-radius: 2px;
  // }

  // ::-webkit-input-placeholder,
  // ::-moz-placeholder,
  // :-ms-input-placeholder,
  // :-moz-placeholder {
  //   text-align: center;
  // }
  //
  // input:focus::-webkit-input-placeholder,
  // input:focus:-moz-placeholder,
  // input:focus::-moz-placeholder,
  // input:focus:-ms-input-placeholder {
  //   color:transparent;
  // }

  modal: {
    top: "50%",
    left: "50%",
    right: "70%",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    border: "1px solid rgb(204, 204, 204)",
    background: "rgb(255, 255, 255)",
    overflow: "auto",
    maxHeight: "100vh",
    borderRadius: 4,
    outline: "none",
    padding: 20,
    boxShadow,
  },

  sectionIcon: {
    minHeight: (sizing * 6),
    minWidth: (sizing * 6),
    margin: sizing,
    backgroundColor: SecondaryColorLight,
    font: "12px georgia",
    borderRadius: sizing,
  },

  sectionName: {
    display: flex,
    alignItems: center,
    marginLeft: (sizing * 2)
  },

  section: {
    textAlign: center,
    marginTop: sizing,
    borderBottom: "1px solid black",
    borderRadius: 3
  },

  sectionOptionsButton: {
    color: SecondaryColorMedium,
    marginTop: sizing * 2,
    cursor,
  },

  MainSubSectionButton,

  MainSectionAltButton,

  mainSectionBlueButton: {
    background: PrimaryColorMedium,
    borderRadius: sizing,
    margin: sizing,
    boxShadow,
  },

  mainSectionBrownButton: {
    background: SecondaryColorMedium,
    borderRadius: sizing,
    margin: sizing,
    boxShadow,
  },

  wideButton: {
    display: "flex",
    flexDirection: "column",
    justifyContent: center,
    height: sizing * 4,
    padding: sizing,
    boxShadow,
    margin: sizing * 2,
    marginLeft: sizing,
    marginRight: sizing,
    background: SecondaryColorMedium,
    borderRadius: sizing,
    font: "bold 14px  Times New Roman, serif",
    cursor,
  },

  topCurvedBorder: {
    borderTop: "1px solid black",
    borderRadius: sizing,
    marginTop: sizing,
    marginBottom: sizing,
  },

  bottomCurvedBorder: {
    borderBottom: "1px solid black",
    borderRadius: sizing,
    marginTop: sizing,
    marginBottom: sizing,
  },

  onClickCloseLayer: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: "rgba(0,0,0,0)",
    zIndex: 7,
  },

  //-----NAV-----//

  nav: {
    backgroundColor: SecondaryColorMedium,
    minHeight: (sizing * 6),
    position: "fixed",
    width: "100%",
    zIndex: 9,
    boxShadow,
    ...CenterContent
  },

  navContainer: {
    width: (mobile) ? ("100%") : ("60%"),
    display: flex,
    justifyContent: center,
    alignItems: center,
    position: "relative",
  },

  navMenuContainer: {
    position: "fixed",
    left: 0,
    display: flex,
    marginLeft: sizing * 2,
    alignItems: center,
    cursor
  },

  navMenuIcon: {
    color: SecondaryColorLight,
    marginRight: sizing * 2
  },

  navNotificationIcon: {
    color: SecondaryColorLight,
    marginRight: sizing * 3,
    height: sizing * 3
  },

  navNotificationContainer: {
    boxShadow,
    position: "absolute",
    left: -212,
    right: 170,
    top: 44,
    backgroundColor: "white",
    padding: sizing * 2,
    zIndex: 8
  },

  navMenuNotificationDot: {
    ...redNotificationDot,
    position: "absolute",
    left: 28,
    top: 0
  },

  navSectionSearch: {
    position: "absolute",
    left: 0,
    display: "flex"
  },

  navMenuText: {
    color: White
  },

  navUserContainer: {
    position: "absolute",
    right: 0,
    display: flex
  },

  navLoginContainer: {
    display: flex,
  },

  menuStyle: {

  },

  menuTitleStyle: {
    backgroundColor: SecondaryColorMedium,
    minHeight: 48,
    padding: 0,
    display: "flex",
    justifyContent: center,
    alignItems: center,
    lineHeight: 0,
  },

  menuItemStyle: {
    display: "block",
    fontSize: text,
    cursor: "default",
    overflow: "auto",
    maxHeight: "90vh"
  },

  navHomeLogo: {
    width: 100,
    minHeight: (sizing * 6),
    background: "url(../images/Full-Logo.gif) center center / contain no-repeat",
    backgroundColor: SecondaryColorLight,
    cursor
  },

  search: {
    background: "url(../images/search.png) center center / contain no-repeat",
    cursor
  },

  searchbar: {

  },

  searchButton: {

  },

  navLoginButton: {
    ...CenterContent,
    backgroundColor: SecondaryColorLight,
    width: (sizing * 7),
    minHeight: (sizing * 6),
    cursor,
  },

  signupForm: {
    textAlign: center
  },

  loginform: {
    textAlign: center,
  },

  passwordRequirements: {
    font: "12px  Times New Roman",
    width: 170,
  },

  loginHeader: {
    textAlign: center,
  },

  registerHeader: {
    textAlign: center,
  },

  navSignIn: {

  },

  navRegister: {

  },

  notifications: {
    background: "url(../images/notifications.png) center center / contain no-repeat",
    cursor
  },

  friends: {
    background: "url(../images/friends.png) center center / contain no-repeat",
    cursor
  },

  navUserContent: {
    cursor,
    display: flex,
    alignItems: center
  },

  navUserDropdown: {
    display: "inline-flex",
    marginLeft: sizing,
  },

  navUserButton: {
    width: 12,
    height: 24,
    border: "1px solid lightgrey",
  },

  navUserOptions: {
    padding: 2,
  },

  navUserAvatar: {
    width: (sizing * 5),
    minHeight: (sizing * 5),
    borderRadius: "50%",
    marginRight: sizing,
  },

  navUserName: {
    color: White
  },

  ActiveTabContainer,

  //-----SECTIONS-----//

  sectionHeaderOptions: {
    ...achievementButton,
    marginTop: sizing * 4,
    width: sizing * 42,
    display: flex,
    justifyContent: "space-between"
  },

  sectionHeaderOptionsEdit: {
    ...achievementButtonIcons,
    backgroundColor: PrimaryColorMedium,
    color: SecondaryColorLight
  },

  sectionHeaderOptionsBookmark: {
    ...achievementButtonIcons,
    color: SecondaryColorLight,
    backgroundColor: PrimaryColorMedium,
  },

  sectionCardContainer: {
    display: "inline-flex",
    position: "relative",
  },

  sectionCard: {

  },

  sectioncardDetails: {
    ...Text,
    fontWeight: 400,
    textAlign: center
  },

  sectionCardIconContainer: {
    ...CenterContent
  },

  sectionCardName: {

  },

  sectionOptionsAddNewAchievement: {
    ...option
  },

  sectionOptionsAddNewChildSection: {
    ...option
  },

  sectionOptionsEditThisSection: {
    ...option
  },

  sectionOptionsDeleteSection: {
    ...option
  },

  sectionOptionsAddNewGroupAchievement: {
    ...option
  },

  sectionOptionsAddNewChildGroupSection: {
    ...option
  },

  sectionOptionsAddPersonalAchievement: {
    ...option,
    backgroundColor: PersonalPrimaryColor
  },

  sectionOptionsAddPersonalSection: {
    ...option,
    backgroundColor: PersonalPrimaryColor
  },

  sectionOptionsSuggestNewAchievement: {
    ...option,
    backgroundColor: Grey,
  },

  sectionOptionsSuggestNewSection: {
    ...option,
    backgroundColor: Grey,
  },

  sectionOptionsReportSection: {
    ...option,
    backgroundColor: Red
  },

  sectionOptionsHeaderSpacer: {
    width: 38
  },

  sectionOptionsHeader: {
    ...H2
  },

  sectionOptionsHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },

  sectionOptions: {

  },

  sectionOptionsCloseButton: {
    color: SecondaryColorMedium,
    cursor,
    margin: sizing
  },

  inputX: {
    position: "absolute",
    top: -6,
    right: -6,
    backgroundColor: SecondaryColorMedium,
    borderRadius: "50%",
    width: 21,
    display: "flex",
    justifyContent: "center",
    color: White,
    cursor,
  },

  personalSectionContainer: {
    backgroundColor: PersonalPrimaryColor
  },

  groupSectionContainer: {
    backgroundColor: GroupPrimaryColor
  },

  suggestedSectionContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },

  suggestedSectionVotingContainer: {
    ...CenterContentColumn,
    padding: "0px 8px",
    borderRadius: "0px 6px 6px 0px",
    backgroundColor: White
  },

  //-----ACHIEVEMENTS-----//

  personalAchievementTemplate: {
    backgroundColor: PersonalPrimaryColor
  },

  groupAchievementTemplate: {
    backgroundColor: GroupPrimaryColor
  },

  suggestedAchievementTemplate: {
    backgroundColor: Grey
  },

  achievementTopContainer: {
    display: flex
  },

  achievementMoreInfoContainer: {
    maxHeight: 0,
    overflow: "hidden"
  },

  achievementIconNameContainer: {
    display: flex,
    alignItems: center,
    width: "100%",
    zIndex: 1,
  },

  achievementName: {
    display: center,
    alignItems: center,
    textAlign: center,
    justifyContent: center,
    width: "100%",
    fontSize: h3,
    paddingBottom: sizing,
    marginLeft: sizing,
    marginRight: sizing,
    // fontWeight: 600,
    ...UnderlineFlair
  },

  achievementPoints: {
    borderRadius: "50%",
    minWidth: sizing * 3
  },

  achievementDescription: {
    padding: sizing,
    marginTop: (sizing * 3),
    marginBottom: (sizing * 3),
  },

  achievementParentSectionLabel: {
    ...SubText,
    cursor,
    textAlign: "left",
    marginBottom: sizing * 2,
    marginLeft: sizing,
    marginRight: sizing,
    paddingTop: sizing,
    borderTop: "1px solid black",
  },

  suggestionsPageAchievementsContainer: {
    ...CenterContentColumn,
    justifyContent: "start",
    padding: sizing,
  },

  suggestionsPageSectionsContainer: {
    ...CenterContentColumn,
    justifyContent: "start",
    padding: sizing,
  },

  suggestedParentLink: {
    marginLeft: sizing,
  },

  achievementButtonContainer: {
    textAlign: center,
    width: "100%"
  },

  achievementFavoriteTrackingContainer: {

  },

  achievementCompleteContainer: {

  },

  achievementDetailsLabel: {
    width: "100%",
    backgroundColor: SecondaryColorMedium,
    padding: (sizing * 2),
    marginBottom: (sizing * 2),
    position: "relative",
    left: -(sizing * 2),
    color: White
  },

  achievementMoreContainer: {
    margin: sizing,
    color: SecondaryColorLight
  },

  achievementToolsText: {
    position: "absolute",
    width: "100%"
  },

  achievementAdviceText: {
    position: "absolute",
    width: "100%"
  },

  achievementAdviceIcon: {
    marginRight: sizing
  },

  achievementDiscussText: {
    position: "absolute",
    width: "100%"
  },

  achievementDiscussIcon: {
    marginRight: sizing
  },

  achievementCollaborationText: {
    position: "absolute",
    width: "100%"
  },

  achievementCollaborationIcon: {
    marginRight: sizing
  },

  achievementAdminButtonsContainer: {
    ...CenterContent,
    marginTop: sizing * 2,
    justifyContent: "space-between"
  },

  achievementAdminEditIcon: {
    ...achievementButtonIcons,
    backgroundColor: PrimaryColorMedium
  },

  achievementButtonText: {
    fontSize: subtext
  },

  achievementAdminEditButton: {
    ...achievementButton,
  },

  achievementAdminDeleteButton: {
    ...achievementButton,
    ...CenterContent
  },

  achievementAdminDeleteIcon: {
    ...achievementButtonIcons,
    backgroundColor: Red
  },

  achievementReportContainer: {
    ...achievementButton
  },

  achievementReportButton: {
    ...achievementButtonIcons,
    backgroundColor: Red
  },

  suggestedAchievementContainer: {
    ...CenterContent,
    width: "100%"
  },

  suggestedAchievementVotingContainer: {
    minHeight: sizing * 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: sizing,
    padding: "0px 8px",
    borderRadius: "0px 6px 6px 0px",
    backgroundColor: White
  },

  achievementTopBackground: {
    position: "absolute",
    backgroundColor: "#42281A",
    width: "100%",
    height: 56,
    zIndex: 0,
    padding: 16,
    top: -16,
    left: -16,
    borderRadius: "8px 8px 0px 0px",
  },

  achievementMainContent: {
    display: "block",
    position: "relative",
  },

  achievementIconContainer: {
    margin: sizing
  },

  achievementIcon: {
    cursor: "default"
  },

  achievementStarIcon: {
    cursor: "default",
    width: sizing * 4,
    height: sizing * 4,
  },

  achievementCompletedTime: {
    ...SubText,
    color: Black
  },

  /*-----COMMON-----*/

  confirmation: {

  },

  confirmationOkButtonSpacer: {

  },
  confirmationCancelButtonSpacer: {

  },

  confirmationCancelButton: {
    ...Button
  },

  confirmationOkButton: {
    ...Button
  },

  confirmationContent: {

  },

  confirmationSelect: {
    margin: 20
  },

  confirmationSelectChoice: {
    marginLeft: 0,
    marginRight: 0
  },

  confirmationSelectText: {

  },

  confirmationSelectName: {

  },

  confirmationMessageTwo: {

  },

  confirmationHeader: {
    ...H2,
    fontWeight: 600
  },

  confirmationMessage: {
    marginTop: sizing * 2,
    marginBottom: sizing * 2
  },

  confirmationButtonContainer: {
    display: "flex",
    justifyContent: "space-between"
  },

  noteDetailsInput: {
    boxShadow,
    width: "100%",
    height: "60vh",
    padding: sizing * 2,
    boxSizing: "border-box",
    backgroundColor: SecondaryColorLight,
  },

  noteDetailsSave: {
    marginTop: sizing * 2,
    marginBottom: sizing * 2
  },

  noteDetailsSaveButton: {
    ...Button,
    backgroundColor: SecondaryColorMedium,
    opacity: 0.33,
  },

  linkDetailsAdminContainer: {

  },

  linkDetailsAdminHeader: {
    ...H3,
    color: SecondaryColorMedium,
    marginBottom: sizing * 2
  },

  linkDetailsAdminAdd: {
    ...achievementButton,
    ...CenterContent,
    marginTop: sizing * 2
  },

  linkDetailsPersonalContainer: {
    ...CenterContentColumn,
  },

  linkDetailsPersonalContent: {
    ...CenterContentColumn,
    width: "100%"
  },

  linkDetailsNoPersonalLinks: {

  },

  linkDetailsPersonalAdd: {
    ...achievementButton,
    ...CenterContent,
    marginTop: sizing * 2
  },

  linkDetailsAdminAddIcon: {
    ...achievementButtonIcons,
    color: Black
  },

  linkDetailsPersonalAddIcon: {
    ...achievementButtonIcons,
    backgroundColor: PersonalSecondaryColor,
    color: Black
  },

  linkDetailsPersonalHeader: {
    ...H3,
    color: PersonalPrimaryColor,
    marginBottom: sizing * 2
  },

  linkDetailsAdminContent: {
    ...CenterContentColumn,
  },

  linkDetailsNoAdminLinks: {

  },

  linkDisplayedTitle: {
    ...Text
  },

  linkDisplayedDescription: {
    boxShadow,
    width: "100%",
    padding: sizing
  },

  linkDisplayedEdit: {
    width: sizing * 5
  },

  linkDisplayed: {
    ...CenterContent,
    alignItems: "baseline",
    width: "100%",
    marginBottom: sizing * 2
  },

  linkDisplayedContainer: {
    ...CenterContentColumn,
    width: "100%",
  },

  linkDisplayedContent: {
    boxShadow,
    width: "100%",
    display: flex,
    justifyContent: "space-between",
    padding: sizing,
    color: White,
    borderRadius: "4px 4px 0px 0px"
  },

  linkDisplayedEditIcon: {
    cursor
  },

  linkDisplayedIcon: {
    cursor
  },

  linkCreateEditTitleError: {

  },

  linkCreateEditDescriptionError: {

  },

  linkCreateEditUrlError: {

  },

  linkCreateEditSaveButton: {
    ...Button,
    background: PrimaryColorMedium,
    marginTop: sizing * 2,
    width: "100%",
    boxSizing: "border-box"
  },

  linkCreateEdit: {
    ...CenterContentColumn,
    alignItems: "baseline",
    width: "100%",
    marginBottom: sizing * 2,
  },

  linkCreateEditContainer: {
    ...CenterContentColumn,
    width: "100%",
  },

  linkCreateEditTitleContainer: {
    boxShadow,
    width: "100%",
    display: flex,
    justifyContent: "space-between",
    padding: sizing,
    color: White,
    borderRadius: "4px 4px 0px 0px"
  },

  linkCreateEditCancelContainer: {
    width: sizing * 5
  },

  linkCreateEditCancelIcon: {
    cursor
  },

  linkCreateEditTitle: {

  },

  linkCreateEditTitleInput: {
    width: "100%"
  },

  linkCreateEditDeleteIcon: {
    cursor
  },

  linkCreateEditDeleteError: {
    width: sizing * 5
  },

  linkCreateEditDescription: {
    boxShadow,
    width: "100%",
    padding: sizing
  },

  linkCreateEditDescriptionInput: {
    width: "100%"
  },

  linkCreateEditUrl: {
    boxShadow,
    width: "100%",
    backgroundColor: White,
    padding: sizing
  },

  linkCreateEditUrlInput: {
    width: "100%"
  },

  reportDetailsTypeContainer: {
    display: flex,
    marginBottom: sizing * 2,
    alignItems: center
  },

  reportDetailsTypeLabel: {
    ...H3,
    marginRight: sizing * 2
  },

  reportDetailsTypeSelect: {
    boxShadow,
    border: "none",
    backgroundColor: White,
    padding: sizing,
    cursor
  },

  reportDetailsType: {

  },

  reportDetailsTypeBug: {

  },


  reportDetailsTypeIncorrect: {

  },


  reportDetailsTypeInappropriate: {

  },

  reportDetailsTypeError: {

  },

  reportDetailsNotesError: {

  },

  reportDetailsNotes: {
    boxShadow,
    width: "100%",
    height: "60vh",
    padding: sizing * 2,
    boxSizing: "border-box",
    backgroundColor: SecondaryColorLight,
  },

  reportDetailsSendButton: {
    ...Button,
    backgroundColor: SecondaryColorMedium
  },

  reportDetailsSendButtonContainer: {
    marginTop: sizing * 2
  },

  /*-----GROUPS-----*/

  groupSettings: {
    padding: sizing * 2,
    color: White
  },

  groupSettingsMemberViewHeader: {
    ...H2,
    textAlign: center,
    marginBottom: sizing * 2
  },

  groupSettingsMemberViewContent: {
    width: 336,
    marginTop: sizing * 2
  },

  groupSettingsMemberViewGroupNameLabel: {
    fontWeight: 600
  },

  groupSettingsMemberViewGroupName: {

  },

  groupSettingsMemberViewGroupDescriptionLabel: {
    fontWeight: 600
  },

  groupSettingsMemberViewLeave: {
    ...Button,
    backgroundColor: Red,
    margin: "16px 0px"
  },

  groupSettingsContent: {

  },

  groupSettingsInfoLabel: {
    ...H2,
    textAlign: "center",
    marginBottom: sizing * 2,
    color: Black
  },

  groupSettingsInfoContent: {
    width: 336,
    marginTop: sizing * 2,
    color: Black
  },

  groupSettingsNameLabelcontainer: {
    cursor,
    color: Black
  },

  groupSettingsNameLabel: {

  },

  groupSettingsNameEditIcon: {
    color: GroupPrimaryColor
  },

  groupSettingsNameLabelContent: {
    display: "flex",
    justifyContent: "space-between"
  },

  groupSettingsNameContainer: {
    cursor
  },

  groupSettingsName: {

  },

  groupSettingsNameInput: {
    ...Input,
    marginTop: sizing * 2
  },

  groupSettingsDescriptionLabel: {

  },

  groupSettingsDescriptionLabelContainer: {
    cursor
  },

  groupSettingsDescriptionLabelContent: {
    display: flex,
    justifyContent: "space-between"
  },

  groupSettingsDescriptionEditIcon: {
    color: GroupPrimaryColor
  },

  groupSettingsDescriptionInput: {
    ...Input,
    marginTop: sizing * 2
  },

  groupSettingsDescriptionContainer: {
    cursor
  },

  groupSettingsDescription: {

  },

  groupSettingsSaveButtonContainer: {

  },

  groupSettingsLabel: {
    ...H2,
    textAlign: center,
    marginBottom: sizing * 2,
    color: Black
  },

  groupSettingsSaveButton: {
    ...Button,
    background: PrimaryColorMedium,
    backgroundColor: SecondaryColorMedium,
    marginBottom: sizing * 2
  },

  groupSettingsPrivacySelect: {
    ...Input,
    border: "none",
    color: PrimaryColorMedium,
    backgroundColor: White,
    padding: sizing * 2,
    cursor,
    width: 336,
    margin: "16px 0px"
  },

  groupSettingsInviteTypeSelect: {
    ...Input,
    border: "none",
    color: PrimaryColorMedium,
    backgroundColor: White,
    padding: sizing * 2,
    cursor,
    width: 336,
    margin: "16px 0px 0px 0px"
  },

  groupSettingsDeleteButton: {
    ...Button,
    backgroundColor: Red
  },

  groupSettingsLeaveButton: {
    ...Button,
    backgroundColor: Red,
    margin: "16px 0px"
  },

  groupsettingsNameError: {

  },

  groupSettingsDescriptionError: {

  },

  groupSettingsInviteTypeContainer: {

  },

  groupSettingsInviteTypeContent: {

  },

  groupSettingsInviteTypeLabel: {
    color: Black
  },

  groupSettingsPrivacyLabel: {
    color: Black
  },

  groupSettingsPrivacyContent: {

  },

  groupInvite: {

  },

  groupInviteHeaderCloseIcon: {
    ...close
  },

  groupInviteHeader: {
    ...H2
  },

  groupInviteHeaderSpacer: {
    width: 38
  },

  groupInviteDescription: {
    margin: "16px 0px"
  },

  groupInviteContent: {
    ...CenterContentColumn,
    margin: "16px 0px"
  },

  groupInviteMessage: {
    margin: "16px 0px"
  },

  groupInviteDescriptionLabel: {
    ...H3,
    margin: "16px 0px"
  },

  groupInviteHeaderContainer: {
    display: flex,
    justifyContent: "space-between",
    alignItems: center
  },

  groupInviteDeclineButton: {
    ...Button
  },

  groupInviteButtonsContainer: {
    ...CenterContent,
    justifyContent: "space-between"
  },

  groupInviteJoinButton: {
    ...Button,
    background: PrimaryColorMedium
  },

  groupAchievementsNoContentMessage: {
    padding: sizing * 2
  },

  groupAchievements: {
    paddingTop: sizing * 2,
    paddingBottom: sizing * 2,
    color: White,
    ...CenterContentColumn
  },

  groupAchievementsCreateSectionButton: {
    ...MainSubSectionButton,
    borderRadius: sizing,
    margin: sizing,
    boxShadow,
    color: White,
    backgroundColor: GroupPrimaryColor
  },

  groupAchievementsCreateAchievementButton: {
    ...MainSubSectionButton,
    borderRadius: sizing,
    margin: sizing,
    boxShadow,
    color: White,
    backgroundColor: GroupPrimaryColor
  },

  groupMemberCard: {
    ...sectionContainer,
    fontSize: text,
    fontWeight: "normal",
    backgroundColor: GroupPrimaryColor
  },

  groupMemberCardContent: {
    display: flex,
    alignItems: center,
    justifyContent: "space-between"
  },

  groupMemberCardName: {
    display: flex,
    alignItems: center
  },

  groupMemberCardAdminIcon: {
    color: White,
    marginLeft: sizing
  },

  groupMemberCardInfoSettingsIcon: {
    ...close,
    color: White
  },

  groupMemberCardStatusContainer: {

  },

  groupMemberCardStatus: {
    ...SubText
  },

  groupMembers: {
    padding: sizing * 2
  },

  groupMembersInviteContainer: {
    width: 336
  },

  groupMembersInvitee: {
    ...Input
  },

  groupMembersInviteLabel: {
    ...H3,
    color: Black,
    width: "100%"
  },

  groupMembersInviteeError: {

  },

  groupMembersSendButton: {
    ...Button,
    backgroundColor: GroupPrimaryColor
  },

  groupMembersSendSuccessMessage: {
    color: Black,
    marginTop: sizing * 2,
    marginBottom: sizing * 2
  },

  groupMembersInviteButton: {
    ...Button,
    backgroundColor: GroupPrimaryColor
  },

  groupMembersContainer: {
    marginBottom: sizing * 2,
    marginTop: sizing * 2,
    color: White,
    width: 336
  },

  groupMembersLabel: {
    ...H3,
    color: Black
  },

  groupMembersInvitedLabel: {
    ...H3,
    color: Black
  },

  groupMembersNoInvitedMessage: {
    marginBottom: sizing * 2,
    marginTop: sizing * 2,
    color: Black
  },

  groupMembersRequestLabel: {
    ...H3
  },

  groupMembersNoRequestsMessage: {
    marginBottom: sizing * 2,
    color: White
  },

  groupMemberDetails: {

  },

  groupMemberDetailsHeaderSpacer: {
    width: 38
  },

  groupMemberDetailsHeader: {
    ...H2
  },

  groupMemberDetailsHeaderContainer: {
    display: flex,
    justifyContent: "space-between",
    alignItems: center
  },

  groupMemberDetailsCloseIcon: {
    ...close
  },

  groupMemberDetailsName: {

  },

  groupMemberDetailsAdminIcon: {
    marginRight: sizing
  },

  groupMemberDetailsStatuscontainer: {
    marginTop: sizing * 2,
    marginBottom: sizing * 2,
    display: flex,
    justifyContent: "space-between"
  },

  groupMemberDetailsStatusLabel: {

  },

  groupMemberDetailsStatus: {

  },

  groupMemberDetailsNameLabel: {

  },

  groupMemberDetailsRequesterNameLabel: {

  },

  groupMemberDetailsInviteeNameLabel: {

  },

  groupMemberDetailsNameContainer: {
    marginTop: sizing * 3,
    marginBottom: sizing * 3,
    display: flex,
    justifyContent: "space-between"
  },

  groupMemberDetailsPromoteButton: {
    ...Button,
    background: PrimaryColorMedium
  },

  groupMemberDetailsDemoteButton: {
    ...Button,
    background: PrimaryColorMedium
  },

  groupMemberDetailsApproveButton: {
    ...Button,
    background: PrimaryColorMedium
  },

  groupMemberDetailsReinviteButton: {
    ...Button,
    background: PrimaryColorMedium
  },

  groupMemberDetailsButtonsContainer: {
    ...CenterContent,
    justifyContent: "space-between"
  },

  groupMemberDetailsRejectButton: {
    ...Button,
    backgroundColor: Red
  },

  groupMemberDetailsDeleteButton: {
    ...Button,
    backgroundColor: Red
  },

  // .tab-links li {
  //
  // }

  // .tab-links a {
  //   padding:9px 15px;
  //   display:inline-block;
  //   border-radius:3px 3px 0px 0px;
  //   background:#663219;
  //   font-size:16px;
  //   font-weight:600;
  //   transition:all linear 0.15s;
  // }

  // .tab-links a:hover {
  //   background:#b3572a;
  //   text-decoration:none;
  // }

  // li.active a, li.active a:hover {
  //   background:#b3572a;
  // }

  // .tab-content {
  //   padding:20px;
  //   border-radius:3px;
  //   box-shadow:-1px 1px 1px rgba(0,0,0,0.15);
  //   background:#b3572a;
  // }

  // .tab {
  //
  // }

  // .tab.active {
  //
  // }


  /*-----Mobile Device Settings-----*/

  // @media only screen and (max-device-width: 480px) {
  //   .full-logo {
  //     display: flex;
  //     justify-content: center;
  //     width: 340px;
  //     height: 150px;
  //   }
  //
  //   .dynamic-logo {
  //     background-image:none;
  //     display: flex;
  //     justify-content: center;
  //     width: 0px;
  //     height: 0px;
  //   }
  //
  //
  //   .dynamic-name {
  //     display:none;
  //   }
  // }

  /*-----PAGES-----*/

  homePage: {
    ...CenterContentColumn,
    paddingTop: 60
  },

  homePagePatchNotesContainer: {
    ...CenterContent,
    cursor,
  },

  homePagePatchNotesLabel: {
    backgroundColor: PrimaryColorMedium,
    color: White,
    padding: sizing,
    marginLeft: sizing,
    borderRadius: 16,
    fontSize: subtext
  },

  homePagePatreonLink: {
    marginTop: sizing * 2,
    color: White,
    textDecoration: "none",
    padding: sizing,
    borderRadius: 24,
    backgroundColor: GroupPrimaryColor
  },

  homePageAchievementWorkshopButton: {
    ...MainSubSectionButton,
    ...MainSectionAltButton
  },

  homePageGroupAchievementButton: {
    ...MainSubSectionButton,
    ...MainSectionAltButton,
    backgroundColor: GroupPrimaryColor,
    color: White
  },

  homePagePersonalAchievementButton: {
    ...MainSubSectionButton,
    background: PrimaryColorMedium,
    borderRadius: sizing,
    margin: sizing,
    boxShadow,
    color: White
  },

  homePageMainSectionGroupContent: {
    maxHeight: 0,
    overflow: "hidden"
  },

  homePageMainSectionGroupContainer: {
    background: SecondaryColorMedium,
    boxShadow,
    marginBottom: (sizing * 2),
    marginTop: (sizing * 2),
    borderTopRightRadius: sizing,
    borderTopLeftRadius: sizing,
  },

  homePageMainSectionGroupName: {
    color: White,
    width: (sizing * 38),
    fontSize: h2,
    padding: (sizing * 2),
    textAlign: "center",
    background: SecondaryColorMedium,
    cursor,
    borderTopRightRadius: sizing,
    borderTopLeftRadius: sizing,
  },

  homePageMainSectionGroupArrowButton: {
    padding: sizing,
    borderTop: "1px solid rgb(242, 234, 211)",
    display: "flex",
    justifyContent: center,
    cursor
  },

  homePageMainSubSectionButtonContainer: {
    ...MainSubSectionButton,
    padding: 0,
    width: "100%"
  },

  aboutPageContainer: {
    ...CenterContent,
    paddingTop: 60
  },

  aboutPageContent: {
    ...CenterContent,
    flexDirection: "column",
    maxWidth: sizing * 60
  },

  aboutPageTitle: {
    ...H2,
    ...Bold
  },

  aboutPagePatchNotesButton: {
    cursor,
    display: "flex",
    justifyContent: center,
    alignItems: center,
    margin: sizing * 3
  },

  aboutPageNotesButtonText: {
    backgroundColor: PrimaryColorMedium,
    color: White,
    padding: sizing,
    marginLeft: sizing,
    borderRadius: sizing * 2,
    fontSize: 12
  },

  aboutPagePatreonLink: {
    marginTop: sizing * 2,
    color: White,
    textDecoration: "none",
    padding: sizing,
    borderRadius: sizing * 3,
    backgroundColor: GroupPrimaryColor
  },

  aboutPageProblemSolvingTitle: {
    ...H3,
    ...Bold,
    margin: sizing * 3
  },

  aboutPageRoadmapTitle: {
    ...H3,
    ...Bold,
    margin: sizing * 3
  },

  aboutPageRoadmapText: {

  },

  aboutPageTrelloLink: {
    marginTop: sizing * 2,
    color: White,
    textDecoration: "none",
    padding: sizing,
    borderRadius: sizing * 3,
    backgroundColor: GroupPrimaryColor
  },

  reportsPage: {
    ...CenterContent,
    paddingTop: 60
  },

  reportsPageContainer: {
    ...CenterContentColumn,
    maxWidth: sizing * 60
  },

  reportsPageHeader: {
    ...H2,
    ...Bold,
  },

  suggestionsPage: {
    ...CenterContent,
    paddingTop: 60
  },

  suggestionsPageContainer:{
    ...CenterContentColumn,
    maxWidth: sizing * 60
  },

  suggestionsPageHeader: {
    ...H2,
    ...Bold
  },

  suggestionsPageContent: {
    ...CenterContentColumn,
    width: "100vw",
    maxWidth: "100vw",
    display: "flex",
    flexDirection: (mobile) ? ("column") : ("unset"),
    alignItems: (mobile) ? ("center") : ("unset"),
    justifyContent: "center",
  },

  sectionPage: {
    paddingTop: 60
  },

  sectionPageHeaderContainer: {
    ...CenterContent
  },

  sectionPageProgressBarContainer: {
    ...CenterContent
  },

  sectionPageContent: {
    ...CenterContentColumn
  },

  sectionPageHeader: {
    ...UnderlineFlair,
    ...H2
  },

  sectionPageProgressBarContent: {
    ...UnderlineFlair,
    width: sizing * 42,
  },

  sectionPageSectionsAndAchievementsContainer: (mobile) ? ({
    ...CenterContentColumn
  }) : ({
    display: "flex",
    minWidth: sizing * 42,
  }),

  sectionPageProgressBarEarned: {
    position: "relative",
    display: "flex",
    paddingBottom: sizing
  },

  sectionPageProgressBarEarnedText: {
    marginRight: sizing
  },

  sectionPageProgressBarEarnedFraction: {
    position: "absolute",
    right: 130,
    top: 2
  },

  sectionsAchievementsPageNoSectionsMessage: {
    maxWidth: sizing * 42,
    marginTop: sizing * 2,
    textAlign: "center"
  },

  sectionsAchievementsPageNoAchievementsMessage: {
    maxWidth: sizing * 42,
    marginTop: sizing * 2,
    textAlign: "center"
  },

  sectionsAchievementsPageWorkshopLinkAchievement: {
    marginLeft: 4,
    fontWeight: "bold",
    cursor: "pointer"
  },

  sectionsAchievementsPageWorkshopLinkSection: {
    marginLeft: 4,
    fontWeight: "bold",
    cursor: "pointer"
  },

  sectionParentSectionLabel: {
    ...SubText,
    cursor,
    textAlign: center,
    marginBottom: sizing * 2,
    marginLeft: sizing,
    marginRight: sizing,
    paddingTop: sizing,
  },

  globalAchievementsContainer: (mobile) ? ({
    padding: sizing * 2
  }) : ({
    padding: sizing * 2,
    margin: sizing * 2,
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    borderRadius: sizing,
  }),

  globalAchievementsLabel: {
    textAlign: "center"
  },

  personalAchievementsContainer: (mobile) ? ({
    padding: sizing * 2
  }) : ({
    padding: sizing * 2,
    margin: sizing * 2,
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    borderRadius: sizing,
  }),

  personalAchievementsLabel: {
    textAlign: "center"
  },

  groupAchievementsContainer: (mobile) ? ({
    padding: sizing * 2
  }) : ({
    padding: sizing * 2,
    margin: sizing * 2,
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    borderRadius: sizing,
  }),

  groupAchievementsLabel: {
    textAlign: "center"
  },

  suggestedAchievementsContainer: (mobile) ? ({
    padding: sizing * 2
  }) : ({
    padding: sizing * 2,
    margin: sizing * 2,
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    borderRadius: sizing,
  }),

  suggestedAchievementsLabel: {
    textAlign: "center"
  },

  suggestionsPageAchievementsContent: (mobile) ? ({
    padding: sizing * 2
  }) : ({
    paddingTop: sizing * 2,
    paddingBottom: sizing * 2,
    margin: sizing * 2,
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    borderRadius: sizing,
    width: "100%"
  }),

  suggestionsPageAchievementsLabel: {
    textAlign: "center"
  },

  suggestionsPageSectionsContent: (mobile) ? ({
    padding: sizing * 2,
    width: "100%",
  }) : ({
    paddingTop: sizing * 2,
    paddingBottom: sizing * 2,
    margin: sizing * 2,
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    borderRadius: sizing,
    width: "100%",
    boxSizing: "border-box",
  }),

  suggestionsPageSectionsLabel: {
    textAlign: "center"
  },

  personalAchievementsPage: {
    ...CenterContentColumn,
    paddingTop: 60
  },

  personalAchievementsPageHeader: {
    ...H1,
    color: PrimaryColorMedium,
    marginBottom: sizing * 2
  },

  personalAchievementsPageCreateSectionButton: {
    ...MainSubSectionButton,
    background: PrimaryColorMedium,
    borderRadius: sizing,
    margin: sizing,
    boxShadow,
    color: White
  },

  personalAchievementsPageCreateAchievementButton: {
    ...MainSubSectionButton,
    background: PrimaryColorMedium,
    borderRadius: sizing,
    margin: sizing,
    boxShadow,
    color: White
  },

  personalSectionsAndAchievementsContainer: (mobile) ? ({
    ...CenterContentColumn
  }) : ({
    display: "flex"
  }),

  personalAchievementsSectionContainer: {
    ...CenterContentColumn,
    justifyContent: "start"
  },

  personalAchievementsSectionContent: (mobile) ? ({
    ...CenterContentColumn,
    paddingTop: sizing * 2,
    paddingBottom: sizing * 2,
    width: "100%"
  }) : ({
    padding: sizing * 2,
    margin: sizing * 2,
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    borderRadius: sizing,
    boxSizing: "border-box",
    width: "100%"
  }),

  personalAchievementsSectionLabel: {
    textAlign: "center",
    color: Black,
  },

  personalAchievementsAchievementContainer: {
    ...CenterContentColumn,
    justifyContent: "start"
  },

  personalAchievementsAchievementContent: (mobile) ? ({
    padding: sizing * 2
  }) : ({
    padding: sizing * 2,
    margin: sizing * 2,
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    borderRadius: sizing,
  }),

  personalAchievementsAchievementLabel: {
    textAlign: "center",
    color: Black,
  },

  personalAchievementsPageNoSectionsMessage: {
    maxWidth: sizing * 42,
    marginTop: sizing * 2,
    textAlign: "center"
  },

  personalAchievementsPageNoAchievementsMessage: {
    maxWidth: sizing * 42,
    marginTop: sizing * 2,
    textAlign: "center"
  },

  groupPage: {
    ...CenterContentColumn,
    paddingTop: sizing * 6,
  },

  groupPageHeader: {
    ...H1,
    color: GroupPrimaryColor,
    marginTop: sizing * 2
  },

  groupPageTabContainer: {
    ...CenterContentColumn,
    width: "100vw",
    maxWidth: sizing * ((mobile) ? (51) : (152))
  },

  groupPageTabOptions: {
    ...CenterContent,
    width: "100%",
    justifyContent: "space-between",
    marginTop: sizing * 2
  },

  groupPageActiveTab: {
    ...tab,
    ...activeTab,
    backgroundColor: GroupSecondaryColor,
    color: Black
  },

  groupPageInactiveTab: {
    ...tab,
    backgroundColor: GroupPrimaryColor,
    color: White
  },

  groupPageRequestToJoinButton: {
    ...Button,
    marginTop: sizing * 2,
    width: sizing * 47,
  },

  groupPageJoinGroupButton: {
    ...Button,
    marginTop: sizing * 2,
    width: sizing * 47
  },

  groupPageTabContent: {
    ...ActiveTabContainer,
    backgroundColor: GroupSecondaryColor
  },

  groupPageLoadingMessageContainer: {
    ...CenterContentColumn,
    paddingTop: sizing * 6
  },

  groupPageLoadingMessage: {
    ...H1,
    color: PrimaryColorMedium,
    marginTop: sizing * 2
  },

  groupSectionsAndAchievementsContainer: (mobile) ? ({
    ...CenterContentColumn
  }) : ({
    display: "flex"
  }),

  groupAchievementsAchievementContainer: {
    ...CenterContentColumn,
    justifyContent: "start"
  },

  groupAchievementsAchievementContent: (mobile) ? ({
    paddingTop: sizing * 2,
    paddingBottom: sizing * 2
  }) : ({
    padding: sizing * 2,
    margin: sizing * 2,
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    borderRadius: sizing,
  }),

  groupAchievementsAchievementLabel: {
    textAlign: "center",
    color: Black,
  },

  groupAchievementsSectionContainer: {
    ...CenterContentColumn,
    justifyContent: "start"
  },

  groupAchievementsSectionContent: (mobile) ? ({
    padding: sizing * 2
  }) : ({
    padding: sizing * 2,
    margin: sizing * 2,
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    borderRadius: sizing,
    boxSizing: "border-box",
    width: "100%"
  }),

  groupAchievementsSectionLabel: {
    textAlign: "center",
    color: Black,
  },

  groupAchievementsPageNoSectionsMessage: {
    maxWidth: sizing * 42,
    marginTop: sizing * 2,
    textAlign: "center",
    color: Black
  },

  groupAchievementsPageNoAchievementsMessage: {
    maxWidth: sizing * 42,
    marginTop: sizing * 2,
    textAlign: "center",
    color: Black
  },

  createEditSectionPage: {
    ...CenterContentColumn,
    paddingTop: 60
  },

  createEditSectionPageNameContainer: {
    width: sizing * 42
  },

  createEditSectionPageParentSelectMenu: {
    top: sizing * 5,
    borderRadius: 0,
    bordeerWidth: 0
  },

  createEditSectionPageParentSelectInput: {
    height: 39,
    paddingTop: sizing,
    fontFamily: "'Times New Roman', serif"
  },

  createEditSectionPageParentSelectControl: {
    borderWidth: 0,
    borderRadius: 0,
    boxShadow
  },

  createEditSectionPageHeader: {
    ...H1,
    marginBottom: sizing * 2
  },

  createEditSectionPageNameLabel: {
    ...H3,
    width: "100%"
  },

  createEditSectionPageParentLabel: {
    ...H3,
    width: "100%"
  },

  createEditSectionPageAddAchievementsLabel: {
    ...H3,
    width: "100%"
  },

  createEditSectionPageAddAchievement: {
    ...achievementButton,
    ...CenterContent,
    marginTop: sizing * 2
  },

  createEditSectionPageAddAchievementIcon: {
    ...achievementButtonIcons,
    color: Black
  },

  createEditSectionPageSubmitButton: {
    ...Button,
    background: PrimaryColorMedium
  },

  createEditSectionPageParentSelectPlaceholder: {
    font: "12px 'Times New Roman', serif"
  },

  createEditSectionPageParentOptionsLabelContainer: {
    display: flex,
    alignItems: center,
    justifyContent: "space-between",
  },

  createEditSectionPageParentOptionsCount: {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: subtext,
    fontWeight: "normal",
    lineHeight: 1,
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: center,
  },

  createEditSectionPageParentContainer: {
    width: sizing * 42
  },

  createEditSectionPageGroupSelect: {
    boxShadow,
    border: "none",
    backgroundColor: White,
    padding: sizing,
    cursor,
  },

  createEditSectionPageGroupLabel: (mobile) ? ({
    ...H3,
    marginRight: 6
  }) : ({
    ...H3,
    marginRight: sizing * 2
  }),

  createEditSectionPageGroupContainer: (mobile) ? ({
    display: "flex",
    margin: sizing,
    alignItems: center
  }) : ({
    display: "flex",
    marginBottom: sizing * 2,
    alignItems: center
  }),

  createEditAchievementPageContainer: (mobile) ? ({
    width: "100%"
  }) : ({
    width: "100%",
    maxWidth: sizing * 42
  }),

  createEditAchievementPage_SectionPage: {
    ...CenterContentColumn,
    paddingTop: sizing * 2,
    marginTop: sizing * 2
  },

  createEditAchievementPage: {
    ...CenterContentColumn,
    paddingTop: 60
  },

  createEditAchievementPageSectionContainer: (mobile) ? ({
    margin: sizing,
    marginBottom: sizing * 2
  }) : ({
    width: "100%",
    marginBottom: sizing * 2
  }),

  createEditAchievementPageSectionLabel: {
    ...H3,
    width: "100%"
  },

  createEditAchievementPageSectionSelectMenu: {
    top: sizing * 5,
    borderRadius: 0,
    bordeerWidth: 0
  },

  createEditAchievementPageSectionSelectInput: {
    height: 39,
    paddingTop: 8,
    fontFamily: "'Times New Roman', serif"
  },

  createEditAchievementPageSectionSelectControl: {
    borderWidth: 0,
    borderRadius: 0,
    boxShadow
  },

  createEditAchievementPageSectionSelectPlaceholder: {
    font: "12px 'Times New Roman', serif"
  },

  createEditAchievementPageHeader: (mobile) ? ({
    ...H1,
    margin: sizing,
    textAlign: center,
  }) : ({
    ...H1,
    marginBottom: sizing * 2
  }),

  createEditAchievementPageParentOptionsLabelContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  createEditAchievementPageParentOptionsCount: {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: subtext,
    fontWeight: "normal",
    lineHeight: 1,
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: center,
  },

  createEditAchievementPageNameLabel: {
    ...H3,
    width: "100%"
  },

  createEditAchievementPageDescriptionContainer: (mobile) ? ({
    margin: sizing
  }) : ({

  }),

  createEditAchievementPageNameContainer: (mobile) ? ({
    margin: sizing
  }) : ({

  }),

  createEditAchievementPageDescriptionLabel: {
    ...H3,
    width: "100%"
  },

  createEditAchievementPageStarsSelect: {
    boxShadow,
    border: "none",
    backgroundColor: White,
    padding: sizing,
    cursor,
  },

  createEditAchievementPageDescriptionInput: {
    ...Input,
  },

  createEditAchievementPageStarsContainer: (mobile) ? ({
    display: "flex",
    margin: sizing,
    alignItems: center
  }) : ({
    display: "flex",
    marginBottom: sizing * 2,
    alignItems: center
  }),

  createEditAchievementPageStarsLabel: {
    ...H3,
    marginRight: sizing * 2
  },

  createEditAchievementPageSubmitButton: {
    ...Button,
    background: PrimaryColorMedium,
    width: "100%",
  },

  createEditAchievementPageButtonsContainer: (mobile) ? ({
    ...CenterContent,
    margin: sizing
  }) : ({
    ...CenterContent
  }),

  achievementWorkshopPage: {
    ...CenterContentColumn,
    paddingTop: 60
  },

  achievementWorkshopPageHeader: {
    ...H1,
    color: PrimaryColorMedium,
    marginBottom: sizing * 2,
    textAlign: center,
  },

  achievementWorkshopPageHeaderIcon: {
    color: PrimaryColorMedium,
    margin: sizing * 3
  },

  achievementWorkshopPageSuggestNewAchievementButton: {
    ...MainSubSectionButton,
    ...MainSectionAltButton
  },

  achievementWorkshopPageSuggestNewSectionButton: {
    ...MainSubSectionButton,
    ...MainSectionAltButton
  },

  achievementWorkshopPageSuggestionsButton: {
    ...MainSubSectionButton,
    ...MainSectionAltButton
  },

  achievementWorkshopPageCreateNewAchievementButton: {
    ...MainSubSectionButton,
    ...MainSectionAltButton
  },

  achievementWorkshopPageCreateNewSectionButton: {
    ...MainSubSectionButton,
    ...MainSectionAltButton
  },

  achievementWorkshopPageReportsButton: {
    ...MainSubSectionButton,
    ...MainSectionAltButton,
    backgroundColor: Red
  },

  profilePage: {
    paddingTop: 60
  },

  profilePageContainer: {
    ...CenterContentColumn
  },

  profilePageUserAvatar: {
    width: (sizing * 12),
    minHeight: (sizing * 12),
    borderRadius: "50%",
  },

  profilePageUserName: {
    margin: sizing * 2
  },

  profilePageContent: {
    padding: sizing * 2,
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: (mobile) ? ("") : (sizing * 47)
  },

  profilePageTabsContainer: {
    ...CenterContent,
    width: "100%",
    justifyContent: "space-between"
  },

  profilePageUserContainer: {
    ...CenterContentColumn,
    borderBottom: "1px solid black",
    borderRadius: 8,
    marginTop: sizing,
    marginBottom: sizing,
    width: "100%"
  },

  profileActiveTabContent: {
    ...ActiveTabContainer,
  },

  profilePageStatsAchievementContainer: {
    display: "flex",
    flexDirection: (mobile) ? ("column") : ("")
  },

  profilePageStatsContainer: {
    display: "flex",
    flexDirection: "column",
    margin: (mobile) ? (0) : (sizing * 2),
    marginTop: 0,
    minWidth: (mobile) ? ("100%") : (sizing * 47),
  },

  profilePageStatsLabel: {
    margin: sizing * 2,
    textAlign: center,
  },

  profilePageAchievemnetsContainer: {
    minWidth: (mobile) ? ("") : (sizing * 47),
  },

  profilePageAchievemnetsLabel: {
    margin: sizing * 2,
    textAlign: center,
  },

  profilePageStatsContent: {
    backgroundColor: PrimaryColorMedium,
    borderRadius: sizing,
    padding: sizing * 2,
    paddingBottom: 0,
    color: White,

  },

  profilePageStatsTotalAchievementsContainer: {
    display: flex,
    justifyContent: "space-between",
    marginBottom: sizing * 2,
    borderBottom: "1px solid black",
    borderRadius: sizing / 2,
  },

  profilePageStatsTotalAchievementsLabel: {

  },

  profilePageStatsTotalAchievements: {

  },

  profilePageStatsTotalAchievementPointsContainer: {
    display: flex,
    justifyContent: "space-between",
    marginBottom: sizing * 2,
    borderBottom: "1px solid black",
    borderRadius: sizing / 2,
  },

  profilePageStatsTotalAchievementPointsLavel: {

  },

  profilePageStatsTotalAchievementPoints: {

  },

  profilePageStatsMostAchievedContainer: {

  },

  profilePageStatsMostAchievedLabel: {

  },

  profilePageStatsMostAchieved: {

  },


  supportPage: {
    paddingTop: 60
  },

  supportPageContainer: {
    flexDirection: "column",
    maxWidth: sizing * 60,
    width: "100%"
  },

  supportPageContent: {
    width: "100%",
    margin: sizing * 2
  },

  supportPageIssueTypeContainer: {
    display: "flex",
    marginBottom: 16,
    alignItems: "center"
  },

  supportPageSendButton: {
    backgroundColor: SecondaryColorMedium
  },

  supportPageIssueTypeLabel: {
    color: SecondaryColorMedium,
    marginRight: 16
  },

  supportPageHeader: {
    marginBottom: sizing * 2
  },

  supportPageIssueTypeSelect: {
    border: "none",
    backgroundColor: White,
    padding: sizing,
    color: SecondaryColorMedium,
    cursor,
  },

  supportPageNotes: {
    width: "100%",
    height: "60vh",
    padding: sizing * 2,
    boxSizing: "border-box",
  },

  supportPageSendButtonContainer: {
    marginTop: sizing * 2
  },

  /*-----REPORTS-----*/

  report: {
    ...achievementTemplate,
    ...Text,
    color: White,
    padding: sizing * 2
  },

  reportTopContainer: {
    display: flex,
    justifyContent: "space-between"
  },

  reportSubjectNameContainer: {
    display: flex,
    marginTop: sizing * 2
  },

  reportNotesContainer: {
    marginTop: sizing * 2,
    marginBottom: sizing * 2
  },

  reportNotesLabel: {
    marginBottom: sizing * 2
  },

  reportButtonsContainer: {
    display: "flex",
    justifyContent: "space-between"
  },

  reportRemoveButton: {
    cursor,
    width: sizing * 3,
    height: sizing * 3
  },

  reportResolveButton: {
    cursor,
    width: sizing * 3,
    height: sizing * 3
  },

  reportLink: {
    cursor,
    width: sizing * 3,
    height: sizing * 3
  },

  /*-----SECTIONS-----*/

  /*-----SESSION-----*/

  signUpComponent: {
    ...CenterContentColumn
  },

  signUpComponentTopContainer: {
    ...CenterContent,
    width: "100%",
    marginBottom: sizing * 3
  },

  signUpComponentHeader: {
    ...H2
  },

  signUpComponentClose: {
    marginRight: sizing * 3,
    cursor,
    position: "absolute",
    right: 0,
    fontFamily: "sans-serif",
    ...H2
  },

  /*-----USER-----*/

  sideMenu: {

  },

  sideMenuArrowDownContainer: {
    paddingLeft: sizing
  },

  sideMenuArrowRightContainer: {
    paddingLeft: sizing
  },

  sideMenuArrowDown: {

  },

  sideMenuArrowRight: {

  },

  navSectionSearchContainer: {
    width: (mobile) ? ("100%") : (200)
  },

  navSectionSearchMenu: {
    top: sizing * 5,
    borderRadius: 0,
    bordeerWidth: 0
  },

  navSectionSearchInput: {
    height: 39,
    paddingTop: sizing,
    fontFamily: "'Times New Roman', serif"
  },

  navSectionSearchControl: {
    borderWidth: 0,
    borderRadius: 0,
    backgroundColor: (mobile) ? (SecondaryColorLight) : (White),
  },

  navSectionSearchPlaceholder: {
    font: "12px 'Times New Roman', serif"
  },

  sideMenuSectionSearchContainer: {
    width: (mobile) ? ("100%") : (200)
  },

  sideMenuSectionSearchMenu: {
    top: sizing * 5,
    borderRadius: 0,
    bordeerWidth: 0
  },

  sideMenuSectionSearchInput: {
    height: 39,
    paddingTop: sizing,
    fontFamily: "'Times New Roman', serif"
  },

  sideMenuSectionSearchControl: {
    borderWidth: 0,
    borderRadius: 0,
    backgroundColor: (mobile) ? (SecondaryColorLight) : (White)
  },

  sideMenuSectionSearchPlaceholder: {
    font: "12px 'Times New Roman', serif"
  },

  sectionSearchLabelContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  sectionSearchLength: {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: subtext,
    fontWeight: "normal",
    lineHeight: 1,
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: center,
  },

  sideMenuSectionSearchContent: {
    position: "absolute",
    top: sizing * 6,
    left: 0,
    width: "100%"
  },

  sideMenuTopSpacer: {
    height: sizing * 6
  },

  favoriteAchievementsNoFavoritesMessage: {
    padding: sizing * 2,
    color: White
  },

  favoriteAchievementsNoRecentsMessage: {
    padding: sizing * 2,
    color: White
  },

  favoriteAchievementsNoTrackingsMessage: {
    padding: sizing * 2,
    color: White
  },

  sideMenuBookmarkedSectionsLabel: {
    padding: sizing,
    fontSize: text,
    fontWeight: 600,
    color: PrimaryColorMedium
  },

  sideMenuBookmarkedSections: {
    display: flex,
    flexWrap: "wrap"
  },

  sideMenuSettingsLabel: {
    padding: sizing,
    fontSize: text,
    fontWeight: 600,
    color: PrimaryColorMedium
  },

  sideMenuSettings: {
    display: flex,
    flexWrap: "wrap"
  },

  sideMenuGroupsLabel: {
    padding: sizing,
    fontSize: text,
    fontWeight: "bold",
    color: GroupPrimaryColor
  },

  sideMenuAbout: {
    padding: sizing,
    fontSize: text,
    fontWeight: 600
  },

  sideMenuSupport: {
    padding: sizing,
    fontSize: text,
    fontWeight: 600
  },

  sideMenuPPAndPOS: {
    padding: sizing,
    fontSize: text,
    fontWeight: 600
  },

  profileSettings: {
    width: "100%"
  },

  profileSettingsGeneralOptions: {
    color: Black,
    marginLeft: 16,
    marginTop: 16,
    textAlign: "center",
    borderBottom: "1px solid black",
    borderRadius: 8,
  },

  profileSettingsPersonalOptions: {
    color: Black,
    marginLeft: 16,
    marginTop: 16,
    textAlign: "center",
    borderBottom: "1px solid black",
    borderRadius: 8,
  },

  profileSettingsChangeDisplayNameButton: {
    ...Button,
    margin: sizing * 2
  },

  profileSettingsChangeAvatarButton: {
    ...Button,
    margin: sizing * 2
  },

  profileSettingsCopiedTooltip: {
    position: "absolute",
    right: sizing * 6
  },

  profileSettingsShareProfileContainer: {
    color: Black,
    display: flex,
    justifyContent: "space-between",
    padding: sizing * 2,
    cursor,
    position: "relative"
  },

  profileSettingsLogoutButton: {
    ...Button,
    margin: sizing * 2
  },

  profileSettingsMakePrivateContainer: {
    color: Black,
    display: flex,
    justifyContent: "space-between",
    padding: sizing * 2,
    cursor
  },

  line: {
    width: "100%",
    border: "0.5px solid black",
    margin: `${sizing*2}px 0px`,
  },

  sectionsOptionsButton: {

  },

  groupMenuItem: {
    paddingLeft: sizing * 2,
    paddingTop: sizing * 2,
    marginTop: sizing,
    width: "100%",
  },

  bookmarkMenuItem: {
    paddingLeft: sizing * 2,
    paddingTop: sizing * 2,
    marginTop: sizing,
    width: "100%",
  },

  groupInviteMenuItem: {
    paddingLeft: sizing * 2,
    paddingTop: sizing * 2,
    marginTop: sizing,
    width: "100%",
    borderTop: "1px solid black",
    position: "relative"
  },

  groupInviteMenuItemNotificationDot: {
    ...redNotificationDot,
    position: "absolute",
    left: 0,
    top: 0
  },

};
