import React, { useState, useEffect } from "react";

import { connect }   from "react-redux";

import dispatcher    from "../../redux/dispatcher.js";

import LinkComponent from "./LinkComponent.js";

import { FontAwesomeIcon }     from "@fortawesome/react-fontawesome";
import {
  faPlus
} from "@fortawesome/free-solid-svg-icons";

import styles        from "../../styles/styles.js";

function LinkDetailsComponent(props){

  const {
    details,
    subject,
    user_info,
    showDetails,
    achievement,
  } = props;

  const [addAdminLinkOpen, setAddAdminLinkOpen] = useState(false);
  const [addPersonalLinkOpen, setAddPersonalLinkOpen] = useState(false);
  const collection = details.collection;

  useEffect(()=>{
    if (collection !== "personal"){
      dispatcher("GET_LINKS", {
        related_id: details.achievement_id,
        collection
      });
    }

    if (collection !== "suggested"){
      dispatcher("GET_LINKS", {
        related_id: details.achievement_id,
        user_id: user_info.uid,
        collection: "personal"
      });
    }
  }, []);

  useEffect(()=>{
    const {
      collection,
    } = details;


    if (collection !== "personal"){
      dispatcher("GET_LINKS", {
        related_id: details.achievement_id,
        collection
      });
    }

    if (collection !== "suggested"){
      dispatcher("GET_LINKS", {
        related_id: details.achievement_id,
        user_id: user_info.uid,
        collection: "personal"
      });
    }
  }, [showDetails]);

  const loginModal = ()=>{
    dispatcher("ADD_MODAL", {
      name: "SESSION",
      modalProps: {
        login: true,
      }
    });
  };

  const toggleAddPersonalLink = ()=>{

    if (!user_info.uid){
      dispatcher("CLOSE_DETAILS");

      loginModal();
    } else {
      setAddPersonalLinkOpen(!addPersonalLinkOpen);
    }
  };

  const toggleAddAdminLink = ()=>{
    setAddAdminLinkOpen(!addAdminLinkOpen);
  };

  if (!subject){
    return null;
  }

  let TopLinks;

  let adminAddButtonStyle;
  let link_collection;

  if (achievement.group_id){
    adminAddButtonStyle = {backgroundColor: styles.GroupSecondaryColor};
    link_collection = "group";
  } else if (achievement.suggesting_user_id){
    adminAddButtonStyle = {backgroundColor: styles.Grey};
    link_collection = "suggested";
  } else {
    adminAddButtonStyle = {backgroundColor: styles.SecondaryColorLight};
    link_collection = "global";
  }

  if (!achievement.user_id){
    let AddAdminLinks;
    if (addAdminLinkOpen  && user_info.admin){
      AddAdminLinks = (
        <LinkComponent
          details={details}
          link_collection={link_collection}
          toggleAddAdminLink={toggleAddAdminLink}
          subject={subject}
        />
      );
    } else if (user_info.admin){
      AddAdminLinks = (
        <div
          id="link-details-admin-add"
          style={{...styles.linkDetailsAdminAdd}}
          onClick={toggleAddAdminLink}
        >
          <FontAwesomeIcon
            id="link-details-admin-add-icon"
            style={{...styles.linkDetailsAdminAddIcon, ...adminAddButtonStyle}}
            icon={faPlus}
          />
        </div>
      );
    }

    const adminLinks = achievement[subject] && !achievement.user_id && Object.keys(achievement[subject]).filter((link_id)=>{

      return (
        (achievement[subject][link_id].link_collection === "global")
        ||
        (achievement[subject][link_id].link_collection === "group")
        ||
        (achievement[subject][link_id].link_collection === "suggested")
        // ((achievement[subject][link_id].link_collection === "suggested") && (this.props.location.pathname.includes("suggested")))
      ) && (!achievement[subject][link_id].deleted);
    }) || [];

    TopLinks = (
      <div id="link-details-admin-container" style={{...styles.linkDetailsAdminContainer}}>

        <div id="link-details-admin-content"style={{...styles.linkDetailsAdminContent}}>

          <div id="link-details-admin-header" style={{...styles.linkDetailsAdminHeader}}>
            Global Links
          </div>

          {(adminLinks.length) ? (adminLinks.map((link_id)=>{
            const link_data = achievement[subject][link_id];

            return (
              <LinkComponent
                key={link_id}
                link_collection={link_collection}
                link_data={link_data}
                subject={subject}
              />
            );
          })) : (
            <div id="link-details-no-admin-links" style={{...styles.linkDetailsNoAdminLinks}}>There are no links for this yet.</div>
          )}
        </div>

        {AddAdminLinks}

        {(achievement.suggesting_user_id) ? (null) : (
          <div className="line" style={{...styles.line}}></div>
        )}
      </div>
    );
  }

  const personalLinks = achievement[subject] && Object.keys(achievement[subject]).filter((link_id)=>{
    return (achievement[subject][link_id].link_collection === "personal") && (!achievement[subject][link_id].deleted) && (achievement[subject][link_id].user_id === user_info.uid);
  }) || [];

  return (
    <div id="link-details"style={{...styles.detailsStyle}}>

      {TopLinks}

      {(achievement.suggesting_user_id) ? (null) : (
        <div id="link-details-personal-container" style={{...styles.linkDetailsPersonalContainer}}>

          <div id="link-details-personal-header" style={{...styles.linkDetailsPersonalHeader}}>
            Personal Links
          </div>

          <div id="link-details-personal-content" style={{...styles.linkDetailsPersonalContent}}>
            {(personalLinks.length) ? (personalLinks.map((link_id)=>{
              const link_data = achievement[subject][link_id];

              return (
                <LinkComponent
                  key={link_id}
                  link_collection={"personal"}
                  link_data={link_data}
                  subject={subject}
                />
              );
            })) : (
              <div id="link-details-no-personal-links" style={{...styles.linkDetailsNoPersonalLinks}}>There are no links for this yet.</div>
            )}
          </div>

          {(addPersonalLinkOpen) ? (
            <LinkComponent
              details={details}
              link_collection={"personal"}
              toggleAddPersonalLink={toggleAddPersonalLink}
              subject={subject}
            />
          ) : (
            <div
              id="link-details-personal-add"
              style={{...styles.linkDetailsPersonalAdd}}
              onClick={toggleAddPersonalLink}
            >
              <FontAwesomeIcon
                id="link-details-personal-add-icon"
                style={{...styles.linkDetailsPersonalAddIcon}}
                icon={faPlus}
              />
            </div>
          )}

        </div>
      )}

    </div>
  );
}

const mapState = (state)=>{

  const hackToUpdate = Math.random();

  return {
    user_info: state.sessionReducer.user_info,
    groups: state.sessionReducer.user_info.groups,
    showDetails: state.sessionReducer.showDetails,
    details: state.sessionReducer.details || {},
    hackToUpdate,
  };
};

export default connect(mapState)(LinkDetailsComponent);
