import React               from "react";

import { connect }         from "react-redux";

import { useNavigate }     from "react-router-dom";

import styles              from "../../styles/styles.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools }         from "@fortawesome/free-solid-svg-icons";

function AchievementWorkshopPage(props){

  const history = useNavigate();

  const goTo = (workshot_section)=>{
    history(`/achievement-workshop/${workshot_section}`);
  };

  const { user_info } = props;

  return(
    <div id="achievement-workshop-page" style={{...styles.achievementWorkshopPage}}>

      <FontAwesomeIcon
        id="achievement-workshop-page-header-icon"
        icon={faTools}
        size="3x"
        style={{...styles.achievementWorkshopPageHeaderIcon}}
      />

      <div id="achievement-workshop-page-header" style={{...styles.achievementWorkshopPageHeader}}>Achievement Workshop</div>

      <div
        id="achievement-workshop-page-suggest-new-achievement-button"
        style={{...styles.achievementWorkshopPageSuggestNewAchievementButton}}
        onClick={()=>{
          goTo("create/achievement/suggested");
        }}
      >
        Suggest New Achievement
      </div>

      <div
        id="achievement-workshop-page-suggest-new-section-button"
        style={{...styles.achievementWorkshopPageSuggestNewSectionButton}}
        onClick={()=>{
          goTo("create/section/suggested");
        }}
      >
        Suggest New Section
      </div>

      <div
        id="achievement-workshop-page-suggestions-button"
        style={{...styles.achievementWorkshopPageSuggestionsButton}}
        onClick={()=>{
          goTo("suggestions");
        }}
      >
        Suggestions
      </div>

      {(user_info.admin) && (
        <React.Fragment>
          <div
            id="achievement-workshop-page-create-new-achievement-button"
            style={{...styles.achievementWorkshopPageCreateNewAchievementButton}}
            onClick={()=>{
              goTo("create/achievement/global");
            }}
          >
            Create New Achievement
          </div>

          <div
            id="achievement-workshop-page-create-new-section-button"
            style={{...styles.achievementWorkshopPageCreateNewSectionButton}}
            onClick={()=>{
              goTo("create/section/global");
            }}
          >
            Create New Section
          </div>

          <div
            id="achievement-workshop-page-reports-button"
            style={{...styles.achievementWorkshopPageReportsButton}}
            onClick={()=>{
              goTo("reports");
            }}
          >
            Reports
          </div>
        </React.Fragment>
      )}

    </div>
  );
}


const mapState = (state)=>{
  return {
    user_info: state.sessionReducer.user_info
  };
};

export default connect(mapState)(AchievementWorkshopPage);
