import React     from "react";

import styles    from "../../styles/styles.js";

function GroupInviteMenuItem(props){

  const {
    onClick,
    group_invite,
  } = props;

  return (
    <div
      className="group-invite-menu-item"
      style={{...styles.groupInviteMenuItem}}
      onClick={onClick}
    >
      <div className="group-invite-menu-item-notification-dot" style={{...styles.groupInviteMenuItemNotificationDot}}></div>
      {group_invite.group_name}
    </div>
  );
}

export default GroupInviteMenuItem;
