import React          from "react";

import dispatcher     from "../../redux/dispatcher.js";

import styles         from "../../styles/styles.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCogs,
  faUserShield,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";

function GroupMemberCard(props){

  const {
    member,
    viewer,
    admins,
  } = props;

  const openMemberOptions = ()=>{
    dispatcher("ADD_MODAL", {
      name: "GROUP_MEMBER_DETAILS",
      modalProps: {
        ...props,
        onRequestClose: ()=>{
          dispatcher("REMOVE_MODAL", "GROUP_MEMBER_DETAILS");
        }
      }
    });
  };

  const name = (viewer === "admin" && member.status !== "joined" && member.status !== "request") ? (member.invitation_sent_to) : (member.display_name);

  const member_is_admin = admins.includes(member.user_id);

  const status = (member.status === "declined") ? (
    <div className="group-member-card-status" style={{...styles.groupMemberCardStatus}}>
      declined
    </div>
  ) : (null);

  return (
    <div
      className="group-member-card"
      style={{...styles.groupMemberCard}}
      onClick={openMemberOptions}
    >

      <div className="group-member-card-content" style={{...styles.groupMemberCardContent}}>
        <div className="group-member-card-name" style={{...styles.groupMemberCardName}}>
          {name}
          {(member_is_admin) ? (
            <FontAwesomeIcon
              className="group-member-card-admin-icon"
              style={{...styles.groupMemberCardAdminIcon}}
              icon={faUserShield}
            />
          ) : (null)}
        </div>
        <FontAwesomeIcon
          className="group-member-card-info-settings-icon"
          style={{...styles.groupMemberCardInfoSettingsIcon}}
          icon={(viewer === "non-member") ? (faInfoCircle) : (faCogs)}
        />
      </div>

      <div className="group-member-card-status-container" style={{...styles.groupMemberCardStatusContainer}}>
        {status}
      </div>
    </div>
  );
}

export default GroupMemberCard;
