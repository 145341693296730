import React     from "react";

import { useNavigate } from "react-router-dom";

import utility   from "../../functions/utility.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrophy
}  from "@fortawesome/free-solid-svg-icons";

import styles    from "../../styles/styles.js";

function SectionCard(props){

  const { section } = props;
  const history = useNavigate();

  const onClick = ()=>{

    let collection = "global/";
    if (section.user_id){
      collection = "personal/";
    } else if (section.group_id){
      collection = "group/";
    } if (section.suggesting_user_id){
      collection = "suggested/";
    }

    history(`/achievements/${collection}${section.section_id}`);
  };

  let sectionIconStyle;
  let sectionContainerStyle = {...styles.sectionContainer};
  let sectionNameStyle;
  let sectionFontColor;

  let suggestion_text;

  if (section.user_id){

    sectionIconStyle = {
      background: `url(${(section.icon || "")}) center center / contain no-repeat`,
      ...styles.sectionIcon,
      backgroundColor: styles.PersonalSecondaryColor,
      color: styles.Black
    };
    sectionContainerStyle = {
      ...sectionContainerStyle,
      ...styles.personalSectionContainer
    };
    sectionNameStyle = {...styles.sectionName, ...{color: "white"}};
    sectionFontColor = {color: styles.White};

  } else if (section.group_id){

    sectionIconStyle = {
      background: `url(${(section.icon || "")}) center center / contain no-repeat`,
      ...styles.sectionIcon,
      backgroundColor: styles.GroupSecondaryColor,
      color: styles.Black
    };
    sectionContainerStyle = {
      ...sectionContainerStyle,
      ...styles.groupSectionContainer
    };
    sectionNameStyle = {...styles.sectionName, ...{color: "white"}};
    sectionFontColor = {color: styles.White};

  } else if (section.suggesting_user_id){
    sectionIconStyle = {
      background: `url(${(section.icon || "")}) center center / contain no-repeat`,
      ...styles.sectionIcon,
      color: styles.Black,
      backgroundColor: styles.White
    };
    sectionContainerStyle = {
      ...sectionContainerStyle,
      backgroundColor: styles.Grey,
      maxWidth: (window.screen.width) ? (8 * 37) : (8 * 42)
    };
    sectionNameStyle = {...styles.sectionName, ...{}};
    sectionFontColor = {color: styles.White};
    suggestion_text = (
      <div className="section-card-details" style={{...styles.sectioncardDetails}}>
        {(window.screen.width < 800) ? ("Tap") : ("Click")} for details
      </div>
    );
  } else {

    sectionIconStyle = {
      background: `url(${(section.icon || "")}) center center / contain no-repeat`,
      ...styles.sectionIcon,
      color: styles.Black
    };
    sectionNameStyle = {...styles.sectionName, ...{}};
    sectionFontColor = {color: styles.White};

  }

  return (
    <div
      className="section-card"
      style={{...styles.sectionCard, ...sectionContainerStyle}}
      onClick={onClick}
    >
      <div className="section-card-container" style={{...styles.sectionCardContainer}}>

        <div className="section-card-icon-container" style={{...sectionIconStyle, ...styles.sectionCardIconContainer}}>
          <FontAwesomeIcon
            className="section-card-icon"
            size="2x"
            icon={faTrophy}
          />
        </div>

        <div className="section-card-name" style={{...styles.sectionCardName, ...sectionNameStyle, ...sectionFontColor}}>
          {utility.upperCase(section.name)}
        </div>

      </div>

      {suggestion_text}
    </div>
  );
}

export default SectionCard;
