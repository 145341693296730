import React from "react";

import Modal from "react-modal";

import ConfirmationComponent from "./ConfirmationComponent.js";

export default class ErrorBoundary extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error){
    return {
      hasError: true,
      error
    };
  }

  onRequestClose = ()=>{
    window.location.href = "/";
  };

  render(){
    const { error, hasError } = this.state;

    if (hasError){
      return (
        <Modal
          isOpen={true}
          className={"react-modal-class-name"}
          onRequestClose={this.onRequestClose}
          closeTimeoutMS={150}
        >
          <ConfirmationComponent
            header={"Something went wrong..."}
            message={"There was an error when trying to perform this action. Please try again later. If you encounter this issue again, please report the issue on the support page."}
            messageTwo={`Technical error: ${error.message}`}
            okButton={true}
            onRequestClose={this.onRequestClose}
          />
        </Modal>
      );
    }

    return this.props.children;
  }
}
