export default (state = {}, action)=>{

  switch (action.type){
    case "ADD_MODAL": {

      let new_state = Object.assign({}, state);

      new_state[action.data.name] = action.data;

      return new_state;
    }
    case "REMOVE_MODAL": {
      let new_state = Object.assign({}, state);

      delete new_state[action.data];

      return new_state;
    }
    case "SERVER_ERROR": {
      let new_state = Object.assign({}, state);

      new_state.SERVER_ERROR = {
        name: "SERVER_ERROR",
        ...action.data
      };

      return new_state;
    }
    case "EMPTY_MODALS": {
      return {};
    }
    case "LOGOUT": {
      return {};
    }
    default: {
      return state;
    }
  }
};
