import { createStore, combineReducers, compose, applyMiddleware } from "redux";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web and AsyncStorage for react-native

import thunk              from "redux-thunk";

import modalReducer       from "./reducers/modals.js";
import errorsReducer      from "./reducers/errors.js";
import groupReducer       from "./reducers/groups.js";
import sessionReducer     from "./reducers/session.js";
import sectionReducer     from "./reducers/sections.js";
import achievementReducer from "./reducers/achievements.js";

const persistConfig = {
  key: "root",
  storage,
};

const reducers = combineReducers({
  modalReducer,
  errorsReducer,
  groupReducer,
  sessionReducer,
  sectionReducer,
  achievementReducer
});

const persistedReducer = persistReducer(persistConfig, reducers);

const enhancer = (window.__REDUX_DEVTOOLS_EXTENSION__) ? (
  compose(
    applyMiddleware(
      thunk,
    ),
    window.__REDUX_DEVTOOLS_EXTENSION__()
  )
) : (
  compose(
    applyMiddleware(
      thunk,
    )
  )
);

export const store = createStore(
  persistedReducer,
  enhancer
);
export const persistor = persistStore(store);
