import React, { useState, useEffect } from "react";
import { connect }          from "react-redux";

import { useParams }        from "react-router-dom";

import dispatcher           from "../../redux/dispatcher.js";

import RecentAchievements   from "../user/RecentAchievements.js";
import TrackingAchievements from "../user/TrackingAchievements.js";
import FavoriteAchievements from "../user/FavoriteAchievements.js";

import styles               from "../../styles/styles.js";

function ProfilePage(props){

  const {
    user_info,
    viewed_profile,
    sectionsReducer,
    achievementReducer,
  } = props;

  const [selected_tab, setSelectedTab] = useState("recent");

  const params = useParams();

  useEffect(()=>{
    if (params.uid){
      dispatcher("GET_VIEWED_PROFILE_INFO", {
        user_id: params.uid
      });
    } else {
      // dispatcher("GET_USER_INFO");
    }
  }, []);

  useEffect(()=>{
    return ()=>{
      dispatcher("FLUSH_VIEWED_PROFILE");
      dispatcher("FLUSH_VIEWED_PROFILE_ACHIEVEMENTS");
    };
  }, []);

  const switchTab = (new_selected_tab)=>{
    setSelectedTab(new_selected_tab);
  };

  const getActiveTabContent = ()=>{

    let TabContent;
    if (selected_tab === "favorites"){
      TabContent = (
        <FavoriteAchievements viewing_profile={params.uid}/>
      );
    } else if (selected_tab === "tracking"){
      TabContent = (
        <TrackingAchievements viewing_profile={params.uid}/>
      );
    } else if (selected_tab === "recent"){
      TabContent = (
        <RecentAchievements viewing_profile={params.uid}/>
      );
    }

    return TabContent;
  };

  const getAchievementStats = ()=>{

    const user_achievements = achievementReducer.user_achievements;

    let completedNumber = 0;
    let scoreNumber = 0;
    // let achievedCatagories = {};

    Object.keys(sectionsReducer.personal_sections).filter((section_id)=>{
      const section = sectionsReducer.personal_sections[section_id];

      return ((section.parent === params.section) && (section.deleted !== "true"));
    });

    Object.keys(user_achievements).filter((achievement_id)=>{
      const collection = user_achievements[achievement_id].collection;
      const achievement = achievementReducer[collection + "_achievements"][achievement_id];

      return achievement && !achievement.deleted;
    }).forEach((achievement_id)=>{
      const collection = user_achievements[achievement_id].collection;
      const achievement = achievementReducer[collection + "_achievements"][achievement_id];

      const user_achievement = user_achievements[achievement_id];

      const completed = user_achievement && user_achievement.completed;

      if (completed){
        completedNumber++;
        scoreNumber = scoreNumber + parseInt(achievement.stars);
        // if (achievementCatagories[sectionReducer[]]){

        // }
      }
    });

    return {
      completedNumber,
      scoreNumber,
    };

  };


  const activeTabStyle = {...styles.tab, ...styles.activeTab};
  const inactiveTabStyle = {...styles.tab};

  const recentTabStyle    = (selected_tab === "recent") ? (activeTabStyle) : (inactiveTabStyle);
  const trackingTabStyle  = (selected_tab === "tracking") ? (activeTabStyle) : (inactiveTabStyle);
  const favoritesTabStyle = (selected_tab === "favorites") ? (activeTabStyle) : (inactiveTabStyle);

  const activeTabContent = getActiveTabContent();

  const AchievementStats = getAchievementStats();

  const current_profile = (params.uid) ? (viewed_profile) : (user_info);

  const userAvatarStyle = {
    ...{
      background: `url(${(current_profile.avatar || "../images/no-avatar.png")}) center center / contain no-repeat`
    },
    ...styles.profilePageUserAvatar
  };

  return (
    <div id="profile-page" style={{...styles.profilePage}}>

      <div id="profile-page-container" style={{...styles.profilePageContainer}}>
        <div id="profile-page-content" style={{...styles.profilePageContent}}>

          <div id="profile-page-user-container" style={{...styles.profilePageUserContainer}}>
            <div id="profile-page-user-avatar" style={userAvatarStyle}></div>
            <div id="profile-page-user-name" style={{...styles.profilePageUserName}}>{current_profile.display_name}</div>
          </div>

          <div id="profile-page-stats-achievement-container" style={{...styles.profilePageStatsAchievementContainer}}>

            <div id="profile-page-achievements-container" style={{...styles.profilePageAchievemnetsContainer}}>
              <div id="profile-page-achievements-label" style={{...styles.profilePageAchievemnetsLabel}}>Achievements</div>

              <div id="profile-page-tabs-container" style={{...styles.profilePageTabsContainer}}>

                <div id="profile-page-recent-tab" onClick={()=>{switchTab("recent");}} style={recentTabStyle}>
                  Recent
                </div>
                <div id="profile-page-favorites-tab" onClick={()=>{switchTab("favorites");}} style={favoritesTabStyle}>
                  Favorites
                </div>
                <div id="profile-page-tracking-tab" onClick={()=>{switchTab("tracking");}} style={trackingTabStyle}>
                  Tracking
                </div>

              </div>

              <div id="profile-page-tab-content" style={{...styles.profileActiveTabContent}}>
                {activeTabContent}
              </div>

            </div>

            <div id="profile-page-stats-container" style={{...styles.profilePageStatsContainer}}>
              <div id="profile-page-stats-label" style={{...styles.profilePageStatsLabel}}>Stats</div>
              <div id="profile-page-stats-content" style={{...styles.profilePageStatsContent}}>

                <div id="profile-page-stats-total-achievements-container" style={{...styles.profilePageStatsTotalAchievementsContainer}}>
                  <div id="profile-page-stats-total-achievements-label" style={{...styles.profilePageStatsTotalAchievementsLabel}}>Total Achievements: </div>
                  <div id="profile-page-stats-total-achievements" style={{...styles.profilePageStatsTotalAchievements}}>
                    {AchievementStats.completedNumber}
                  </div>
                </div>

                <div id="profile-page-stats-total-achievement-points-container" style={{...styles.profilePageStatsTotalAchievementPointsContainer}}>
                  <div id="profile-page-stats-total-achievement-points-label" style={{...styles.profilePageStatsTotalAchievementPointsLavel}}>Total Achievement Stars: </div>
                  <div id="profile-page-stats-total-achievement-points" style={{...styles.profilePageStatsTotalAchievementPoints}}>
                    {AchievementStats.scoreNumber}
                  </div>
                </div>

                {/*<div id="profile-page-stats-most-achieved-container" style={{...styles.profilePageStatsMostAchievedContainer}}>
                <div id="profile-page-stats-most-achieved-label" style={{...styles.profilePageStatsMostAchievedLabel}}>Most Achieved Catagory: </div>
                <div id="profile-page-stats-most-achieved" style={{...styles.profilePageStatsMostAchieved}}>

                </div>
                </div>*/}

              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  );
}

const mapState = (state)=>{
  return {
    loggedIn: state.sessionReducer.loggedIn,
    user_info: state.sessionReducer.user_info,
    viewed_profile: state.sessionReducer.viewed_profile,
    achievementReducer: state.achievementReducer,
    sectionsReducer: state.sectionReducer,
  };
};

export default connect(mapState)(ProfilePage);
