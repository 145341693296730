import React, { useState } from "react";

import { connect }   from "react-redux";

import dispatcher    from "../../redux/dispatcher.js";

import styles        from "../../styles/styles.js";

function ReportDetailsComponent(props){

  const {
    details,
    section,
    achievement,
    user_info,
  } = props;

  const [notes, setNotes] = useState("");
  const [type, setType] = useState("");
  const [errors, setErrors] = useState({});

  const validateReport = ()=>{

    let new_errors = {};

    if (notes === ""){
      new_errors.notes = "Please describe the issue.";
    }

    if (type === ""){
      new_errors.type = "Please select the type of issue.";
    }

    return new_errors;
  };

  const sendReport = ()=>{
    const new_errors = validateReport();
    if (Object.keys(new_errors).length !== 0){
      setErrors(new_errors);
      return;
    }

    let collection = "global";
    if ((achievement && achievement.group_id) || (section && section.group_id)){
      collection = "group";
    }

    let payload = {
      collection,
      user_id: user_info.uid,
      notes,
      type
    };

    if (achievement){
      payload.achievement_id = achievement.achievement_id;
    } else {
      payload.section_id = section.section_id;
    }

    dispatcher("SUBMIT_REPORT", {
      payload
    });
  };

  const onChangeNotes = (e)=>{
    if (e !== undefined){
      setNotes(e.target.value);
    }
  };

  const onChangeType = (e)=>{
    if (e !== undefined){
      setType(e.target.value);
    }
  };

  const {
    subject,
  } = details;

  if (!subject){
    return null;
  }

  let textareaStyle = {};
  let saveButtonStyle = {};
  let styleColor = styles.SecondaryColorMedium;

  if ((achievement && achievement.group_id) || (section && section.group_id)){
    textareaStyle = {backgroundColor: styles.GroupSecondaryColor};
    saveButtonStyle = {backgroundColor: styles.GroupPrimaryColor};
    styleColor = styles.GroupPrimaryColor;
  }

  return (
    <div id="report-details" style={{...styles.detailsStyle}}>

      <div id="report-details-type-container" style={{...styles.reportDetailsTypeContainer}}>

        <div id="report-details-type-label" style={{...styles.reportDetailsTypeLabel, color: styleColor}}>Issue type</div>

        <select
          id="report-details-type-select"
          style={{...styles.reportDetailsTypeSelect, color: styleColor}}
          name="type"
          value={type}
          onChange={onChangeType}
        >
          <option
            id="report-details-type-placeholder"
            style={{...styles.reportDetailsTypePlaceholder}}
            value=""
            disabled
            selected
            hidden
          >
            Select
          </option>
          <option
            id="report-details-type-bug"
            style={{...styles.reportDetailsTypeBug}}
            value="bug"
          >
            Bug
          </option>
          <option
            id="report-details-type-incorrect"
            style={{...styles.reportDetailsTypeIncorrect}}
            value="incorrect_data"
          >
            Incorrect Data
          </option>
          <option
            id="report-details-type-inappropriate"
            style={{...styles.reportDetailsTypeInappropriate}}
            value="inappropriate_content"
          >
            Inappropriate Content
          </option>
        </select>
      </div>
      {(errors.type) && (
        <div id="report-details-type-error" style={{...styles.reportDetailsTypeError}}>{errors.type}</div>
      )}

      <textarea
        id="report-details-notes"
        style={{...styles.reportDetailsNotes, ...textareaStyle}}
        name="notes"
        value={notes}
        placeholder="Describe the issue."
        onChange={onChangeNotes}
      />
      {(errors.notes) && (
        <div id="report-details-notes-error" style={{...styles.reportDetailsNotesError}}>{errors.notes}</div>
      )}

      <div id="report-details-send-button-container" style={{...styles.reportDetailsSendButtonContainer}}>
        <div
          id="report-details-send-button"
          style={{...styles.reportDetailsSendButton, ...saveButtonStyle}}
          onClick={sendReport}
        >
          Send
        </div>
      </div>

    </div>
  );
}

const mapState = (state)=>{

  return {
    user_info: state.sessionReducer.user_info,
    achievementReducer: state.achievementReducer,
    details: state.sessionReducer.details || {},
  };
};

export default connect(mapState)(ReportDetailsComponent);
