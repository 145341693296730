import React           from "react";
import { useNavigate } from "react-router-dom";

import { connect }     from "react-redux";

import dispatcher      from "../../redux/dispatcher.js";

import Achievement     from "../achievement/Achievement.js";
import SectionCard     from "../section/SectionCard.js";

import styles          from "../../styles/styles.js";

function PersonalAchievementsPage(props){

  const {
    user_info,
    sectionReducer,
    achievementReducer,
  } = props;

  const history = useNavigate();

  const toNewPersonalSection = ()=>{

    if (!user_info.uid){
      loginModal();
    } else {
      history("/achievement-workshop/create/section/personal");
    }
  };

  const toNewPersonalAchievement = ()=>{

    if (!user_info.uid){
      loginModal();
    } else {
      history("/achievement-workshop/create/achievement/personal");
    }
  };

  const loginModal = ()=>{
    dispatcher("ADD_MODAL", {
      name: "SESSION",
      modalProps: {
        login: true,
      }
    });
  };

  const renderSections = ()=>{

    let sections = [];
    let group_sections = [];
    let global_sections = [];

    Object.keys(sectionReducer.personal_sections).filter((section_id)=>{
      const section = sectionReducer.personal_sections[section_id];

      return (section.deleted !== "true");
    }).forEach((section_id, key)=>{
      const section = sectionReducer.personal_sections[section_id];

      if (sectionReducer.global_sections[section.parent]){
        global_sections.push(
          <SectionCard key={`personal_section_${key}`} section={section}/>
        );
      } else if (sectionReducer.group_sections[section.parent]){
        group_sections.push(
          <SectionCard key={`personal_section_${key}`} section={section}/>
        );
      } else if (!sectionReducer.personal_sections[section.parent]){
        sections.push(
          <SectionCard key={`personal_section_${key}`} section={section}/>
        );
      }
    });

    return (
      <div id="personal-achievements-section-container" style={{...styles.personalAchievementsSectionContainer}}>

        <div
          id="personal-achievements-page-create-section-button"
          style={{...styles.personalAchievementsPageCreateSectionButton}}
          onClick={toNewPersonalSection}
        >
          Create new section
        </div>

        <div id="personal-achievements-section-content" style={{...styles.personalAchievementsSectionContent}}>
          <div id="personal-achievements-section-label" style={{...styles.personalAchievementsSectionLabel}}>Personal Sections</div>
          {(sections.length) ? (sections) : (
            <div id="personal-achievements-page-no-sections-message" style={{...styles.personalAchievementsPageNoSectionsMessage}}>Once you've added some personal sections, they will show up here.</div>
          )}
        </div>

        {(global_sections.length) ? (
          <div id="personal-achievements-section-content" style={{...styles.personalAchievementsSectionContent}}>
            <div id="personal-achievements-section-label" style={{...styles.personalAchievementsSectionLabel}}>Personal Global Sections</div>
            {global_sections}
          </div>
        ) : (null)}

        {(group_sections.length) ? (
          <div id="personal-achievements-section-content" style={{...styles.personalAchievementsSectionContent}}>
            <div id="personal-achievements-section-label" style={{...styles.personalAchievementsSectionLabel}}>Personal Group Sections</div>
            {group_sections}
          </div>
        ) : (null)}

      </div>
    );
  };

  const renderAchievements = ()=>{

    const user_achievements = achievementReducer.user_achievements;

    let achievements = [];
    let group_achievements = [];
    let global_achievements = [];

    Object.keys(achievementReducer.personal_achievements).filter((achievement_id)=>{
      const achievement = achievementReducer.personal_achievements[achievement_id];

      return (achievement.deleted !== "true") && (!sectionReducer.personal_sections[achievement.section]);
    }).forEach((achievement_id, key)=>{
      const achievement = achievementReducer.personal_achievements[achievement_id];

      const user_achievement = user_achievements[achievement_id];

      const completed      = user_achievement?.completed;
      const favorited      = user_achievement?.favorited;
      const tracking       = user_achievement?.tracking;
      const completed_date = user_achievement?.created;

      const AchievementToPush = (
        <Achievement
          key={`achievement-${key}`}
          achievement={achievement}
          completed={completed}
          favorited={favorited}
          tracking={tracking}
          completed_date={completed_date}
          user_id={user_info.uid}
        />
      );

      if (sectionReducer.global_sections[achievement.section]){
        global_achievements.push(AchievementToPush);
      } else if (sectionReducer.group_sections[achievement.section]){
        group_achievements.push(AchievementToPush);
      } else if (!sectionReducer.personal_sections[achievement.section]){
        achievements.push(AchievementToPush);
      }

    });

    return (
      <div id="personal-achievements-achievement-container" style={{...styles.personalAchievementsAchievementContainer}}>

        <div
          id="personal-achievements-page-create-achievement-button"
          style={{...styles.personalAchievementsPageCreateAchievementButton}}
          onClick={toNewPersonalAchievement}
        >
          Create new achievement
        </div>

        <div id="personal-achievements-achievement-content" style={{...styles.personalAchievementsAchievementContent}}>
          <div id="personal-achievements-achievement-label" style={{...styles.personalAchievementsAchievementLabel}}>Personal Achievements</div>
          {(achievements.length) ? (achievements) : (
            <div id="personal-achievements-page-no-achievements-message" style={{...styles.personalAchievementsPageNoAchievementsMessage}}>Once you've added some personal achievements, they will show up here.</div>
          )}
        </div>

        {(global_achievements.length) ? (
          <div id="personal-achievements-achievement-content" style={{...styles.personalAchievementsAchievementContent}}>
            <div id="personal-achievements-achievement-label" style={{...styles.personalAchievementsAchievementLabel}}>Personal Global Achievements</div>
            {global_achievements}
          </div>
        ) : (null)}

        {(group_achievements.length) ? (
          <div id="personal-achievements-achievement-content" style={{...styles.personalAchievementsAchievementContent}}>
            <div id="personal-achievements-achievement-label" style={{...styles.personalAchievementsAchievementLabel}}>Personal Group Achievements</div>
            {group_achievements}
          </div>
        ) : (null)}

      </div>
    );

  };

  const Sections = renderSections();
  const Achievements = renderAchievements();

  // <div onClick={this.removeChildren}>click</div>
  return (
    <div id="personal-achievements-page" style={{...styles.personalAchievementsPage}}>

      <div id="personal-achievements-page-header" style={{...styles.personalAchievementsPageHeader}}>Personal Achievements Page</div>

      <div id="personal-sections-and-achievements-container" style={{...styles.personalSectionsAndAchievementsContainer}}>
        {Sections}
        {Achievements}
      </div>

    </div>
  );
}

const mapState = (state)=>{

  return {
    personal_sections: state.sectionReducer.personal_sections,
    user_info: state.sessionReducer.user_info,
    sectionReducer: state.sectionReducer,
    achievementReducer: state.achievementReducer,
  };
};

export default connect(mapState)(PersonalAchievementsPage);
