import React  from "react";

import { useNavigate } from "react-router-dom";

import styles from "../../styles/styles.js";

export default function AboutPage(){
  const history = useNavigate();

  return (
    <div id="about-page-container" style={{...styles.aboutPageContainer}}>
      <div id="about-page-content" style={{...styles.aboutPageContent}}>
        <div id="about-page-title" style={{...styles.aboutPageTitle}}>About Life Nav</div>

        <div id="about-page-patch-notes-button" style={{...styles.aboutPagePatchNotesButton}} onClick={()=>{
          history("/patch-notes");
        }}>
          Life Nav is currently in Alpha. v0.0.8
          <div id="about-page-patch-notes-button-text" style={{...styles.aboutPagePatchNotesButtonText}}>Patch Notes</div>
        </div>

        If you would like to help support this project, you can do so by supporting me (Caleb Sundance) over on Patreon where I have some extra perks and rewards for building Life Nav along with my other projects.

        <a id="about-page-patreon-link" style={{...styles.aboutPagePatreonLink}} href="https://www.patreon.com/calebsundance">Support me on Patreon!</a>

        <div id="about-page-problem-solving-title" style={{...styles.aboutPageProblemSolvingTitle}}>
          The Problem Life Nav is Solving
        </div>

        There are many steps to doing what you want to do in life, and as time goes on, there are even more things you may wind up wanting to do. Life Nav helps you manage all of your goals in a way that normal goal setting apps do not. Think of it like the Wikipedia of goals. Our mission is to pull together all the best available resources already online available to everyone and make it easy to find. One of the biggest problems with information overload is knowing how to navigate it. The tools that we have on the internet are invaluable, but the resources are still fragmented. Life Nav is building a home for everything you didn't know to search for.

        <div id="about-page-roadmap-title" style={{...styles.aboutPageRoadmapTitle}}>
          Roadmap
        </div>

        <div id="about-page-roadmap-text">
          Check out our Trello board for upcoming features!
        </div>

        <a id="about-page-trello-link" style={{...styles.aboutPageTrelloLink}} href="https://trello.com/b/MZWWfYBO">Trello Roadmap</a>

      </div>

    </div>
  );
}
